import { Col, Form, Row, Input, Label, Card, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import axios from 'axios'
import React, { Fragment, useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { ChevronDown, Check } from 'react-feather'
import toast from 'react-hot-toast'
import Avatar from '@components/avatar'
//import AddActions from './AddActions'
//import EditAction from './EditActions'
import { handleProjectActiondeleteConfirmRefreshPage, handleProjectScopeIds, handlerefresh } from '../../redux/managementStrategy'
import { useParams } from 'react-router-dom'
import ProjectDetails from '../ProjectDetails/ProjectDetails'
import AddActions from '../actions/AddActions'

const LinkActionsModal = ({For}) => {
  const dispatch = useDispatch()
  const parms = useParams()
  const [linkaction, setLinkAction] = useState(false)
  const [selectedStrategy, setSelectedStrategy] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const event_id = useSelector(state => state.navbar.eventId)
  const [unlink, setUnlink] = useState([])
  //const [linkaction, setLinkAction] = useState()
  const [selected, setSelected] = useState([])
  const projectID = parms.projectId
  const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
  const RefreshPage = useSelector(state => state.managementStrategy.refreshpage)
  const activeTabFromRedux = useSelector((state) => state.navbar.activeTab)
  const databaseUserId = localStorage.getItem('loggedInUserId')
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [searchValue] = useState('')
  const [data, setData] = useState([])
  const [spinButton, setSpinButton] = useState(false)
  const [totalRecord, setTotalRecords] = useState()
  const [getData, setGetData] = useState({
    projectId: parms.projectId,
    page: currentPage,
    perPage: rowsPerPage,
    q: searchValue,
    completedStatus:'No'
  })
  // get pagwise action data
  useEffect(() => {
    setData([])
      setIsLoading(true)
      axios.post(`${process.env.REACT_APP_BASENAME}/getactionspagewise`, getData)
        .then((res) => {
          //console.log('Res', res.data.data)
          const selected_strategy = res.data.data.allData?.map((ele) => {
            if (ele.scopes?.find((data) => data === event_id.join())) {
              return ele._id        
            } 
         })
         const data = selected_strategy.filter((ele) => ele !== undefined)
         console.log(data)
         const selectedData = res.data.data.allData.filter((event) => event.scopes.includes(event_id.join(' ')))
         const unselectedData = res.data.data.allData.filter((event) => !event.scopes.includes(event_id.join(' ')))
     
         setSelected(data)
         setSelectedStrategy(data)
          setIsLoading(false)
          setData([...selectedData, ...unselectedData])
          dispatch(handleProjectScopeIds(res.data.data?.allData))
          setTotalRecords(res.data.data?.total)
        })
  }, [RefreshPage, getData, activeTabFromRedux === '5'])

  const cancelHandler = () => {
    dispatch(handlerefresh())
    setSpinButton(false)
    setSelected([])
    setLinkAction(false)
  }
 console.log(selected)
  const handleActionLink = () => {
    if ((selected.length || unlink.length > 0) && event_id.length > 0) {
     const e_id =  event_id.join(' ')
      axios.post(`${process.env.REACT_APP_BASENAME}/linkprojectactions`, {link : selected, unlink, pie: e_id }).then((res) => {
        console.log(res.data.data.message)
        dispatch(handlerefresh()) 
        if (res.data.data.message) {
          dispatch(handlerefresh())
          toast(
              <Row className='demo-vertical-spacing'>
                  <div className='d-flex'><div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /> </div>
                      <div className='d-flex flex-column'><h6>{res.data.data.message}</h6></div>
                  </div>
              </Row>)
          cancelHandler()
      }
      }) 
    }
  }

  console.log(event_id)

  // handle Checkbox selection
  const onHandleSelect = (e, id) => {
    const selectedIndex = selected.indexOf(id)
    console.log('selectedIndex', selectedIndex)
    let newSelected = []
    // let unSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    
    if (unlink.length <= selectedStrategy.length) {
      if (e.target.checked) {
        const find_id = selectedStrategy.find((_id) => _id === id)
        if (find_id) {
         const data =  unlink.filter((_id) => _id !== id) 
         setUnlink(data)
        } 
      } else {
        const find_id = selectedStrategy.find((_id) => _id === id)
        if (find_id) {
          const data = unlink.find((ele) => ele === id)
           if (!data) {
            setUnlink([...unlink, id]) 
           }
        }
      }
    }
    
    setSelected(newSelected) 
  }
 
  // cancel Handler for delete
  

  //to delete Action
  const onConfirmDelete = () => {
    setIsLoading(true)
    axios.post(`${process.env.REACT_APP_BASENAME}/deleteprojectaction`, { userId: databaseUserId, selectIds: selected.toString() })
      .then((res) => {
        setIsLoading(false)
        dispatch(handleProjectActiondeleteConfirmRefreshPage(res.data))
        if (res.data.data) {
          toast(
            <Row className='demo-vertical-spacing'>
              <div className='d-flex'>
                <div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /></div>
                <div className='d-flex flex-column'><h6>{res.data.data.message}</h6></div></div>
            </Row>
          )
          cancelHandler()
        }
      })
  }

  //Pagination Functions
  const isSelected = (id) => selected.indexOf(id) !== -1
  const serverSideColumns = [
    {
      minWidth: '50px',
      name: 'Link Action',
      cell: row => {
        return (
          <>
            {(LoginUserRole === 'Editor') ? <Input type='checkbox' checked={isSelected(row?._id) } className='cursor-pointer' onChange={(e) => onHandleSelect(e, row?._id)} /> : ''}
          </>
        )
      }
    },
    {
      sortable: true,
      name: 'Subject',
      minWidth: '18%',
      wrap: true,
      selector: row => row?.subject
    },
    {
      sortable: true,
      name: 'Start Date',
      minWidth: '15%',
      wrap: true,
      selector: row => (moment(new Date(row?.startDate)).format('ll'))
    },
    {
      sortable: true,
      name: 'End Date',
      minWidth: '15%',
      wrap: true,
      selector: row => (moment(new Date(row?.endDate)).format('ll'))
    },
    {
      sortable: true,
      name: 'User',
      minWidth: '15%',
      wrap: true,
      selector: row => row?.stakeHolder
    },
    {
      sortable: true,
      name: 'Completed?',
      wrap: true,
      minWidth: '18%',
      selector: row => {
        return (
          <>
            <span className={row?.completedStatus === 'Yes' ? 'badge bg-success rounded-pill' : (row?.completedStatus === 'No') ? 'badge bg-danger rounded-pill' : ''}>{row?.completedStatus}</span>
          </>
        )
      }
    }
   
  ]
  const handlePagination = page => {

    setGetData({
      projectId: projectID,
      page: page.selected + 1,
      perPage: rowsPerPage
    })
    setCurrentPage(page.selected + 1)
  }

  // ** Function to handle per page
  const handlePerPage = e => {
    setGetData({
      projectId: projectID,
      page: currentPage,
      perPage: parseInt(e.target.value)
    })
    setRowsPerPage(parseInt(e.target.value))
  }

  // ** Custom Pagination
  const CustomPagination = () => {
    const count = Math.ceil(totalRecord / rowsPerPage)

    return (
      <div className="d-flex align-items-center justify-content-between mt-1">
 <div className='d-flex align-items-center'>
                      <Label for='sort-select'> Select No. of Records </Label>
                      <Input className='dataTable-select' style={{width:'80px', height:'33px', margin:'5px'}} type='select' id='sort-select' value={rowsPerPage} onChange={e => handlePerPage(e)}>
                        <option value={3}>3</option>
                        <option value={5}>5</option>
                        <option value={7}>7</option>
                        <option value={10}>10</option>
                      </Input>
                    </div>
      <ReactPaginate previousLabel={''} nextLabel={''} breakLabel='...' pageCount={Math.ceil(count) || 1} marginPagesDisplayed={2} pageRangeDisplayed={2} activeClassName='active' forcePage={currentPage !== 0 ? currentPage - 1 : 0} onPageChange={page => handlePagination(page)} pageClassName='page-item' breakClassName='page-item' nextLinkClassName='page-link' pageLinkClassName='page-link' breakLinkClassName='page-link' previousLinkClassName='page-link' nextClassName='page-item next-item' previousClassName='page-item prev-item' containerClassName={ 'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-2' }/>
     </div> 
    )
  }

  // ** Table data to render
  const dataToRender = () => {
    const filters = {
      q: searchValue
    }

    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })

    if (data?.length > 0) {
      return data
    } else if (data?.length === 0 && isFiltered) {
      return []
    } else {
      return data?.slice(0, rowsPerPage)
    }
  }

  return (
    <>
     {(For === 'PIED') ? (<a className='cursor-pointer' style={{ color: 'blue' }} onClick={() => setLinkAction(!linkaction)} >+<u>LINK ACTION</u> </a>) : <Button color="primary" style={{marginRight:'15px'}} className="float-end" onClick={() => setLinkAction(!linkaction)} ></Button>}
      <div >
      <Modal backdrop="static" isOpen={linkaction} toggle={cancelHandler} className='modal-dialog-centered modal-lg '>
          <ModalHeader
            toggle={cancelHandler}>
        </ModalHeader>
        <h4 className="text-center mb-2 mt-1"> Incomplete actions are shown below </h4>
        <ModalBody>
            <Form onSubmit={(e) => e.preventDefault()}>
                <Row>
      <Fragment>
      <Col className='mb-1' >{(LoginUserRole === 'Viewer') ||  <AddActions For = 'PIED' />}</Col>
        <Card>
          <Row className="mt-0">
            {(data?.length === 0) ? (<h6 className=' text-center'> Actions Will Appear Here </h6>) : (
              <> <Row>
                <Col sm='12'>
                  <DataTable noHeader pagination paginationServer wrap='true' className='react-dataTable ' columns={serverSideColumns} sortIcon={<ChevronDown size={15} />} paginationComponent={CustomPagination} data={dataToRender()} />
                </Col></Row>
                <Row className='mx-0 mt-0 mb-50'>
                  <Col sm='3 '>
                   
                  </Col>
                </Row> </>)}
          </Row>
        </Card>
      </Fragment>
      <div className='demo-inline-spacing'><div className='vertically-centered-modal'>
        <Modal backdrop="static" toggle={cancelHandler} className='modal-dialog-centered'>
          <ModalHeader toggle={cancelHandler}>Confirmation</ModalHeader>
          <ModalBody>Do you want to delete this record ?</ModalBody>
          <ModalFooter>
            <Button color="primary" type='submit' onClick={onConfirmDelete}>{isLoading === true ? <Spinner size='sm' /> : 'YES'}</Button>
            <Button className="offset-2" color="secondary" onClick={cancelHandler}> NO</Button></ModalFooter>
        </Modal>
      </div>
      </div>
      </Row>
          </Form>
          </ModalBody>
          <ModalFooter className="modal-footer justify-content-center">
              <Button type='submit' onClick={handleActionLink} color='primary'>{spinButton === true ? <Spinner size='sm' className='ms-75' /> : 'SAVE'} </Button>
              <Button onClick={cancelHandler}> CANCEL</Button>
          </ModalFooter>
      </Modal>
    </div>
   
    </>
  )
}

export default LinkActionsModal


//  import axios from "axios"
//  import { ModalHeader, ModalBody, ModalFooter, Form, Button, Spinner, Modal, Row, Col, Input } from 'reactstrap'
//  import toast from 'react-hot-toast'
//  import React, { useState } from 'react'
//  import Avatar from '@components/avatar'
//  import { Check } from "react-feather"
//  import { handleManagementStrategy } from '../../redux/navbar'
//  import { useDispatch, useSelector } from 'react-redux'
//  import { handleProjectManagementStrategyRefreshPage } from "../../redux/managementStrategy"

// const LinkActionsModal = ({For}) => {
//     const [linkaction, setLinkAction] = useState(false)
//     const [spinButton, setSpinButton] = useState(false)

//     const cancelHandler = () => {
//         setSpinButton(false)
     
//         setLinkAction(!linkaction)
//     }

//     return (
//         <>
//          {(For === 'PIED') ? (<a className='cursor-pointer' style={{ color: 'blue' }} onClick={() => setLinkAction(!linkaction)} >+<u>LINK NEW ACTION</u> </a>) : <Button color="primary" style={{marginRight:'15px'}} className="float-end" onClick={() => setLinkAction(!linkaction)} ></Button>}
//             <div >
//                 <Modal backdrop={false} isOpen={linkaction} toggle={cancelHandler} className='modal-dialog-centered modal-lg '>
//                     <ModalHeader
//                         toggle={cancelHandler}>
//                     </ModalHeader>
//                     <h4 className="text-center mb-2 mt-1"> Incomplete actions are shown below </h4>
//                     <ModalBody>
//                         <Form onSubmit={(e) => e.preventDefault()}>
//                             <Row>
//                                 <Col>
                                   
//                                 </Col>
//                             </Row>
//                         </Form>
//                     </ModalBody>
//                     <ModalFooter className="modal-footer justify-content-center">
//                          <Button type='submit' color='primary'>{spinButton === true ? <Spinner size='sm' className='ms-75' /> : 'SAVE'} </Button>
//                         <Button onClick={cancelHandler}> CANCEL</Button>
//                     </ModalFooter>
//                 </Modal>
//             </div>
//         </>
//     )
// }

// export default LinkActionsModal