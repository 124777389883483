
import Select from 'react-select'
import { selectThemeColors } from '@utils'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import AddLogo from '../../assets/images/portrait/small/add.png'
import { useDispatch, useSelector } from 'react-redux/es/exports'
import { handleCostumiseReceptor, handleCostumiseReceptorModal, handleCustomisationClose } from '../../redux/navbar'
import { Button, Col, Row, Spinner } from 'reactstrap'
import { XCircle, Check, PlusCircle } from 'react-feather'
import Avatar from '@components/avatar'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

const DropdownReceptors = () => {
  const [data, setData] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const parms = useParams()
  const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
  const activeTabFromRedux = useSelector((state) => state.navbar.activeTab)
  const [add, setAdd] = useState(1)
  const [error, setError] = useState()
  const dispatch = useDispatch()
  const [stage] = useState([])
  const [selected, setSelected] = useState([])
  const projectId = parms.projectId
  const databaseUserId = localStorage.getItem('loggedInUserId')
  const colourOptions = [stage]
  const addHandler = () => {
    setAdd(0)
    setData("")
  }

  const cancelHandler = () => {
    setData('')
    setAdd(1)
    setError()
  }

  //Tosty Message component
  const Tosty = (msg, type) => {
    toast(
      <Row className='demo-vertical-spacing'>
        <div className='d-flex'><div className='me-1'>{type === 'Error' ? <Avatar size='sm' color='danger' icon={<Check size={12} />} /> : <Avatar size='sm' color='success' icon={<Check size={12} />} />}</div>
          <div className='d-flex flex-column'><h6>{msg}</h6></div>
        </div>
      </Row>)
  }


  Array.prototype.removeValue = function (name, value) {
    const array = $.map(this, function (v) {
      return v[name] === value ? null : v
    })
    this.length = 0 //clear original array
    this.push.apply(this, array) //push all elements except the one we want to delete
  }
  // add receptors in  array if already existed then show message login applied
  const handleChangeForAddReceptor = (val) => {
    if (val !== null) {
      if (val.label !== null || val.isEmpty({})) {
        if (val.value === '') {
          Tosty('Select Receptor can not be selected. Select another value', 'Error')
        } else {
          const check = selected.indexOf(val.label)
          if (check === -1) {
            const selectedIndex = selected.indexOf(val.label)
            let newSelected = []
            if (selectedIndex === -1) {
              newSelected = newSelected.concat(selected, val.label)
            } else if (selectedIndex === 0) {
              newSelected = newSelected.concat(selected.slice(1))
            } else if (selectedIndex === selected.length - 1) {
              newSelected = newSelected.concat(selected.slice(0, -1))
            } else if (selectedIndex > 0) {
              newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
            }
            setSelected(newSelected)
            dispatch(handleCustomisationClose('false'))
            dispatch(handleCostumiseReceptor(newSelected))
          } else {
            Tosty('Custom Receptor already exists', 'Error')
          }
        }
      }
    }
  }

  // for 
  const handleChangeForDeletionReceptor = (id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    dispatch(handleCustomisationClose('false'))
    setSelected(newSelected)
    dispatch(handleCostumiseReceptor(newSelected))
  }


  // get all receptors
  useEffect(() => {
    setIsLoading(true)
    stage.length = 0
    stage.push({ value: "", label: "Select Receptor" })
    axios.get(`${process.env.REACT_APP_BASENAME}/getreceptors/${databaseUserId}`).then(res => {
      //console.log(res.data.data, "84")
      res.data.data?.map(ele => stage.push({ value: ele.receptor, label: ele.receptor }))
      res.data.data?.map(ele => colourOptions.push({ value: ele.receptor, label: ele.receptor }))
      setIsLoading(false)
    })
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_BASENAME}/getcustomization/${projectId}`)
      .then((res) => {
        if (res.data.data?.receptors) {
          setIsLoading(false)
          setSelected(res.data.data?.receptors)
        }
      })
  }, [activeTabFromRedux === '1'])

  // get customise receptors for current Project
  // useEffect(() => {
  //   axios.get(`${process.env.REACT_APP_BASENAME}/getcustomization/${projectId}`)
  //     .then((res) => {
  //       if (res.data.data?.receptors) {
  //         setSelected(res.data.data?.receptors)
  //       }
  //     })
  // }, [projectId])

  // save customise receptors
  const saveHandler = () => {
    if (data.length !== 0) {
      const check = selected.indexOf(data)
      if (check === -1) {
        handleChangeForDeletionReceptor(data)
        const userIdData = { userId: databaseUserId, receptor: data }
        axios.post(`${process.env.REACT_APP_BASENAME}/savereceptor`, userIdData).then(() => {
          dispatch(handleCostumiseReceptorModal(data))
          stage.push({ value: data, label: data })
        })
        setAdd(1)
      } else {
        Tosty('Custom Receptor already exists', 'Error')
      }
    } else {
      setError(0)
    }
  }
  dispatch(handleCostumiseReceptor(selected))

  return (
    <>
      <Row className='mt-1' >
        <h4 >Select Receptors</h4>
      </Row>
      {isLoading === true ? (<> <center><Spinner color="primary" className='text-center' style={{ height: '2rem', width: '2rem' }} /></center>  </>) : (<Row className='mb-5'>
        <Col className='mt-1 ' md='4' xl='4' >
          <Select
            onChange={handleChangeForAddReceptor}
            theme={selectThemeColors}
            className='react-select'
            classNamePrefix='select'
            maxMenuHeight={180}
            placeholder='Select Receptor'
            name='clear'
            options={stage}
            isClearable
          />
        </Col>
        <Col md='1' xl='1' className='text-center mt-1 '>
          {(LoginUserRole === 'Editor') ? (<PlusCircle size={30} onClick={addHandler} />) : ''}
        </Col>
        <Col className='mt-1 ' md='6' xl='6' >
          {selected?.map(ele => <React.Fragment key={ele}>
            <Button.Ripple className='btn btn-outline-primary round waves-effect mb-1' style={{ marginRight: '10px' }} size='sm' color='secondary' outline>

              <span className='round height-60-per'>{ele}</span>
              {/* <Badge color='secondary' pill   > */}
              <XCircle size={15} className='align-middle me-25 ms-1' onClick={() => handleChangeForDeletionReceptor(ele)} />
              {/* </Badge> */}
            </Button.Ripple>
          </React.Fragment>
          )}
          {/* COde to add custome Receptor */}
          {
            (add === 0) ? <Row >
              <Col sm='6' className='mt-1 mb-1'>
                <input id='input' className='form-control' placeholder=' Enter Custom Receptors' onChange={(e) => {
                  setError(1)
                  setData(e.target.value.trim())
                }} />
                {error === 0 ? <span style={{ color: "red" }}>Enter Custom Receptors</span> : " "}
              </Col>
              <Col sm='6' className='mt-1 mb-1'>
                <Button color='primary' className='ms-1' onClick={saveHandler}>Add</Button>
                <Button color='secondary' className='ms-1' onClick={cancelHandler}>Cancel</Button>
              </Col>
            </Row> : ""}
        </Col>
      </Row>)}

    </>
  )
}
export default DropdownReceptors
