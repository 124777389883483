import { ModalHeader, ModalBody, ModalFooter, Form, Button, Row, Modal, Spinner, Col, Input, Label } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { handleEditedScopeForPIED, handleEventId, handleScopeId, handleUpdate, handleclosepopover } from '../../redux/navbar'
import { Check, Edit, Eye, Trash2, Folder, File } from 'react-feather'
import PIEDs from './PIED'
import toast from 'react-hot-toast'
import Avatar from '@components/avatar'
import LinkActionsModal from './LinkActionsModal'
// import useClickOutside from '../ClickOutsideHook/Outsideclick'


const level = ['Very High', 'High', 'Moderate', 'Low']
const assumption = ['Very High', 'High', 'Moderate', 'Low']

const AddNewScopingModal = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
    const EditScope = useSelector(state => {
        if (props.For === 'Create') {
            return []
        } else {
            return state.navbar.customization_data
        }
    })
    const projectID = useSelector(state => state.navbar.projectId)
    const [stakeHolderModal, setStakeHolderModal] = useState(false)
    const loggedInUserId = useSelector(state => state.navbar.loggedInUserId)
    const projectCustomisationData = useSelector(state => state.navbar.projectCustomisationData)
    const NewScopeId = useSelector(state => state.navbar.scopeId)
    const dispatch = useDispatch()
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [certaintyModal, setCertaintyModal] = useState(false)
    // const [deltedId, setDeleteId] = useState('')
    const [errorStage, setErrorStage] = useState()
    const [errorSource, setErrorSource] = useState()
    const [errorPathway, setErrorPathway] = useState()
    const [errorReceptor, setErrorReceptor] = useState()
    const [addScoping, setaddScoping] = useState(false)
    const [linkageModal, setLinkageModal] = useState(false)
    const databaseUserId = localStorage.getItem('loggedInUserId')
    const [certaintyData, setCertaintyData] = useState({
        level: '',
        levelDescription: '',
        assumptions: '',
        assumptionDesc: ''
    })
    const [levelErr, setLevelErr] = useState('')
    const [levelDescErr, setLevelDecErr] = useState('')
    const [assumpErr, setAssumpErr] = useState('')
    const [assumpDescErr, setAssumpDecErr] = useState('')
    const [potentialImpactEvent, setPotentialImpactEvent] = useState(false)
    const [projectScope, setProjectScope] = useState({
        stage: EditScope?.stage ? EditScope.stage : "",
        source: EditScope?.source ? EditScope.source : "",
        pathway: EditScope?.pathway ? EditScope.pathway : "",
        receptor: EditScope?.receptor ? EditScope.receptor : "",
        modifiedBy: EditScope ? loggedInUserId : loggedInUserId
    })
    const [stage, setStage] = useState([])
    const [receptor, setReceptor] = useState([])
    const [pathway, setPathway] = useState([])
    const [source, setSource] = useState([])
    const [stakeholderData, setStakeHolderData] = useState('')
    const [stakehodlererr, setStakeHolderErr] = useState('')
    
    // const ref = useRef(null)
    // useClickOutside(ref, () => props.setPopoverOpen(true))

    //function for tosty Message 
    const Tosty = (msg, type) => {
        toast(
            <Row className='demo-vertical-spacing'>
                <div className='d-flex'><div className='me-1'>{type === 'Error' ? <Avatar size='sm' color='danger' icon={<Check size={12} />} /> : <Avatar size='sm' color='success' icon={<Check size={12} />} />}</div>
                    <div className='d-flex flex-column'><h6>{msg}</h6></div>
                </div>
            </Row>)
    }

    // get customisation data from customisation page
    useEffect(() => {
       
        axios.get(`${process.env.REACT_APP_BASENAME}/getcustomization/${projectID}`)
            .then((res) => {
                setStage(res.data.data?.stages)
                setReceptor(res.data.data?.receptors)
                setPathway(res.data.data?.pathways)
                setSource(res.data.data?.sources)
            })
        axios.get(`${process.env.REACT_APP_BASENAME}/getcertaintybystrategy/${EditScope._id}`)
            .then((res) => {
                if (res.data.data) {
                    setCertaintyData({
                        level: res.data?.data?.level,
                        levelDescription: res.data?.data.levelDescription,
                        assumptions: res.data?.data.assumptions,
                        assumptionDesc: res.data?.data.assumptionDesc
                    })
                }
            })
        axios.get(`${process.env.REACT_APP_BASENAME}/getstakeholderdata/${EditScope._id}`)
            .then((res) => {
                if (res.data.data) {
                    setStakeHolderData(res.data.data.stakeholder_engagement)
                }
            })

    }, [projectCustomisationData])

    //to handle Linkage
    const onHandleLinkage = (linkage) => {
        setIsLoading(true)
        let scopeIdForLinkage = 0
        if (props.For === 'Create') {
            scopeIdForLinkage = NewScopeId
        } else {
            scopeIdForLinkage = EditScope._id
        }
        //save and update Linkage
        axios.post(`${process.env.REACT_APP_BASENAME}/savescopelinkage`, { projectScopeId: scopeIdForLinkage, linkage: linkage.target.value })
            .then((res) => {
                setIsLoading(false)
                setLinkageModal(!linkageModal)
                setPotentialImpactEvent(!potentialImpactEvent)
                dispatch(handleEditedScopeForPIED(res.data.data.projectScopeDetails))
                dispatch(handleUpdate(res.data))
            })
    }

    //onChange of project Scoping Data
    const onHandleChange = (e) => {
        setProjectScope({ ...projectScope, [e.target.name]: e.target.value })
    }
    //onChnage of handle certainty data
    const onHandleCertainty = (e) => {
        setCertaintyData({ ...certaintyData, [e.target.name]: e.target.value.trim() })
    }

    // setting state blank and error states blank on cancel button
    const cancelHandler = () => {
        // setProjectScope({
        //     stage: "",
        //     source: "",
        //     pathway: "",
        //     receptor: ""
        // })
        setCertaintyData({
            level: '',
            levelDescription: '',
            assumptions: '',
            assumptionDesc: ''
        })
        setErrorStage(1)
        setErrorSource(1)
        setErrorPathway(1)
        setErrorReceptor(1)
        setIsLoading(false)
        setaddScoping(false)
        setConfirmationModal(false)
        setCertaintyModal(false)
        setLevelErr('')
        setLevelDecErr('')
        setAssumpErr('')
        setAssumpDecErr('')
        props.setPopoverOpen(false)
        //dispatch(handleCertaintyDataRedux(''))
    }

    //handle submit of Project Scope
    const handleScopeSubmit = () => {
        if ((projectScope.stage === '') || (projectScope.source === '') || (projectScope.pathway === '') || (projectScope.receptor === '')) {
            if (projectScope.stage.length === 0) setErrorStage('0')
            if (projectScope.source.length === 0) setErrorSource('0')
            if (projectScope.pathway.length === 0) setErrorPathway('0')
            if (projectScope.receptor.length === 0) setErrorReceptor('0')
        } else {
            setIsLoading(true)
            axios.post(`${process.env.REACT_APP_BASENAME}/addscope`, {
                projectId: projectID,
                stage: projectScope.stage,
                source: projectScope.source,
                pathway: projectScope.pathway,
                receptor: projectScope.receptor,
                userId: loggedInUserId
            }).then((res) => {
                setIsLoading(false)
                if (res.data.data) {
                    // setLinkageModal(!linkageModal)
                    if (res.data.data.errorMessage) {
                        return Tosty(res.data.data.message, 'Data')
                    }
                    setaddScoping(false)
                    console.log(res)
                    dispatch(handleUpdate(res.data))
                    dispatch(handleEventId(res.data.data.scopeId))
                    dispatch(handleScopeId(res.data.data.scope.insertedId))
                    axios.post(`${process.env.REACT_APP_BASENAME}/savescopelinkage`, { projectScopeId: res.data?.data?.scope?.insertedId ? res.data?.data?.scope?.insertedId : EditScope._id })
                        .then((res) => {
                            setIsLoading(false)
                            // setLinkageModal(!linkageModal)
                            setPotentialImpactEvent(!potentialImpactEvent)
                            dispatch(handleEditedScopeForPIED(res.data.data.projectScopeDetails))
                            dispatch(handleUpdate(res.data))
                        })
                    cancelHandler()

                } else if (res.data.errorMessage) {
                    Tosty(res.data.errorMessage, 'Error')
                    setaddScoping(false)

                }

            })
        }

    }

    // To update Project Scope
    const handleScopeUpdate = () => {
        setIsLoading(true)
        axios.post(`${process.env.REACT_APP_BASENAME}/updateprojectscope `, {
            projectScopeId: EditScope._id,
            stage: projectScope.stage,
            source: projectScope.source,
            pathway: projectScope.pathway,
            receptor: projectScope.receptor,
            userId: loggedInUserId
        }).then((res) => {
            setIsLoading(false)
            // setLinkageModal(!linkageModal)
            if (res.data.data) {
                Tosty(res.data.data.message, 'Data')
                setaddScoping(false)
                //dispatch(handleUpdate(res.data))
                dispatch(handleScopeId(res.data.data.projectScopeId))
                axios.post(`${process.env.REACT_APP_BASENAME}/savescopelinkage`, { projectScopeId: EditScope._id, linkage: EditScope.linkage })
                    .then((res) => {
                        setIsLoading(false)
                        // setLinkageModal(!linkageModal)
                        setPotentialImpactEvent(!potentialImpactEvent)
                        dispatch(handleEditedScopeForPIED(res.data.data.projectScopeDetails))
                        dispatch(handleUpdate(res.data))
                    })
            } else if (res.data.errorMessage) {
                Tosty(res.data.errorMessage, 'Error')
                setaddScoping(false)
            }
            cancelHandler()
            props.setPopoverOpen(true)
        })

    }


    const onViewClick = () => {
        dispatch(handleEditedScopeForPIED(EditScope))
        setPotentialImpactEvent(!potentialImpactEvent)
    }

    // function for Delete Scope
    const onConfirmDelete = () => {
        setIsLoading(true)
        axios.post(`${process.env.REACT_APP_BASENAME}/deletescope`, { projectScopeIds: EditScope._id })
            .then((res) => {
                setIsLoading(false)
                if (res.data.data) {
                    dispatch(handleUpdate(res.data.data))
                    Tosty(res.data.data.message, 'Error')
                    setConfirmationModal(false)
                }
            })
    }
    // save certainty data
    const handleCertaintyData = () => {
        setLevelErr('')
        setLevelDecErr('')
        setAssumpErr('')
        setAssumpDecErr('')
        if (certaintyData.level.trim().length !== 0 && certaintyData.levelDescription.trim().length !== 0 && certaintyData.assumptions.trim().length !== 0 && certaintyData.assumptionDesc.trim().length !== 0) {
            setIsLoading(true)
            const postDataFrommadal = { linkageId: EditScope._id, projectId: EditScope.projectId, level: certaintyData.level, levelDescription: certaintyData.levelDescription, assumptions: certaintyData.assumptions, assumptionDesc: certaintyData.assumptionDesc, userId: databaseUserId, scopes: [EditScope.scopeId], formanagement_strategy: false }
            axios.post(`${process.env.REACT_APP_BASENAME}/addcertainty`, postDataFrommadal).then((res) => {
                if (res.data.message) {
                    Tosty(res.data.message)
                    setIsLoading(false)
                    cancelHandler()
                }
            })
        } else {
            if (certaintyData.level.trim().length === 0) {
                setLevelErr("Add level")
            } else if (certaintyData.levelDescription.trim().length === 0) {
                setLevelDecErr("Add description")
            } else if (certaintyData.assumptions.trim().length === 0) {
                setAssumpErr("Add assumption")
            } else if (certaintyData.assumptionDesc.trim().length === 0) {
                setAssumpDecErr("Add description")
            }
        }
    }


    // stakeholder data
    const onChangeStakeholder = (e) => {
        setStakeHolderData(e.target.value)
    }
    // save staeholder data
    const saveStakeHolderData = () => {
        if (stakeholderData.length > 0) {
            axios.post(`${process.env.REACT_APP_BASENAME}/savestakeholderdata`, { impact_event_id: EditScope._id, stakeholder_engagement: stakeholderData }).then((res) => {
                if (res.data.message) {
                    toast(
                        <Row className='demo-vertical-spacing'>
                            <div className='d-flex'><div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /> </div>
                                <div className='d-flex flex-column'><h6>{res.data.message}</h6></div>
                            </div>
                        </Row>
                    )
                    cancelHandler()
                }
            })
        } else {
            setStakeHolderErr("Please enter value")
        }
    }

    return (
        < >
            {/* Old Code */}
            {/* <Edit2 size={17} color='#6e6b7b' className='cursor-pointer'  onClick={() => setaddScoping(!addScoping)} />
            <Trash color='#6e6b7b' size={17} className='cursor-pointer' onClick={() => setConfirmationModal(!confirmationModal)} style={{marginLeft:'12px'}} /> */}
            {/* Add New Scope / Edit Existing Scope Button */}
            {(props.For === 'Edit') ? <>
                <div className=" d-flex flex-column">
                    <div style={{ width: '70px', paddingLeft: '5px', paddingRight: '5px', marginBottom: '0px' }} className='d-flex hovereffect cursor-pointer align-items-center justify-content-between' onClick={() => {
                        setaddScoping(true)
                        dispatch(handleclosepopover(false))
                    }}>
                        <p> <Edit size={18} /> </p>
                        <p style={{ fontSize: '15px' }} >Edit</p>
                    </div>
                    <div onClick={() => {
                        setConfirmationModal(true)
                        dispatch(handleclosepopover(false))
                    }} style={{ width: '90px', paddingLeft: '5px', paddingRight: '5px', marginBottom: '0px' }} className='d-flex hovereffect cursor-pointer align-items-center justify-content-between'>
                        <p > <Trash2 size={18} /> </p>
                        <p style={{ fontSize: '15px' }}>Delete</p>
                    </div>
                    <div onClick={() => {
                        setCertaintyModal(true)
                        dispatch(handleclosepopover(false))
                    }} style={{ width: '242px', paddingLeft: '5px', paddingRight: '5px', marginBottom: '0px' }} className='d-flex hovereffect cursor-pointer align-items-center justify-content-between'>
                        <p > <Folder size={20} /> </p>
                        <p style={{ fontSize: '15px' }}>Undertake uncertainty <br/> and sensitivity Assessment</p>
                    </div>
                     {
                      EditScope.linkage === 'No' ? <div style={{ width: '236px', paddingLeft: '5px', paddingRight: '5px', marginBottom: '0px' }} className='d-flex hovereffect cursor-pointer align-items-center justify-content-between' onClick={() => {
                        setStakeHolderModal(true)
                        dispatch(handleclosepopover(false))
                      }}>
                        <p><Folder size={18}/></p>
                        <p  style={{ fontSize: '15px' }}> Stakeholder Engagement</p>
                      </div> : ''
                     }          
                </div>
            </> : (props.For === 'Viewer') ? <Eye color='gray' className='cursor-pointer' size={15} onClick={onViewClick} /> : <Button color="primary" onClick={() => setaddScoping(!addScoping)} > CREATE NEW POTENTIAL IMPACT  EVENT</Button>}

            {/* //Model Popup for add new scoping or edit scoping */}
            
            <Modal isOpen={addScoping} backdrop="static" toggle={cancelHandler} className='modal-dialog-centered modal-lg'>
              
                <ModalHeader toggle={cancelHandler}></ModalHeader>
                <ModalBody><Form onSubmit={(e) => e.preventDefault()}>
                    <Row>{(props.For === 'Create') ? <h4  className="text-center mb-2">Add New Potential Impact Event</h4> : <h4 className="text-center mb-2">Edit Existing Potential Impact Event</h4>}
                         
                        <div className="col-md-6 mt-1 mb-1">
                            {EditScope?.stage ? <label> Select Stage </label> : ''}
                            {
                                props.For === 'Create' ? <select style={{ marginTop: '5px' }} className="form-select" name="stage" onChange={(e) => {
                                    setErrorStage(1)
                                    onHandleChange(e)
                                }} >
                                    <option selected value=''><h5>Select Stage</h5> </option>
                                    {
                                        stage?.map(CustStage => (
                                            <option key={CustStage} >{CustStage}</option>
                                        ))
                                    }
                                </select> : <select style={{ marginTop: '5px' }} className="form-select" disabled>
                                    <option>{EditScope.stage}</option>
                                </select>
                            }
                            {errorStage === '0' ? <span style={{ color: "red" }} value=''>Select Stage </span> : ''}
                        </div>
                        <div className="col-md-6 mt-1 mb-1">
                            {EditScope?.source ? <label> Select Source </label> : ''}
                            {
                                props.For === 'Create' ? <select style={{ marginTop: '5px' }} className="form-select" name="source" onChange={(e) => {
                                    setErrorSource(1)
                                    onHandleChange(e)
                                }}>
                                    <option selected value=''><h5>Select Source</h5> </option>
                                    {
                                        source?.map(custSource => (
                                            <option key={custSource} value={custSource}>{custSource}</option>
                                        ))
                                    }
                                </select> : <select style={{ marginTop: '5px' }} className="form-select" disabled>
                                    <option>{EditScope.source}</option>
                                </select>
                            }

                            {errorSource === '0' ? <span style={{ color: "red" }}>Select Source</span> : " "}
                        </div>
                    </Row>
                    <Row>
                        <div className="col-md-6 offset-0 mt-1 mb-1">
                            {EditScope?.pathway ? <label> Select Pathway </label> : ''}
                            <select style={{ marginTop: '5px' }} className="form-select" disabled={EditScope?.pathway} name="pathway" onChange={(e) => {
                                setErrorPathway(1)
                                onHandleChange(e)
                            }}>
                                {(props.For === 'Create') ? <option selected value=''><h5>Select Pathway</h5> </option> : null}
                                {
                                    (props.For === 'Create') ? pathway?.map((custPathway => (
                                        <option value={custPathway} > {custPathway}</option>
                                    ))) : <option>{EditScope?.pathway}</option>
                                }
                            </select>
                            {errorPathway === '0' ? <span style={{ color: "red" }}>Select Pathway</span> : " "}
                        </div>
                        <div className="col-md-6 mt-1 mb-1">
                            {EditScope?.pathway ? <label> Select Recepetor </label> : ''}
                            <select style={{ marginTop: '5px' }} disabled={EditScope?.pathway} className="form-select" name="receptor" onChange={(e) => {
                                setErrorReceptor(1)
                                onHandleChange(e)
                            }}>
                                {(props.For === 'Create') ? <option selected value=''><h5>Select Receptor</h5> </option> : null}
                                {
                                    (props.For === 'Create') ? receptor?.map(custReceptor => (
                                        <option key={custReceptor} selected={(EditScope?.receptor === custReceptor)} value={custReceptor}>{custReceptor}</option>
                                    )) : <option> {EditScope?.receptor} </option>
                                }
                            </select>
                            {errorReceptor === '0' ? <span style={{ color: "red" }}>Select Recepetor</span> : " "}
                        </div>
                    </Row>
                </Form>
                </ModalBody>
                <ModalFooter className=" modal-footer justify-content-center " >
                    {(props.For === 'Viewer') ? '' : (<>{(props.For === 'Create') ? <Button color="primary" onClick={handleScopeSubmit} >{isLoading === true ? <Spinner size='sm' /> : 'SUBMIT'}
                    </Button> : <Button color="primary" onClick={handleScopeUpdate} >{isLoading === true ? <Spinner size='sm' /> : 'UPDATE'}  </Button>}
                        <Button color="secondary" onClick={cancelHandler}>CANCEL</Button></>)}
                </ModalFooter>
            </Modal>


            {/* //Model Popup For Linkage */}
            <Modal backdrop="static" isOpen={linkageModal} toggle={() => setLinkageModal(!linkageModal)} className='modal-dialog-centered'>
                <div></div>
                <ModalHeader toggle={() => setLinkageModal(!linkageModal)}></ModalHeader>
                <ModalBody>
                    {(EditScope?.linkage) ? <h5 className="text-center mb-2">Update Linkage?</h5> : <h5 className="text-center mb-2">Is there a Linkage?</h5>}
                    <div className="row">
                        <div className="text-center mb-1">
                            {(EditScope?.linkage === 'Yes') ? <Button id='Yes' color='primary' style={{ width: '85px' }} value="Yes" onClick={(e) => onHandleLinkage(e)}>
                                {isLoading === true ? <Spinner size='sm' /> : 'Yes'}
                            </Button> : <Button id='Yes' outline color='primary' value="Yes" style={{ width: '85px' }} onClick={(e) => onHandleLinkage(e)}>Yes</Button>}&nbsp;&nbsp;
                            {(EditScope?.linkage === 'No') ? <Button id='No' color='primary' style={{ width: '85px' }} value="No" onClick={(e) => onHandleLinkage(e)}>
                                {isLoading === true ? <Spinner size='sm' /> : 'No'}
                            </Button> : <Button id='No' outline color='primary' value="No" style={{ width: '85px' }} onClick={(e) => onHandleLinkage(e)}>No</Button>}
                        </div>
                    </div >
                    <div className="row">
                        <div className="text-center mb-1">
                            {(EditScope?.linkage === 'Unconfirmed') ? <Button id='Unconfirmed' color='primary' style={{ width: '180px' }} value="Unconfirmed" onClick={(e) => onHandleLinkage(e)}>
                                {isLoading === true ? <Spinner size='sm' /> : 'Unconfirmed'}
                            </Button> : <Button id='Unconfirmed' outline color='primary' style={{ width: '180px' }} value="Unconfirmed" onClick={(e) => onHandleLinkage(e)}>Unconfirmed</Button>}
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {/* uncertainty modal */}
            <Modal backdrop='static' isOpen={certaintyModal} toggle={cancelHandler} className='modal-dialog-centered modal-lg '>
                <div></div>
                <ModalHeader toggle={cancelHandler}></ModalHeader>
                <h4 className="text-center mb-2 mt-1"> Undertake uncertainty and sensitivity assessment </h4>
                <ModalBody>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Row>
                            <Col>
                                {<label className="mb-1"  >Level of certainty of the source, pathway or receptor </label>}
                                <div>
                                    <select className="form-select" name="level" onChange={onHandleCertainty} >
                                        {certaintyData.level ? '' : <option selected > Select level certainty </option>}
                                        {
                                            level.map((level_item) => {
                                                return <option selected={certaintyData.level === level_item} value={level_item} > {level_item} </option>
                                            })
                                        }

                                    </select>
                                    {levelErr ? <span style={{ color: "red" }}>{levelErr}</span> : ''}
                                </div>
                                <label className="mt-1" >Level of certainty of the source, pathway or receptor description </label>
                                <Input value={certaintyData.levelDescription} className=" mt-2" style={{ width: "100%" }} type='textarea' onChange={onHandleCertainty} name='levelDescription' placeholder='Enter value' />
                                <div>
                                    {levelDescErr ? <span style={{ color: "red" }}>{levelDescErr}</span> : ''}
                                </div>

                                {<label className="mb-1 mt-1"  > Sensitivity to change assumptions of the source, pathway and receptor </label>}
                                <div >
                                    <select className="form-select" name="assumptions" onChange={onHandleCertainty} aria-label="Floating label select example">
                                        {certaintyData?.assumptions ? '' : <option selected > Select level sensitivity </option>}
                                        {
                                            assumption.map((assumption_item) => {
                                                return <option selected={certaintyData?.assumptions === assumption_item} value={assumption_item} > {assumption_item} </option>
                                            })
                                        }

                                    </select>
                                    {assumpErr ? <span style={{ color: "red" }}>{assumpErr}</span> : ''}
                                </div>
                                <label className="mt-1" >Sensitivity to change assumptions of the source, pathway and receptor description </label>
                                <Input value={certaintyData.assumptionDesc} className="mt-2" style={{ width: "100%" }} type='textarea' onChange={onHandleCertainty} name='assumptionDesc' placeholder='Enter value' />
                                {assumpDescErr ? <span style={{ color: "red" }}>{assumpDescErr}</span> : ''}
                                <p className="mt-1" >{<LinkActionsModal For={'PIED'} />}</p>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
                <ModalFooter className="modal-footer justify-content-center">
                    {<><Button color="primary" onClick={handleCertaintyData}>
                        {isLoading === true ? <Spinner size='sm' /> : 'SAVE'}
                    </Button>
                        <Button onClick={cancelHandler}>CANCEL</Button></>}
                </ModalFooter>
            </Modal>

            <Modal backdrop='static' isOpen={stakeHolderModal} toggle={cancelHandler} className='modal-dialog-centered'>
                <div></div>
                <ModalHeader toggle={cancelHandler}></ModalHeader>
                <h4 className="text-center mb-2 mt-1">Stakeholder Engagement</h4>
                <ModalBody>
                    <div className='mt-1' >
                        <Label>Stakeholder engagement (stakeholder views of the source, pathway or receptor and acceptability of impact level) </Label>
                        <Input value={stakeholderData} className=" mt-2" disabled={(LoginUserRole === 'Viewer')} style={{ width: "100%" }} type='textarea' onChange={onChangeStakeholder} name='measure_outcome' placeholder='Enter value' />
                        {stakehodlererr ? <span style={{ color: "red" }}>Please Enter value</span> : " "}
                    </div>
                </ModalBody>
                <ModalFooter className="modal-footer justify-content-center">
                    {(LoginUserRole === "Editor") ? (<><Button color="primary" onClick={saveStakeHolderData}>
                        {isLoading === true ? <Spinner size='sm' /> : 'SAVE'}
                    </Button>
                        <Button onClick={cancelHandler}>CANCEL</Button></>) : ''}
                </ModalFooter>
            </Modal>

            {/* //Confirmation Model Popup */}
            <div className=''>
                <div className='vertically-centered-modal'>
                    <Modal backdrop="static" isOpen={confirmationModal} toggle={() => setConfirmationModal(true)} className='modal-dialog-centered'>
                        <div></div>
                        <ModalHeader toggle={() => setConfirmationModal(true)}>Confirmation</ModalHeader>
                        <ModalBody><span> Do you want to delete this record ?</span></ModalBody>
                        <ModalFooter>
                            <Button color="primary" type='submit' onClick={onConfirmDelete}> {isLoading === true ? <Spinner size='sm' /> : 'Yes'} </Button>
                            <Button className="offset-2" color="secondary" onClick={() => setConfirmationModal(false)}> No</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
            {PIEDs && < PIEDs setClose={props.setClose} For={props.For} setPopoverOpen={props.setPopoverOpen} eventId={props.EditScope?.scopeId} potentialImpactEvent={potentialImpactEvent} setPotentialImpactEvent={setPotentialImpactEvent} />}

        </>
    )
}

export default AddNewScopingModal