// // ** Navigation imports
// import apps from './apps'
// import pages from './pages'
// import others from './others'
// import charts from './charts'
import dashboards from './dashboards'
// import uiElements from './ui-elements'
// import formsAndTables from './forms-tables'

// ** Merge & Export
export default [...dashboards]

// import { Mail, Home } from "react-feather"

// export default [
//   {
//     id: "home",
//     title: "Home",
//     icon: <Home size={20} />,
//     navLink: "/home"
//   },
//   {
//     id: "secondPage",
//     title: "Second Page",
//     icon: <Mail size={20} />,
//     navLink: "/second-page"
//   }
// ]
