import { Col, Row, Input, Button, Label, Card, CardHeader, CardTitle, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Spinner, Popover, PopoverBody } from 'reactstrap'
import toast from 'react-hot-toast'
import Avatar from '@components/avatar'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import axios from 'axios'
import React, { Fragment, useState, useEffect, useRef } from 'react'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { ChevronDown, Check, Trash, MoreHorizontal } from 'react-feather'
import AddNewScopingModal from './AddNewScopingModal'
import PrintScopingReport from './PrintScopingReport'
import { handleCustomisationData, handleEventId, handlePrintReport, handleclosepopover } from '../../redux/navbar'
import PotentialImpactSummary from '../pages/dashboard/Chart/PotentialImpactSummary'
import { handleProjectScopeIdsfromScopingToPotential, handleProjectActionUserStatus } from '../../redux/managementStrategy'
import { useParams } from 'react-router-dom'
import ProjectDetails from '../ProjectDetails/ProjectDetails'
import PrintReports from './PrintReports'
import useClickOutside from '../ClickOutsideHook/Outsideclick'


const Scoping = () => {
  const dispatch = useDispatch()
  const parms = useParams()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selected, setSelected] = useState([])
  const projectID = parms.projectId
  // const [confirmationModal, setConfirmationModal] = useState(false)
  // const [deltedId, setDeleteId] = useState('')
  const updateOne = useSelector(state => state.navbar.onUpdate)
  const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
  const activeTabFromRedux = useSelector((state) => state.navbar.activeTab)
  const close_popover = useSelector((state) => state.navbar.close_popover)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [searchValue] = useState('')
  const [data, setData] = useState([])
  const [totalRecord, setTotalRecords] = useState()
  const [getData, setGetData] = useState({
    projectId: parms.projectId,
    page: currentPage,
    perPage: rowsPerPage
  })
  const ref = useRef(null)
  useClickOutside(ref, () => setPopoverOpen(false))
  const popoverRef = useRef(null)
  // Pagination in Potential Impact Event Details (Scoping)
  useEffect(() => {
    setIsLoading(true)
    // if (projectID) {
    axios.post(`${process.env.REACT_APP_BASENAME}/getscopespagewise`, getData)  // all scopes from current Project
      .then((res) => {
        setData(res.data.data?.allData)
        dispatch(handleProjectScopeIdsfromScopingToPotential(res.data.data.allData))
        setTotalRecords(res.data.data.total)
        setIsLoading(false)
      })
    // }
    axios.post(`${process.env.REACT_APP_BASENAME}/getusersbyproject`, { projectId: parms.projectId, alluser: true })
      .then((res) => {
        dispatch(handleProjectActionUserStatus(res.data.data?.allData))
      })
  }, [updateOne, getData, activeTabFromRedux === '3'])


  // Code for selected and de-selected checkboxes
  const onHandleSelect = (id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
    dispatch(handlePrintReport(newSelected)) // for Print Report
  }

  const handleRow = (row) => {
    dispatch(handleCustomisationData(row))
    dispatch(handleEventId(row.scopeId))
    dispatch(handleclosepopover(true))
  }

  // const HandleSubmit = (id) => {
  //   setConfirmationModal(!confirmationModal)
  //   setDeleteId(id)
  // }

  //function for tosty Message 
  const Tosty = (msg) => {
    toast(
      <Row className='demo-vertical-spacing'>
        <div className='d-flex'><div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /></div>
          <div className='d-flex flex-column'><h6>{msg}</h6></div>
        </div>
      </Row>)
  }
   
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (popoverRef.current && !popoverRef.current.contains(event.target)) {
           if (close_popover) {
            setPopoverOpen(false)
           }
      }
      event.stopPropagation()
    }
    
      window.addEventListener('click', handleOutsideClick)

    return () => {
      window.removeEventListener('click', handleOutsideClick)
    }
  }, [close_popover])
  // function for Delete Scope
  // const onConfirmDelete = () => {
  //  setIsLoading(true)
  //   axios.post(`${process.env.REACT_APP_BASENAME}/deletescope`, { projectScopeIds: deltedId })
  //     .then((res) => {
  //       setIsLoading(false)
  //       if (res.data.data) {
  //         setDeleteId([])
  //         dispatch(handleUpdate(res.data.data))
  //         Tosty(res.data.data.message)
  //         setConfirmationModal(!confirmationModal)
  //       }
  //     })
  // }

  //code for de-selected checkbox after compliting action
  const isSelected = (id) => selected.indexOf(id) !== -1

  //Pagination Table Columns 
  const serverSideColumns = [
    {
      minWidth: '7px',
      cell: row => {
        return (
          <>
            {(LoginUserRole === 'Editor') ? <Input type='checkbox' checked={isSelected(row?._id)} className='cursor-pointer' onChange={() => onHandleSelect(row?._id)} /> : ''}
          </>
        )
      }
      //selector: row => <> {(LoginUserRole === 'Editor') ? <Input type='checkbox' checked={isSelected(row?._id)} className='cursor-pointer' onChange={() => onHandleSelect(row?._id)} /> : ''}</>
    },
    {
      sortable: true,
      name: 'Id',
      maxWidth: '10px',
      wrap: true,
      selector: row => row?.scopeId
    },
    {
      sortable: true,
      name: 'Stage',
      minWidth: '120px',
      wrap: true,
      selector: row => row?.stage
    },
    {
      sortable: true,
      name: 'Source',
      minWidth: '120px',
      wrap: true,
      selector: row => row?.source
    },
    {
      sortable: true,
      name: 'Pathway',
      minWidth: '150px',
      wrap: true,
      selector: row => row?.pathway
    },
    {
      sortable: true,
      name: 'Recepetor',
      minWidth: '150px',
      wrap: true,
      selector: row => row?.receptor
    },
    {
      sortable: true,
      name: 'Linkage',
      minWidth: '130px',
      wrap: true,
      selector: row => {
        return (
          <>
            <span className={row?.linkage === 'Yes' ? 'badge bg-success rounded-pill' : (row?.linkage === 'No') ? 'badge bg-info rounded-pill' : (row.linkage === 'Unconfirmed') ? 'badge bg-primary rounded-pill' : ''}>{row?.linkage}</span>
          </>
        )
      }
    },
    {
      sortable: true,
      name: `Applicable Guidelines/Standards/Legislation`,
      minWidth: '350px',
      wrap: true,
      selector: row => row?.relevantLegislation
    },
    {
      sortable: true,
      name: 'Last Modified',
      minWidth: '150px',
      wrap: true,
      selector: row => moment(new Date(row?.modifiedAt)).format("DD MMM YY")
    },
    {
      name: 'Action',
      minWidth: '120px',
      cell: row => {
        return (
          < >
            <span color='primary' outline id='controlledPopover'>
              <MoreHorizontal onClick={() => handleRow(row)} />
            </span>
            <Popover
              style={{ zIndex: 1200}}
              placement='top'
              isOpen={popoverOpen}
              target='controlledPopover'
              toggle={() => setPopoverOpen(true)}
            >
              {/* <PopoverHeader>Controlled Popover</PopoverHeader> */}
              <PopoverBody>
                {(LoginUserRole === 'Editor') ? (popoverOpen ? <span ref={popoverRef} className='d-flex align-items-center' >
                  <AddNewScopingModal setPopoverOpen={setPopoverOpen} projectId={projectID} For={'Edit'} EditScope={row} />                    {/* for Edit Scope */}
                  {/* <Trash color='#6e6b7b' size={17} className='cursor-pointer' onClick={() => HandleSubmit(row?._id)} style={{marginRight:'2%'}} />             */}
                </span> : '') : <AddNewScopingModal setPopoverOpen={setPopoverOpen} projectId={projectID} For={'Viewer'} EditScope={row} />}         {/* for View Sope if user is Viewer */}
              </PopoverBody>
            </Popover>

          </>)
      }
    }
  ]

  // ** Function to handle Pagination and get data from selected page no.
  const handlePagination = page => {
    setGetData({
      projectId: projectID,
      page: page.selected + 1,
      perPage: rowsPerPage
    })
    setCurrentPage(page.selected + 1)
  }

  // ** Function to handle records per page
  const handlePerPage = e => {
    setGetData({
      projectId: projectID,
      page: currentPage,
      perPage: parseInt(e.target.value)
    })
    setRowsPerPage(parseInt(e.target.value))
  }

  // ** Custom Pagination
  const CustomPagination = () => {
    const count = Math.ceil(totalRecord / rowsPerPage)
    return (
      <div className="d-flex align-items-center justify-content-between mt-1">
        <div style={{marginLeft:'10px'}} className='d-flex align-items-center'> <Label for='sort-select'> Select No. of Records </Label>
          <Input className='dataTable-select' type='select' id='sort-select' style={{ width: '70px', height: '33px', margin: '5px' }} value={rowsPerPage} onChange={e => handlePerPage(e)}>
            <option value={3}>3</option>
            <option value={5}>5</option>
            <option value={7}>7</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={75}>75</option>
            <option value={100}>100</option>
          </Input>
        </div>
        <ReactPaginate previousLabel={''} nextLabel={''} breakLabel='...' pageCount={Math.ceil(count) || 1} marginPagesDisplayed={2} pageRangeDisplayed={2} activeClassName='active' forcePage={currentPage !== 0 ? currentPage - 1 : 0} onPageChange={page => handlePagination(page)} pageClassName='page-item' breakClassName='page-item' nextLinkClassName='page-link' pageLinkClassName='page-link' breakLinkClassName='page-link' previousLinkClassName='page-link' nextClassName='page-item next-item' previousClassName='page-item prev-item' containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-0'} />
      </div>
    )
  }

  // ** Table data to render
  const dataToRender = () => {
    const filters = {
      q: searchValue
    }
    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })
    if (data?.length > 0) {
      return data
    } else if (data?.length === 0 && isFiltered) {
      return []
    } else {
      return data?.slice(0, rowsPerPage)
    }
  }
  return (
    <>
      <ProjectDetails />
      <Fragment>
        <Row >
          <Col md='8' xl='8' >
            <Card style={{ height: '349px' }}>
              <CardBody className='mb-3 mt-2'>
                <CardTitle tag='span' >
                Identify Potential Impact Events for each stage of the project by assessing the relationship between the identified sources, pathways and receptors. A potential impact event is the combination of a source, a pathway and a receptor.
                </CardTitle>
                <CardTitle style={{ marginTop: '12%' }}>
                  {(LoginUserRole === 'Editor') ? (<AddNewScopingModal setPopoverOpen={setPopoverOpen} For={'Create'} projectId={projectID} />) : ''}
                </CardTitle>
              </CardBody>
            </Card>
          </Col>
          <Col md='6' xl='4'>
            <Card >
              <CardBody>
                <CardTitle tag='h4' style={{ marginTop: '-9px' }} className='text-center' >Potential Impact Event Summary</CardTitle>
                <PotentialImpactSummary />
                <CardTitle style={{ marginBottom: '-15px', textAlign: 'center', fontSize: '12px' }} >Linkage</CardTitle>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Fragment>
      <Fragment>
        <Row>
          <Col md='10' xl='10'>
            {/* <PrintReports/> */}
            {/* <PrintScopingReport setSelected={setSelected} /> */}
            {<PrintScopingReport selected={selected} setSelected={setSelected} />}
            {/* <div id='print' >
            
            </div> */}
          </Col>
        </Row>
      </Fragment>
      <Fragment>
        <Card>
          <CardHeader className='border-bottom'>
            <CardTitle tag='h4'>Potential Impact Events</CardTitle>
          </CardHeader>
          <Row>
            {(data?.length === 0) ? (<h6 className=' text-center mt-1 mb-1'>{isLoading === true ? <Spinner size='sm' color="primary" /> : ''}  Potential Impact Events Will Appear Here</h6>) : (
              <> <div className='table-bordered table-responsive dataTable'>
                <DataTable noHeader pagination paginationServer className='react-dataTable' columns={serverSideColumns} sortIcon={<ChevronDown size={15} />} paginationComponent={CustomPagination} data={dataToRender()} />
              </div>
                {/* Pagination Part */}
                <Row className='mx-0 mt-0 mb-50'>
                  <Col sm='3'></Col>
                </Row></>)}
          </Row>
        </Card>
      </Fragment>
    </>
  )
}

export default Scoping