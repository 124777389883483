import React, { useEffect, useState } from 'react'
import axios from 'axios'
import font from './Roboto-Medium.ttf'
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  PDFDownloadLink,
  pdf,
  Font
} from "@react-pdf/renderer"
import { getCountry } from 'country-state-picker'
import { useSelector } from 'react-redux'
import { DropdownItem, DropdownMenu, Spinner } from 'reactstrap'

Font.register({
  family: "HeaderBold",
  format: "truetype",
  src: font
})

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: "#d11fb6",
    color: "white"
  },
  section: {
    margin: 10,
    padding: 10
  },
  viewer: {
    width: window.innerWidth, //the pdf viewer will take up all of the width and height
    height: window.innerHeight
  },
  parent: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  header: {
    backgroundColor: '#2961e3',
    border: '1px solid black',
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px',
    fontFamily: 'HeaderBold'
  },
  stakeholder: {
    backgroundColor: '#2961e3',
    marginTop: '5%',
    border: '1px solid black',
    width: '80%',
    display: 'flex',
    flexDirection: 'row',
    fontSize: '12px'
  },
  tablerow: {
    display: 'flex',
    flexDirection: 'row',
    width: '80%',
    borderBottom: '1px solid black',
    borderLeft: '1px solid black',
    borderRight: '1px solid black',
    fontSize: '12px'
  },
  border: {
    border: '1px solid black'
  },
  scopingRow: {
    padding: '3px',
    paddingLeft: '5px',
    borderRight: '1 px solid black'
  },
  flexProp: {
    display: 'flex'
  }
})

// Create Document Component
function PdfReport() {
  const report_type = useSelector((state) => state.navbar.report_type)
  const selectedProjectId = useSelector(state => state.navbar.printReport)
  const [data, setData] = useState([])
  const [relavaneManagementStratagy, setRelavaneManagementStratagy] = useState([])
  const [actionData, setActiondata] = useState([])
  const projectId = useSelector((state) => state.navbar.projectId)
  const [projectData, setProjectData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [impactData, setImpactData] = useState([])

  useEffect(() => {
    setIsLoading(true)
    switch (report_type) {
      case 'Project summary':
        axios.post(`${process.env.REACT_APP_BASENAME}/getprojectdetails`, { projectId }).then((res) => {
          if (res.data.data) {
            setProjectData(res.data.data?.project)
            setIsLoading(false)
          }
        })
        break
      case 'Scoping report':
        axios.post(`${process.env.REACT_APP_BASENAME}/printscopingreport`, { projectScopeIds: selectedProjectId })
          .then((res) => {
            setData(res.data.data)
            setIsLoading(false)
          })

        axios.post(`${process.env.REACT_APP_BASENAME}/getprojectstrategies`, { projectScopeId: selectedProjectId })
          .then((res) => {
            setRelavaneManagementStratagy(res.data.data)
          })
          
        axios.post(`${process.env.REACT_APP_BASENAME}/getprojectactionsforreport`, { projectScopeId: selectedProjectId })
        .then((res) => {
          setActiondata(res.data.data)
        })
        break
      case 'Impact assessment report':
        axios.post(`${process.env.REACT_APP_BASENAME}/printscopingreport`, { projectScopeIds: selectedProjectId })
          .then((res) => {
            setData(res.data.data)
            setIsLoading(false)
          })

        axios.post(`${process.env.REACT_APP_BASENAME}/getprojectstrategies`, { projectScopeId: selectedProjectId })
          .then((res) => {
            setRelavaneManagementStratagy(res.data.data)
          })

        axios.post(`${process.env.REACT_APP_BASENAME}/getimpactassesementdata`, { projectScopeId: selectedProjectId })
          .then((res) => {
            setImpactData(res.data.data)
          })

        axios.post(`${process.env.REACT_APP_BASENAME}/getprojectactionsforreport`, { projectScopeId: selectedProjectId })
          .then((res) => {
            setActiondata(res.data.data)
          })
    }

  }, [projectId])

  console.log(impactData)

  const generatePdfDocument = async () => {
    const blob = await pdf(<GenerateData />).toBlob()
    saveAs(blob, `${report_type}.pdf`)
  }

  const GenerateData = () => {
    switch (report_type) {
      case 'Project summary':
        return <>
          {/* <PDFViewer style={styles.viewer}> */}
          {/* Start of the document*/}
          <Document>
            {/*render a single page*/}
            <Page size="A4" style={{marginTop:'50px', marginBottom:'50px'}}>
              <View style={styles.parent}>
                <View style={styles.header}>
                  <Text style={{ padding: '3px', fontFamily: 'HeaderBold', fontWeight: 500 }} > Project Summary</Text>
                </View>
                <View style={styles.tablerow}>
                  <Text style={{ flex: 1, padding: '3px', backgroundColor: '#79C585', borderRight: '1px solid black' }} > Project Name</Text>
                  <Text style={{ flex: 2, padding: '3px' }} > {projectData?.projectName} </Text>
                </View>
                <View style={styles.tablerow}>
                  <Text style={{ flex: 1, padding: '3px', backgroundColor: '#79C585', borderRight: '1px solid black' }} > Project Type</Text>
                  <Text style={{ flex: 2, padding: '3px' }} > {projectData?.projectTypeId} </Text>
                </View>
                <View style={styles.tablerow}>
                  <Text style={{ flex: 1, padding: '3px', backgroundColor: '#79C585', borderRight: '1px solid black' }} > Project Description</Text>
                  <Text style={{ flex: 2, padding: '3px' }} > {projectData?.description} </Text>
                </View>
                <View style={styles.tablerow}>
                  <Text style={{ flex: 1, padding: '3px', backgroundColor: '#79C585', borderRight: '1px solid black' }} > Country </Text>
                  <Text style={{ flex: 2, padding: '3px' }} > {getCountry(projectData?.countryId).name} </Text>
                </View>
                <View style={styles.tablerow}>
                  <Text style={{ flex: 1, padding: '3px', backgroundColor: '#79C585', borderRight: '1px solid black' }} > State/Province </Text>
                  <Text style={{ flex: 2, padding: '3px' }} > {projectData?.stateId} </Text>
                </View>
                <View style={styles.tablerow}>
                  <Text style={{ flex: 1, padding: '3px', backgroundColor: '#79C585', borderRight: '1px solid black' }} > Project Coordinates </Text>
                  <Text style={{ flex: 2, padding: '3px' }} > {projectData?.location} </Text>
                </View>
              </View>
            </Page>
          </Document>
          {/* </PDFViewer> */}
        </>
        break
      case 'Scoping report':
        return <>
          <Document>
            {data.map((projectScope) => {
              return <Page size="A4" style={{marginTop:'50px', marginBottom:'50px'}} orientation='landscape'>
                <View style={styles.parent}>
                  <View style={styles.header}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#3a85d6' }]} >ID </Text>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#ffffff' }]}>{projectScope.scopeId} </Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: '#3a85d6' }]}>Stage </Text>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#ffffff' }]}>{projectScope.stage} </Text>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#3a85d6' }]}>Potential impact event description </Text>
                    <Text style={[styles.scopingRow, { flex: 2, backgroundColor: '#ffffff' }]}>{projectScope.potentialImpactDesciption} </Text>
                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#79C585' }]} >Source </Text>
                    <Text style={[styles.scopingRow, { flex: 1 }]}> {projectScope.source} </Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: '#79C585' }]}>Source description </Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>{projectScope.sourceDescription}</Text>

                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#79C585' }]} >Pathway </Text>
                    <Text style={[styles.scopingRow, { flex: 1 }]}> {projectScope.pathway} </Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: '#79C585' }]}>Pathway description </Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>{projectScope.pathwayDescription} </Text>

                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#79C585' }]} >Receptor </Text>
                    <Text style={[styles.scopingRow, { flex: 1 }]}> {projectScope.receptor} </Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: '#79C585' }]}>Receptor description </Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>{projectScope.receptorDescription}</Text>

                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#79C585' }]} >Confirmation of linkage </Text>
                    <Text style={[styles.scopingRow, { flex: 1 }]}> {projectScope.linkage} </Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: '#79C585' }]}>Linkage justification </Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>{projectScope.linkageDescription} </Text>

                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#79C585' }]} >Actions (Forward Works Plan) </Text>
                    <Text style={[styles.scopingRow, { flex: 1 }]}>
                      {actionData[projectScope.scopeId]?.length === 0 ? <Text>-</Text> : actionData[projectScope.scopeId]?.map((action, i) => {
                        return <Text>{`${ i < actionData[projectScope.scopeId]?.length - 1 ? `${action.subject}, ` : action.subject }`}</Text>
                      })}
                    </Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: '#79C585' }]}>Relevant Guidelines, Standards or Legislation </Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>{projectScope.relevantLegislation} </Text>

                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#79C585' }]} >Relevant Management Strategies and role </Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>

                      {relavaneManagementStratagy[projectScope.scopeId]?.length === 0 ? <Text>-</Text> : relavaneManagementStratagy[projectScope.scopeId]?.map((strategy, i) => {
                        return <Text>{`${ i < relavaneManagementStratagy[projectScope.scopeId]?.length - 1 ? `${strategy.title}, ` : `${strategy.title}`}`}</Text>
                      })}

                    </Text>

                  </View>
                </View>
              </Page>
            })}
          </Document>
        </>
        break
      case 'Impact assessment report':
        return <>
          <Document>
            {data.map((projectScope) => {
              let filtered_certaintydata = impactData?.certainty_data?.filter((item) => item.linkageId === projectScope._id)
              if (filtered_certaintydata.length === 0) {
                filtered_certaintydata = [{}]
              }

              let filtered_rawriskdata = impactData?.rawrisk_data?.filter((item_rawrisk) => item_rawrisk.impact_event_id === projectScope._id)
            
              if (filtered_rawriskdata.length === 0) {
                filtered_rawriskdata = [{}]
              }

              let filtered_residudalriskdata = impactData?.residual_risk?.filter((residual_risk) => residual_risk.impact_event_id === projectScope._id)
              
              if (filtered_residudalriskdata.length === 0) {
                filtered_residudalriskdata = [{}]
              }

              let filtered_managementstrategy =  impactData?.management_strategy?.filter((event_strategy) => event_strategy?.scopes.includes(projectScope.scopeId))

              if (filtered_managementstrategy.length === 0) {
                filtered_managementstrategy = [{}]
              }

              let filtered_monitoringdata = impactData?.outcome_monitoring_data?.filter((outcome_data) => outcome_data.impact_event_id === projectScope._id)

              if (filtered_monitoringdata.length === 0) {
                filtered_monitoringdata = [{}]
              }

              let filtered_stakeholerdata = impactData?.stakeholder_data?.filter((statement_data) => statement_data.impact_event_id === projectScope._id)
              
              if (filtered_stakeholerdata.length === 0) {
                filtered_stakeholerdata = [{}]
              }
              return projectScope.linkage === 'Yes' ? <Page style={{marginTop:'50px', marginBottom:'50px'}} size="A3" orientation='landscape'>
                <View style={styles.parent}>
                  <View style={styles.header}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#3a85d6' }]} >ID </Text>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#ffffff' }]}>{projectScope?.scopeId}</Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: '#3a85d6' }]}>Stage </Text>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#ffffff' }]}>{projectScope.stage}</Text>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#3a85d6' }]}>Potential impact event description</Text>
                    <Text style={[styles.scopingRow, { flex: 2, backgroundColor: '#ffffff' }]}>{projectScope.potentialImpactDesciption}</Text>
                  </View>
                  <View style={[styles.header, { marginTop: 'none', backgroundColor: '#79C585', borderTop: 'none' }]} >
                    <Text style={[styles.scopingRow, { borderRight: '1px solid #79C585' }]} >Potential Impact Event Assessment </Text>
                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: 'lightgrey' }]} >Source </Text>
                    <Text style={[styles.scopingRow, { flex: 1 }]}>{projectScope.source}</Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: 'lightgrey' }]}>Source description </Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>{projectScope.sourceDescription}</Text>

                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: 'lightgrey' }]} >Pathway </Text>
                    <Text style={[styles.scopingRow, { flex: 1 }]}>{projectScope.pathway}</Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: 'lightgrey' }]}>Pathway description </Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>{projectScope.pathwayDescription}</Text>
                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: 'lightgrey' }]} >Receptor </Text>
                    <Text style={[styles.scopingRow, { flex: 1 }]}>{projectScope.receptor}</Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: 'lightgrey' }]}>Receptor description </Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>{projectScope.receptorDescription} </Text>

                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: 'lightgrey' }]} >Confirmation of linkage </Text>
                    <Text style={[styles.scopingRow, { flex: 1 }]}>{projectScope.linkage}</Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: 'lightgrey' }]}>Linkage justification </Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>{projectScope.linkageDescription}</Text>

                  </View>
                  {
                    filtered_certaintydata.map((certainty) => {
                      console.log(certainty)
                      return <>
                        <View style={[styles.header, { marginTop: 'none', backgroundColor: '#79C585', borderTop: 'none' }]} >
                          <Text style={[styles.scopingRow, { borderRight: '1px solid #79C585' }]} >Potential Impact Event Uncertainty and Sensitivity Assessment</Text>
                        </View>
                        <View style={styles.tablerow}>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]} >Level of certainty of the Source, Pathway or Receptor</Text>
                          <Text style={[styles.scopingRow, { flex: 1 }]}>{certainty.level}</Text>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]}>Level of certainty of the Source, Pathway or Receptor description </Text>
                          <Text style={[styles.scopingRow, { flex: 1, borderRight: '1px solid white' }]}>{certainty.levelDescription}</Text>
                          <Text style={[styles.scopingRow, { flex: 1, borderRight: '1px solid white' }]}> </Text>
                          <Text style={[styles.scopingRow, { flex: 2, borderRight: '1px solid white' }]}> </Text>
                        </View>
                        <View style={styles.tablerow}>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]} >Sensitivity to change in assumptions of the Source, Pathway and Receptor </Text>
                          <Text style={[styles.scopingRow, { flex: 1 }]}>{certainty.assumptions}</Text>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]}>Sensitivity to change in assumptions of the Source, Pathway and Receptor description </Text>
                          <Text style={[styles.scopingRow, { flex: 1, borderRight: '1px solid white' }]}>{certainty.assumptionDesc}</Text>
                          <Text style={[styles.scopingRow, { flex: 1, borderRight: '1px solid white' }]}> </Text>
                          <Text style={[styles.scopingRow, { flex: 2, borderRight: '1px solid white' }]}> </Text>
                        </View>
                      </>
                    })
                  }
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: 'lightgrey' }]} >Actions (Forward Works Plan) </Text>
                    <Text style={[styles.scopingRow, { flex: 3.5 }]}>
                      {actionData[projectScope.scopeId]?.length === 0 ? <Text>-</Text> : actionData[projectScope.scopeId]?.map((action, i) => {
                        return <Text>{`${ i < actionData[projectScope.scopeId]?.length - 1 ? `${action.subject}, ` : action.subject }`}</Text>
                      })}
                    </Text>
                    <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]}>Relevant Guidelines, Standards or Legislation</Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>{projectScope.relevantLegislation}</Text>

                  </View>
                  {
                    filtered_rawriskdata.map((rawrisk) => {
                      return <>
                        <View style={[styles.header, { marginTop: 'none', backgroundColor: '#79C585', borderTop: 'none' }]} >
                          <Text style={[styles.scopingRow, { borderRight: '1px solid #79C585' }]} >Impact Assessment</Text>
                        </View>
                        <View style={[styles.tablerow, { backgroundColor: 'lightgrey' }]}>
                          <Text style={[styles.scopingRow, { backgroundColor: 'lightgrey', borderRight: 'none' }]} >Raw Risk/Impact (Pre-Management Strategies) </Text>
                        </View>
                        <View style={styles.tablerow}>
                          <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: 'lightgrey' }]} >Likelihood</Text>
                          <Text style={[styles.scopingRow, { flex: 1 }]}>{rawrisk.likelihood}</Text>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]}>Impact significance</Text>
                          <Text style={[styles.scopingRow, { flex: 1 }]}>{rawrisk.significance}</Text>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]}>Rating</Text>
                          <Text style={[styles.scopingRow, { flex: 1, borderRight: '1px solid white' }]}>{rawrisk.impact_significance}</Text>
                        </View>
                      </>
                    })
                  }
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: 'lightgrey' }]} >Relevant Management Strategies and role</Text>
                    <Text style={[styles.scopingRow, { flex: 3 }]}>
                      {relavaneManagementStratagy[projectScope.scopeId]?.length === 0 ? <Text>-</Text> : relavaneManagementStratagy[projectScope.scopeId]?.map((strategy, i) => {
                        return <Text>{`${ i < relavaneManagementStratagy[projectScope.scopeId]?.length - 1 ? `${strategy.title}, ` : `${strategy.title}`}`}</Text>
                      })}
                    </Text>
                  </View>

                  {
                    filtered_residudalriskdata.map((residualrisk) => {
                      return <>
                        <View style={[styles.tablerow, { backgroundColor: 'lightgrey' }]}>
                          <Text style={[styles.scopingRow, { backgroundColor: 'lightgrey', borderRight: 'none' }]} >Raw Risk/Impact (Post-Management Strategies) </Text>
                        </View>
                        <View style={styles.tablerow}>
                          <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: 'lightgrey' }]} >Likelihood</Text>
                          <Text style={[styles.scopingRow, { flex: 1 }]}>{residualrisk.likelihood}</Text>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]}>Impact significance</Text>
                          <Text style={[styles.scopingRow, { flex: 1 }]}>{residualrisk.consequence}</Text>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]}>Rating</Text>
                          <Text style={[styles.scopingRow, { flex: 1, borderRight: '1px solid white' }]}>{residualrisk.impact_significance}</Text>
                        </View>
                      </>
                    })
                  }

                  {
                    filtered_managementstrategy?.map((strategy) => {
                      return <>
                        <View style={[styles.header, { marginTop: 'none', backgroundColor: '#79C585', borderTop: 'none' }]} >
                          <Text style={[styles.scopingRow, { borderRight: '1px solid #79C585' }]} >Management Strategies Uncertainty and Sensitivity Assessment </Text>
                        </View>
                        <View style={styles.tablerow}>
                          <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: 'lightgrey' }]} >Level of certainty of Management Strategy effectiveness</Text>
                          <Text style={[styles.scopingRow, { flex: 1 }]}>{strategy?.level}</Text>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]}>Level of certainty of Management Strategy effectiveness description</Text>
                          <Text style={[styles.scopingRow, { flex: 1 }]}>{strategy?.levelDescription} </Text>
                        </View>
                        <View style={styles.tablerow}>
                          <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: 'lightgrey' }]} >Sensitivity to change of assumptions of the Control Measures</Text>
                          <Text style={[styles.scopingRow, { flex: 1 }]}>{strategy?.assumptions}</Text>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]}>Sensitivity to change of assumptions of the Control Measures description</Text>
                          <Text style={[styles.scopingRow, { flex: 1 }]}>{strategy?.assumptionDesc}</Text>
                        </View>
                      </>
                    })
                  }
                  {
                    filtered_monitoringdata?.map((outcome) => {
                      return <>
                        <View style={[styles.header, { marginTop: 'none', backgroundColor: '#79C585', borderTop: 'none' }]} >
                          <Text style={[styles.scopingRow, { borderRight: '1px solid #79C585' }]} >Outcomes and Monitoring </Text>
                        </View>
                        <View style={styles.tablerow}>
                          <Text style={[styles.scopingRow, { flex: 1, backgroundColor: 'lightgrey' }]} >Statement of the Environmental Outcomes that are expected to occur</Text>
                          <Text style={[styles.scopingRow, { flex: 3 }]}>{outcome.env_outcome}</Text>
                        </View>
                        <View style={styles.tablerow}>
                          <Text style={[styles.scopingRow, { flex: 1, backgroundColor: 'lightgrey' }]} >Statement of the Criteria to be adopted to measure the Environmental Outcomes</Text>
                          <Text style={[styles.scopingRow, { flex: 3 }]}>{outcome.measure_outcome?.map((mea_out) => <Text> {`${mea_out}, `} </Text>)}</Text>
                        </View>
                        <View style={styles.tablerow}>
                          <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: 'lightgrey' }]} >Is there a high reliance on Management Strategies to achieve an Environmental Outcome?</Text>
                          <Text style={[styles.scopingRow, { flex: 1 }]}>{outcome.lead_indicator}</Text>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]}>Statement of Leading Indicator Criteria that will be used to give an early warning that a Control Measure may fail or be failing</Text>
                          <Text style={[styles.scopingRow, { flex: 1 }]}>{outcome.statement_criteria?.map((mea_out) => <Text> {`${mea_out}, `} </Text>)}</Text>
                        </View>
                      </>
                    })
                  }
                  {
                   filtered_stakeholerdata?.map((statement) => {
                      return <>
                        <View style={[styles.header, { marginTop: 'none', backgroundColor: '#79C585', borderTop: 'none' }]} >
                          <Text style={[styles.scopingRow, { borderRight: '1px solid #79C585' }]} >Stakeholder engagement (stakeholder views of the Source, Pathway or Receptor and acceptability of impact level) </Text>
                        </View>
                        <View style={[styles.stakeholder, { marginTop: 'none', borderTop: 'none', backgroundColor: '#ffffff' }]} >
                          <Text> {statement.stakeholder_engagement} </Text>
                        </View>
                      </>
                    })
                  }
                </View>
              </Page> : <Page style={{marginTop:'50px', marginBottom:'50px'}} size="A3" orientation='landscape'>
                <View style={styles.parent}>
                  <View style={styles.header}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#3a85d6' }]} >ID </Text>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#ffffff' }]}>{projectScope.scopeId}</Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: '#3a85d6' }]}>Stage </Text>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#ffffff' }]}>{projectScope.stage}</Text>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: '#3a85d6' }]}>Potential Impact Event Description </Text>
                    <Text style={[styles.scopingRow, { flex: 2, backgroundColor: '#ffffff' }]}>{projectScope.potentialImpactDesciption}</Text>
                  </View>
                  <View style={[styles.header, { marginTop: 'none', backgroundColor: '#79C585', borderTop: 'none' }]} >
                    <Text style={[styles.scopingRow, { borderRight: '1px solid #79C585' }]} >Potential impact event description </Text>
                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: 'lightgrey' }]} >Source </Text>
                    <Text style={[styles.scopingRow, { flex: 1 }]}>{projectScope.source} </Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: 'lightgrey' }]}>Source description </Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>{projectScope.sourceDescription}</Text>

                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: 'lightgrey' }]} >Pathway </Text>
                    <Text style={[styles.scopingRow, { flex: 1 }]}>{projectScope.pathway}</Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: 'lightgrey' }]}>Pathway description </Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>{projectScope.pathwayDescription}</Text>

                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: 'lightgrey' }]} >Receptor </Text>
                    <Text style={[styles.scopingRow, { flex: 1 }]}>{projectScope.receptor}</Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: 'lightgrey' }]}>Receptor description </Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>{projectScope.receptorDescription}</Text>

                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: 'lightgrey' }]} >Confirmation of linkage</Text>
                    <Text style={[styles.scopingRow, { flex: 1 }]}>{projectScope.linkage}</Text>
                    <Text style={[styles.scopingRow, { flex: 1.5, backgroundColor: 'lightgrey' }]}>Linkage justification </Text>
                    <Text style={[styles.scopingRow, { flex: 3, borderRight: '1px solid white' }]}>{projectScope.linkageDescription} </Text>

                  </View>
                  {
                    filtered_certaintydata.filter((item) => item.linkageId === projectScope._id).map((certainty) => {
                      return <>
                        <View style={[styles.header, { marginTop: 'none', backgroundColor: '#79C585', borderTop: 'none' }]} >
                          <Text style={[styles.scopingRow, { borderRight: '1px solid #79C585' }]} >Potential Impact Event Uncertainty and Sensitivity Assessment </Text>
                        </View>
                        <View style={styles.tablerow}>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]} >Level of certainty of the Source, Pathway or Receptor </Text>
                          <Text style={[styles.scopingRow, { flex: 1 }]}>{certainty.level}</Text>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]}>Level of certainty of the Source, Pathway or Receptor description</Text>
                          <Text style={[styles.scopingRow, { flex: 1, borderRight: '1px solid white' }]}>{certainty.levelDescription}</Text>
                          <Text style={[styles.scopingRow, { flex: 1, borderRight: '1px solid white' }]}> </Text>
                          <Text style={[styles.scopingRow, { flex: 2, borderRight: '1px solid white' }]}> </Text>
                        </View>
                        <View style={styles.tablerow}>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]} >Sensitivity to change in assumptions of the Source, Pathway and Receptor</Text>
                          <Text style={[styles.scopingRow, { flex: 1 }]}>{certainty.assumptions}</Text>
                          <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]}>Sensitivity to change in assumptions of the Source, Pathway and Receptor description</Text>
                          <Text style={[styles.scopingRow, { flex: 1, borderRight: '1px solid white' }]}>{certainty.assumptionDesc}</Text>
                          <Text style={[styles.scopingRow, { flex: 1, borderRight: '1px solid white' }]}> </Text>
                          <Text style={[styles.scopingRow, { flex: 2, borderRight: '1px solid white' }]}> </Text>
                        </View>
                      </>
                    })
                  }
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: 'lightgrey' }]} >Actions (Forward Works Plan) </Text>
                    <Text style={[styles.scopingRow, { flex: 3.5 }]}>
                      {actionData[projectScope.scopeId]?.length === 0 ? <Text>-</Text> : actionData[projectScope.scopeId]?.map((action, i) => {
                        return <Text>{`${ i < actionData[projectScope.scopeId]?.length - 1 ? `${action.subject}, ` : action.subject }`}</Text>
                      })}
                    </Text>
                    <Text style={[styles.scopingRow, { flex: 2, backgroundColor: 'lightgrey' }]}>Relevant Guidelines, Standards or Legislation</Text>
                    <Text style={[styles.scopingRow, { flex: 1, borderRight: '1px solid white' }]}>{projectScope.relevantLegislation}</Text>
                    <Text style={[styles.scopingRow, { flex: 2, borderRight: '1px solid white' }]}> </Text>
                    <Text style={[styles.scopingRow, { flex: 1, borderRight: '1px solid white' }]}> </Text>
                  </View>
                  <View style={styles.tablerow}>
                    <Text style={[styles.scopingRow, { flex: 1, backgroundColor: 'lightgrey' }]} >Relevant Management Strategies and role </Text>
                    <Text style={[styles.scopingRow, { flex: 3.5 }]}>
                      {relavaneManagementStratagy[projectScope.scopeId]?.length === 0 ? <Text>-</Text> : relavaneManagementStratagy[projectScope.scopeId]?.map((strategy, i) => {
                        return <Text>{`${ i < relavaneManagementStratagy[projectScope.scopeId]?.length - 1 ? `${strategy.title}, ` : `${strategy.title}`}`}</Text>
                      })} </Text>
                  </View>
                  {
                   filtered_stakeholerdata.map((statement) => {
                      return <>
                        <View style={[styles.header, { marginTop: 'none', backgroundColor: '#79C585', borderTop: 'none' }]} >
                          <Text style={[styles.scopingRow, { borderRight: '1px solid #79C585' }]} >Stakeholder engagement (stakeholder views of the Source, Pathway or Receptor and acceptability of impact level)</Text>
                        </View>
                        <View style={[styles.stakeholder, { marginTop: 'none', borderTop: 'none', backgroundColor: '#ffffff' }]} >
                          <Text> {statement.stakeholder_engagement} </Text>
                        </View>
                      </>
                    })
                  }
                </View>
              </Page>


            })}
          </Document>
        </>
        //  return <span onClick={generatePdfDocument}>{isLoading === true ? <Spinner size='sm' /> : 'PDF'}</span>
        break
    }
  }

  return (
    <>
      <DropdownItem className='w-100' onClick={generatePdfDocument} end> {isLoading === true ? <Spinner size='sm' /> : 'PDF'}</DropdownItem>
    </>
  )
}
export default PdfReport
