// ** React Imports
import { Link, useNavigate } from "react-router-dom"
// ** Custom Components
import Avatar from "@components/avatar"

// ** Third Party Components
import {
  User,
  LogOut
} from "react-feather"

// ** Reactstrap Imports
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem
} from "reactstrap"

import { useDispatch, useSelector } from "react-redux"
import { handleLogout } from "../../../../redux/authentication"
import { handleAllProjects } from "../../../../redux/navbar"

// ** Default Avatar Image
//import defaultAvatar from "@src/assets/images/portrait/small/avatar-s-11.png"

const UserDropdown = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {userDetails} = useSelector((state) => state.authentication)
  const fullName = `${localStorage.getItem('firstName')} ${localStorage.getItem('lastName')}`

  return (
    <>
   
    <UncontrolledDropdown tag="li"  className=" d-flex align-items-center dropdown-user nav-item ">
    <span style={{fontSize:"18px"}} >{userDetails.firstName}</span>
      <DropdownToggle
        href="/"
       tag="a"
        className="nav-link dropdown-user-link"
        onClick={(e) => e.preventDefault()}
      >
        <div className="user-nav d-sm-flex d-none ">
          {/* <span className="user-name fw-bold">John Doe</span>*/}
           {/* <span className="user-status">Admin</span>   */}
        </div>
        <Avatar
          content= {fullName ?? ''}
          imgHeight="20"
          imgWidth="20"
          initials
          //status="online"
        />
       
        {/* <User color="gray" size={25} /> */}
      </DropdownToggle>
      <DropdownMenu end>
      <DropdownItem tag={Link} to='/login' onClick={() => {
        dispatch(handleLogout())
        dispatch(handleAllProjects([]))
        localStorage.removeItem('loggedInUserId')
            navigate("/login")
            
            }}>
          <LogOut size={14} className='me-75' />
          <span className='align-middle'>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
    </>
  )
}

export default UserDropdown
