import { Fragment, useEffect, useState } from "react"
import { Col, Row, Card, Label, Input, ModalBody, ModalFooter, Button, Modal, ModalHeader, Spinner } from "reactstrap"
import AddNewUser from "./AddNewUser"
import axios from 'axios'
import { useSelector, useDispatch } from "react-redux"
import { Airplay, ChevronDown, Check, Trash, Edit2, Eye } from 'react-feather'
import toast from 'react-hot-toast'
import Avatar from '@components/avatar'
import DataTable from 'react-data-table-component'
import { handleUpdate } from "../../redux/navbar"
import ReactPaginate from 'react-paginate'
import { handleProjectActionUserStatus } from "../../redux/managementStrategy"
import { useParams } from "react-router-dom"
import ProjectDetails from "../ProjectDetails/ProjectDetails"
const Permissions = () => {

  const [isLoading, setIsLoading] = useState(false)
  const parms = useParams()
  const [confirmationModal, setConfirmationModal] = useState()
  const [deleteId, setDeleteId] = useState([])
  const [selectedSingle, setSelecedSingle] = useState([])
  const updateOne = useSelector(state => state.navbar.onUpdate)
  const dispatch = useDispatch()
  const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
  const loggedInUserId = useSelector(state => state.navbar.loggedInUserId)
  const activeTabFromRedux = useSelector((state) => state.navbar.activeTab)
  const projectID = parms.projectId
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [searchValue] = useState('')
  const [data, setData] = useState([])
  const [totalRecord, setTotalRecords] = useState()
  //for pagination
  const [getData, setGetData] = useState({
    projectId: parms.projectId,
    page: currentPage,
    perPage: rowsPerPage
  })

  //get userlist by project Id with Pagination
  useEffect(() => {
    setIsLoading(true)
    axios.post(`${process.env.REACT_APP_BASENAME}/getusersbyproject`, getData)
      .then((res) => {
        //console.log('Res', res.data.data)
        setData(res.data.data.allData)
        setTotalRecords(res.data.data.total)
        setIsLoading(false)
        dispatch(handleProjectActionUserStatus(res.data.data.allData))
      })
  }, [updateOne, getData, activeTabFromRedux === '6'])

  // to handle Delete id of user
  const HandleDelete = (id) => {
    setConfirmationModal(!confirmationModal)
    setSelecedSingle(id)
    // setDeleteId(id)
  }

  //Checkbox code
  const onHandleSelect = (id) => {
    const selectedIndex = deleteId.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(deleteId, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(deleteId.slice(1))
    } else if (selectedIndex === deleteId.length - 1) {
      newSelected = newSelected.concat(deleteId.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(deleteId.slice(0, selectedIndex), deleteId.slice(selectedIndex + 1))
    }
    setDeleteId(newSelected)
    // setSelecedSingle(newSelected)
  }

  // for de-select the previos item after complition of task
  const isSelected = (id) => deleteId.indexOf(id) !== -1

  // server side columns
  const serverSideColumns = [
    {
      minWidth: '50px',
      cell: row => {
        return (
          <>
            {(LoginUserRole === 'Editor') ? (loggedInUserId === row.userId) ? '' : <Input type='checkbox' checked={isSelected(row?._id)} className='cursor-pointer' onChange={() => onHandleSelect(row?._id)} /> : ''}
          </>
        )
      }
    },
    {
      sortable: true,
      name: 'User',
      wrap: true,
      minWidth: '20%',
      selector: row => row?.fullName
    },
    {
      sortable: true,
      name: 'Email',
      minWidth: '25%',
      wrap: true,
      selector: row => row?.email
    },
    {
      sortable: true,
      name: 'Role',
      minWidth: '20%',
      selector: row => <>{row.role === 'Editor' ? <Edit2 color='#6e6b7b' size={15} /> : row.role === 'Viewer' ? <Airplay size={15} style={{ color: 'red' }} /> : ''} {row?.role} </>
    },
    {
      sortable: true,
      name: 'Status',
      minWidth: '20%',
      selector: row => {
        return (
          <>
            <span className={row.status === 'Active' ? 'badge bg-light-success rounded-pill' : (row.status === 'Pending') ? 'badge bg-light-danger rounded-pill' : 'badge bg-light-primary rounded-pill'}>{row.status}</span>
          </>
        )
      }
    },
    {
      name: 'Action',
      minWidth: '100px',
      cell: row => {
        return (
          <>
            {/* to hide edit delete button if user is viewer in this project */}
            {(LoginUserRole === 'Editor') ? (loggedInUserId === row.userId) ? '' : (<>
              <Row> <Col>
                <AddNewUser For={'Edit'} className='cursor-pointer float-start ms-1' EditProjectUser={row} />
                <Trash color='#6e6b7b' className='cursor-pointer float-end ms-1' onClick={() => HandleDelete(row?._id)} size={17} />
              </Col> </Row>
            </>) : ''}
          </>)
      }
    }
  ]
  //handle Pagination
  const handlePagination = page => {
    setGetData({
      projectId: projectID,
      page: page.selected + 1,
      perPage: rowsPerPage
    })
    setCurrentPage(page.selected + 1)
  }

  // handle per page logic
  const handlePerPage = e => {
    setGetData({
      projectId: projectID,
      page: currentPage,
      perPage: parseInt(e.target.value)
    })
    setRowsPerPage(parseInt(e.target.value))
  }
  const CustomPagination = () => {
    const count = Math.ceil(totalRecord / rowsPerPage)

    //Pagination Component
    return (
      <div className="d-flex align-items-center justify-content-between mt-1">
        <div style={{marginLeft:'10px'}} className='d-flex align-items-center'>
          <Label for='sort-select'> Select No. of Records </Label>
          <Input className='dataTable-select' style={{ width: '70px', height: '33px', margin: '5px' }} type='select' id='sort-select' value={rowsPerPage} onChange={e => handlePerPage(e)}>
            <option value={3}>3</option>
            <option value={5}>5</option>
            <option value={7}>7</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={75}>75</option>
            <option value={100}>100</option>
          </Input>
        </div>
        <ReactPaginate previousLabel={''} nextLabel={''} breakLabel='...' pageCount={Math.ceil(count) || 1} marginPagesDisplayed={2} pageRangeDisplayed={2} activeClassName='active' forcePage={currentPage !== 0 ? currentPage - 1 : 0} onPageChange={page => handlePagination(page)} pageClassName='page-item' breakClassName='page-item' nextLinkClassName='page-link' pageLinkClassName='page-link' breakLinkClassName='page-link' previousLinkClassName='page-link' nextClassName='page-item next-item' previousClassName='page-item prev-item' containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-0'}
        />
      </div>
    )
  }

  // ** Table data to render
  const dataToRender = () => {
    const filters = {
      q: searchValue
    }
    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })
    if (data?.length > 0) {
      return data
    } else if (data?.length === 0 && isFiltered) {
      return []
    } else {
      return data?.slice(0, rowsPerPage)
    }
  }
  // cancel Handler for delete
  const cancelHandler = () => {
    setDeleteId([])
    setSelecedSingle([])
    setConfirmationModal(!confirmationModal)
  }

  // delete project user
  const onConfirmDelete = () => {
    setIsLoading(true)
    //console.log('Res', deleteId, selectedSingle)
    let deleteNew = []
    if (selectedSingle?.length === 0) {
      deleteNew = deleteId
    } else {
      deleteNew = selectedSingle
    }
    axios.post(`${process.env.REACT_APP_BASENAME}/deleteprojectuser`, { projectUserIds: deleteNew.toString() })
      .then((res) => {
        dispatch(handleUpdate(res.data))
        if (res.data.data) {
          setIsLoading(false)
          setConfirmationModal(!confirmationModal)
          toast(
            <Row className='demo-vertical-spacing'>
              <div className='d-flex'>
                <div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /></div>
                <div className='d-flex flex-column'><h6>{res.data.data.message}</h6></div></div>
            </Row>
          )
          setDeleteId([])
          // setSelecedSingle([])
        }
      })
  }

  return (
    <>
      <ProjectDetails />
      {(LoginUserRole === 'Editor') ? (
        <>
          <Fragment>
          <Row>
              <Col className='mb-1'>
                  <Button color="secondary" disabled={deleteId?.length === 0} style={{ marginRight: '12px' }} className=' float-start' onClick={() => setConfirmationModal(!confirmationModal)}>Delete</Button>
                </Col>
                <Col >{(LoginUserRole === 'Viewer') || (deleteId.length > 0) ? '' : <AddNewUser For={'Create'} />}
                </Col>
            </Row>
            <Card><Row className='mt-1'><Col sm='10 '><h4 className="ms-1 float-start">All Users</h4></Col></Row>
              <hr />
              <Row>
                {(data?.length === 0) ? (<h6 className=' text-center'> Permissions Will Appear Here</h6>) : (
                  <>
                    <Row><Col sm='12'>
                      <DataTable noHeader pagination paginationServer className='react-dataTable table-bordered' columns={serverSideColumns} sortIcon={<ChevronDown size={15} />} paginationComponent={CustomPagination} data={dataToRender()} />
                    </Col>
                    </Row>
                    <Row className='mx-0 mt-0 mb-50'>
                      <Col sm='3 '>
                      </Col>
                    </Row></>
                )}
              </Row>
            </Card>
          </Fragment>
        </>
      ) : <h3> Sorry, you do not have access to this page.</h3>}
      <div className='demo-inline-spacing'><div className='vertically-centered-modal'>
        <Modal backdrop='static' isOpen={confirmationModal} toggle={cancelHandler} className='modal-dialog-centered'>
          <ModalHeader toggle={cancelHandler}>Confirmation</ModalHeader>
          <ModalBody>Do you want to deactivate this user?</ModalBody>
          <ModalFooter><Button color="primary" type='submit' onClick={onConfirmDelete}>{isLoading === true ? < Spinner size='sm' /> : 'YES'}</Button>
            <Button className="offset-2" color="secondary" onClick={cancelHandler}> NO</Button></ModalFooter>
        </Modal>
      </div></div>
    </>
  )
}

export default Permissions