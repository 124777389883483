import Select from 'react-select'
import { selectThemeColors } from '@utils'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import AddLogo from '../../assets/images/portrait/small/add.png'
import { useDispatch, useSelector } from 'react-redux/es/exports'
import { handleCostumiseSource, handleCostumiseSourceModal, handleCustomisationClose } from '../../redux/navbar'
import { Button, Col, Row, Spinner } from 'reactstrap'
import { XCircle, Check, PlusCircle} from 'react-feather'
import Avatar from '@components/avatar'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

const DropdownSource = () => {
  const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
   const activeTabFromRedux = useSelector((state) => state.navbar.activeTab)
  const parms = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [data, setData] = useState("")
  const [add, setAdd] = useState(1)
  const dispatch = useDispatch()
  const [error, setError] = useState()
  const [stage] = useState([])
  const projectId = parms.projectId
  const databaseUserId = localStorage.getItem('loggedInUserId')
  const [selected, setSelected] = useState([])
  const addHandler = () => {
    setAdd(0)
    setData("")
  }
  const cancelHandler = () => {
    setData('')
    setAdd(1)
    setError()
  }
  const colourOptions = [stage]

  //Tosty Message component
const Tosty = (msg, type) => {
  toast(
    <Row className='demo-vertical-spacing'>
      <div className='d-flex'><div className='me-1'>{type === 'Error' ? <Avatar size='sm' color='danger' icon={<Check size={12} />} /> : <Avatar size='sm' color='success' icon={<Check size={12} />} />}</div>
        <div className='d-flex flex-column'><h6>{msg}</h6></div>
      </div>
    </Row>)
}

  Array.prototype.removeValue = function (name, value) {
    const array = $.map(this, function (v) {
      return v[name] === value ? null : v
    })
    this.length = 0 //clear original array
    this.push.apply(this, array) //push all elements except the one we want to delete
  }

  // to handle add sources
  const handleChangeForAddSources = (val) => {
    if (val !== null) {
      if (val.label !== null || val.isEmpty({})) {
        if (val.value === '') {
          Tosty('Select source can not be selected. Select another value', 'Error')
        } else {
        const check = selected.indexOf(val.label)
     if (check === -1) {
    const selectedIndex = selected.indexOf(val.label)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, val.label)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
    dispatch(handleCostumiseSource(newSelected))
  } else {
    Tosty('Source already existed.', 'Error')
  } 
}
      }
    }
  }

  // to get all customisation sources of current project
  useEffect(() => {
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_BASENAME}/getcustomization/${projectId}`)
      .then((res) => {
        if (res.data.data?.sources) {
          setSelected(res.data.data?.sources)
          setIsLoading(false)
        }
      })
      stage.length = 0
      setIsLoading(true)
      stage.push({ value: "", label: "Select Source"})
    axios.get(`${process.env.REACT_APP_BASENAME}/getsources/${databaseUserId}`).then(res => {
      res.data.data?.map(ele => stage.push({ value: ele.source, label: ele.source }))
      res.data.data?.map(ele => colourOptions.push({ value: ele.source, label: ele.source }))
      setIsLoading(false)
    })
  }, [activeTabFromRedux === '1'])

  // to handle Delete Sources
  const handleChangeForDeleteSources = (id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    dispatch(handleCustomisationClose('false'))
    setSelected(newSelected)
    dispatch(handleCostumiseSource(newSelected))
  }


  // useEffect(() => {
  //   stage.length = 0
  //   axios.get(`${process.env.REACT_APP_BASENAME}/getsources/${databaseUserId}`).then(res => {
  //     res.data.data?.map(ele => stage.push({ value: ele.source, label: ele.source }))
  //     res.data.data?.map(ele => colourOptions.push({ value: ele.source, label: ele.source }))
  //   })
  // }, [parms.projectId])

  // save customisation sources
  const saveHandler = () => {
    if (data.length !== 0) {
      const check = selected.indexOf(data)
      if (check === -1) {
      handleChangeForDeleteSources(data)
      const userIdData = { userId: databaseUserId, source: data }
      axios.post(`${process.env.REACT_APP_BASENAME}/savesource`, userIdData).then(() => {
        dispatch(handleCostumiseSourceModal(data))  
        stage.push({ value: data, label: data })
      })
      setAdd(1)
    } else {
      Tosty('Custom Source already exists', 'Error')
    }
    } else {
      setError(0)
    }
  }
    dispatch(handleCostumiseSource(selected))

  return (<div>
    <Row className=' mt-1' ><h4 >Select Sources</h4>
    </Row>
    {isLoading === true ? (<> <center><Spinner color="primary" className='text-center' style={{ height: '2rem', width: '2rem' }} /></center>  </>)  : (<Row>
    <Col className='mt-1' md='4' xl='4' >
        <Select onChange={handleChangeForAddSources} theme={selectThemeColors} className='react-select' classNamePrefix='select' maxMenuHeight={200} placeholder='Select Source' name='clear' options={stage} isClearable  />
      </Col>
      <Col  md='1' xl='1'  className='text-center mt-1'>
      {(LoginUserRole === 'Editor') ? (<PlusCircle  onClick={addHandler} size={30} />) : ''} 
      </Col>
      <Col className='mt-1' md='6' xl='6' >
         {selected?.map(ele =>  <React.Fragment key={ele}> 
         <Button.Ripple className='btn btn-outline-primary round waves-effect mb-1 ' style={{ marginRight:'10px'}} size='sm' color='secondary' outline>
             <span className='round height-60-per'>{ele}</span><XCircle  size={15} className='align-middle me-25 ms-1' onClick={() => handleChangeForDeleteSources(ele)} />
         </Button.Ripple>       
         </React.Fragment> 
               )}
               {/* Code to add custome sources */}
               {(add === 0) ? <Row ><Col sm='6' className='mt-1 mb-1'>
            <input id='input' className='form-control' placeholder='Enter Custom Sources' onChange={(e) => {
              setError(1)
              setData(e.target.value.trim())
            }} />
            {error === 0 ? <span style={{ color: "red" }}>Enter Custom Sources</span> : " "}
        </Col>
        <Col sm='6' className='mt-1 mb-1'>
        <Button color='primary' className='ms-1' onClick={saveHandler}>Add</Button>
        <Button color='secondary' className='ms-1' onClick={cancelHandler}>Cancel</Button>
        </Col></Row> : "" }
          </Col>
    </Row>)}
    
  </div>
  )
}
export default DropdownSource
