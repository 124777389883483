// ** React Imports
import { Navigate } from "react-router-dom"
import { Suspense } from "react"

// ** Context Imports
import { AbilityContext } from "@src/utility/context/Can"

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  // const ability = useContext(AbilityContext)
  const user = JSON.parse(localStorage.getItem("userData"))

  //console.log(user, "restrictedRoute")
  if (route) {
    // let action = null
    let resource = null
    let restrictedRoute = false

    if (route.meta) {
      //console.log(route.meta, "route.meta")
      // action = route.meta.action
      resource = route.meta.resource
      restrictedRoute = route.meta.restricted
    }
    // console.log(restrictedRoute, "restrictedRoute")
    if (!user) {
      return <Navigate to="/login" />
    }
    // if (user) {
    // // if (user && restrictedRoute) {
    //   return <Navigate to="/home" />
    // }
    if (user && restrictedRoute && user.role === "client") {
      return <Navigate to="/access-control" />
    }
    if (user, resource) {
      // return <Navigate to="/misc/not-authorized" replace />
      return <Navigate to="/login" replace />
    }

  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
