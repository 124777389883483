import axios from "axios"
import { ModalHeader, ModalBody, ModalFooter, Form, Button, Spinner, Modal, Row, Col, Input } from 'reactstrap'
import toast from 'react-hot-toast'
import React, { useState } from 'react'
import Avatar from '@components/avatar'
import { Check } from "react-feather"
import { handleManagementStrategy } from '../../redux/navbar'
import { useDispatch, useSelector } from 'react-redux'
import { handleProjectManagementStrategyRefreshPage, handlerefresh } from "../../redux/managementStrategy"

const AddManagementStrategy = ({ For, scope }) => {

    const [spinButton, setSpinButton] = useState(false)
    const [managementStratagyData, setManagementStratagyData] = useState({
        titleInput: "",
        descriptionInput: "",
        selectInput: ""
    })
    const scopes = useSelector(state => state.navbar.eventId)
    const databaseUserId = localStorage.getItem('loggedInUserId')
    const projectIddata = useSelector(state => state.navbar.projectId)
    const [inputTitleError, setInputTitleError] = useState("1")
    const [descriptionInputError, setDescriptionInputError] = useState("1")
    const [selectInputError, setSelectInputError] = useState("1")
    const dispatch = useDispatch()
    const [addManagementStratagy, setAddManagementStratagy] = useState(false)

    //to Handle onCHange Data
    const onHandleChange = (e) => {
        setManagementStratagyData({ ...managementStratagyData, [e.target.name]: e.target.value.trim() })
        setSpinButton(false)
        setInputTitleError(managementStratagyData?.titleInput !== 0 ? "1" : "")
        setDescriptionInputError(managementStratagyData?.descriptionInput !== 0 ? "1" : "")
        setSelectInputError(managementStratagyData?.descriptionInput !== 0 ? "1" : "")
    }

    //cancel Handler to empty state and error state
    const cancelHandler = () => {
        setSpinButton(false)
        setManagementStratagyData({
            titleInput: "",
            descriptionInput: "",
            selectInput: ""
        })
        setSelectInputError("1")
        setInputTitleError("1")
        setDescriptionInputError("1")
        setAddManagementStratagy(!addManagementStratagy)
    }

    // to add management Stratagy
    const addMangementHandler = () => {
        setSpinButton(true)
        if (managementStratagyData.titleInput.trim().length !== 0 && managementStratagyData.descriptionInput.trim().length !== 0 && managementStratagyData.selectInput.trim().length !== 0) {
            const scopeIdFromScopes = scope?._id ? scope?._id : ''
            const postDataFrommadal = { projectScopeId:scopeIdFromScopes, projectId:projectIddata, title: managementStratagyData.titleInput, description: managementStratagyData.descriptionInput, type: managementStratagyData.selectInput, userId: databaseUserId, scopes: For === 'PIED' ? scopes : [] /*scopes: scope ? [scope?.scopeId] : []*/ }
            axios.post(`${process.env.REACT_APP_BASENAME}/saveprojectstrategy`, postDataFrommadal).then((res) => {
                setSpinButton(false)
                dispatch(handlerefresh())
                dispatch(handleManagementStrategy(res.data))
                dispatch(handleProjectManagementStrategyRefreshPage(res.data))
                setManagementStratagyData({
                    titleInput: "",
                    descriptionInput: "",
                    selectInput: ""
                })
                if (res.data.message) {
                    toast(
                        <Row className='demo-vertical-spacing'>
                            <div className='d-flex'><div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /> </div>
                                <div className='d-flex flex-column'><h6>{res.data.message}</h6></div>
                            </div>
                        </Row>)
                    cancelHandler()
                }
            })
          
            // dispatch(handleCostumiseStagesModal("projectScope"))
        } else {
            setSpinButton(false)
            setDescriptionInputError(managementStratagyData.descriptionInput.length !== 0 ? "1" : "")
            setInputTitleError(managementStratagyData.titleInput.length !== 0 ? "1" : "")
            setSelectInputError(managementStratagyData.selectInput.length !== 0 ? "1" : "")
        }
    }
    return (
        <>
          <Button color="primary" style={{marginRight:'15px'}} className="float-end" onClick={() => setAddManagementStratagy(!addManagementStratagy)} > Add Strategy</Button>
            {/* {(For !== 'PIED') ? (<a className='cursor-pointer' style={{ color: 'blue' }} onClick={() => setAddManagementStratagy(!addManagementStratagy)} >+<u>LINK MANAGEMENT STRATEGY</u> </a>) : <Button color="primary" style={{marginRight:'15px'}} className="float-end" onClick={() => setAddManagementStratagy(!addManagementStratagy)} > Add Strategy</Button>} */}
            <div >
                <Modal backdrop='static' isOpen={addManagementStratagy} toggle={cancelHandler} className='modal-dialog-centered modal-lg '>
                    <ModalHeader
                        toggle={cancelHandler}>
                    </ModalHeader>
                    <h4 className="text-center mb-2 mt-1"> Add New Management Strategy</h4>
                    <ModalBody>
                        <Form onSubmit={(e) => e.preventDefault()}>
                            <Row>
                                <Col>
                                    <Input name='titleInput' onChange={onHandleChange} placeholder='Title' />
                                    {inputTitleError.length === 0 ? <span style={{ color: "red" }}>Add Title</span> : " "}
                                    <Input className=" mt-2" style={{ width: "100%" }} type='textarea' onChange={onHandleChange} name='descriptionInput' placeholder='Description' />
                                    {descriptionInputError.length === 0 ? <span style={{ color: "red" }}>Add Description</span> : " "}
                                        <select className="form-select mt-2" name="selectInput" placeholder="Type (Avoid,Minimize,Offset)" onChange={onHandleChange} aria-label="Floating label select example">
                                            <option selected value='' >Type (Avoid/Minimise/Offset)</option>
                                            <option value={"Avoid"}>Avoid</option>
                                            <option value={"Minimise"} >Minimise</option>
                                            <option value={"Offset"}>Offset</option>
                                        </select>
                                        {selectInputError.length === 0 ? <span style={{ color: "red" }}>Select Type</span> : " "}
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer justify-content-center">
                         <Button type='submit' color='primary' onClick={addMangementHandler}>{spinButton === true ? <Spinner size='sm' className='ms-75' /> : 'SAVE'} </Button>
                        <Button onClick={cancelHandler}> CANCEL</Button>
                    </ModalFooter>
                </Modal>
            </div>
        </>
    )
}

export default AddManagementStrategy