import { Col, Row, Input, Label, Card, Spinner, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import DataTable from 'react-data-table-component'
import moment from 'moment'
import axios from 'axios'
import React, { Fragment, useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { ChevronDown, Check } from 'react-feather'
import toast from 'react-hot-toast'
import Avatar from '@components/avatar'
import AddActions from './AddActions'
import EditAction from './EditActions'
import { handleProjectActionUserStatus, handleProjectActiondeleteConfirmRefreshPage, handleProjectScopeIds } from '../../redux/managementStrategy'
import { useParams } from 'react-router-dom'
import ProjectDetails from '../ProjectDetails/ProjectDetails'

const ViewAction = () => {
  const dispatch = useDispatch()
  const parms = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState()
  const [selected, setSelected] = useState([])
  const projectID = parms.projectId
  const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
  const dataFromPotentialImpactq = useSelector(state => state.managementStrategy.potentialImpactFromPotential)
  const RefreshPage = useSelector(state => state.managementStrategy.projectActiondeleteConfirm.refreshPage)
  const activeTabFromRedux = useSelector((state) => state.navbar.activeTab)
  const databaseUserId = localStorage.getItem('loggedInUserId')
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [searchValue] = useState('')
  const [data, setData] = useState([])
  const [totalRecord, setTotalRecords] = useState()
  const [getData, setGetData] = useState({
    projectId: parms.projectId,
    page: currentPage,
    perPage: rowsPerPage,
    q: searchValue
  })

  console.log('dataFromPotentialImpactq', dataFromPotentialImpactq)
  // get pagwise action data
  useEffect(() => {
    setIsLoading(true)
    axios.post(`${process.env.REACT_APP_BASENAME}/getactionspagewise`, getData)
      .then((res) => {
        //console.log('Res', res.data.data)
        setIsLoading(false)
        setData(res.data.data?.allData)
        dispatch(handleProjectScopeIds(res.data.data?.allData))
        setTotalRecords(res.data.data?.total)
      })
    axios.post(`${process.env.REACT_APP_BASENAME}/getusersbyproject`, { projectId: parms.projectId, alluser: true })
      .then((res) => {
        dispatch(handleProjectActionUserStatus(res.data.data?.allData))
      })
  }, [RefreshPage, getData, activeTabFromRedux === '5'])

  // handle Checkbox selection
  const onHandleSelect = (id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
  }

  // cancel Handler for delete
  const cancelHandler = () => {
    setSelected([])
    setConfirmationModal(!confirmationModal)
  }

  //to delete Action
  const onConfirmDelete = () => {
    setIsLoading(true)
    axios.post(`${process.env.REACT_APP_BASENAME}/deleteprojectaction`, { userId: databaseUserId, selectIds: selected.toString() })
      .then((res) => {
        setIsLoading(false)
        dispatch(handleProjectActiondeleteConfirmRefreshPage(res.data))
        if (res.data.data) {
          toast(
            <Row className='demo-vertical-spacing'>
              <div className='d-flex'>
                <div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /></div>
                <div className='d-flex flex-column'><h6>{res.data.data.message}</h6></div></div>
            </Row>
          )
          cancelHandler()
        }
      })
  }

  //Pagination Functions
  const isSelected = (id) => selected.indexOf(id) !== -1
  const serverSideColumns = [
    {
      minWidth: '50px',
      cell: row => {
        return (
          <>
            {(LoginUserRole === 'Editor') ? <Input type='checkbox' checked={isSelected(row?._id)} className='cursor-pointer' onChange={() => onHandleSelect(row?._id)} /> : ''}
          </>
        )
      }
    },
    {
      sortable: true,
      name: 'Subject',
      minWidth: '18%',
      wrap: true,
      selector: row => row?.subject
    },
    {
      sortable: true,
      name: 'Start Date',
      minWidth: '15%',
      wrap: true,
      selector: row => (moment(new Date(row?.startDate)).format('ll'))
    },
    {
      sortable: true,
      name: 'End Date',
      minWidth: '15%',
      wrap: true,
      selector: row => (moment(new Date(row?.endDate)).format('ll'))
    },
    {
      sortable: true,
      name: 'User',
      minWidth: '15%',
      wrap: true,
      selector: row => row?.stakeHolder
    },
    {
      sortable: true,
      name: 'Completed?',
      wrap: true,
      minWidth: '15%',
      selector: row => {
        return (
          <>
            <span className={row?.completedStatus === 'Yes' ? 'badge bg-success rounded-pill' : (row?.completedStatus === 'No') ? 'badge bg-danger rounded-pill' : ''}>{row?.completedStatus}</span>
          </>
        )
      }
    },
    {
      name: 'Action',
      minWidth: '200px',
      cell: row => {
        return (
          <>{(LoginUserRole === 'Editor') ? (<><EditAction mapDataForEditDelete={row} /></>) : <EditAction mapDataForEditDelete={row} />}</>)
      }
    }
  ]
  const handlePagination = page => {

    setGetData({
      projectId: projectID,
      page: page.selected + 1,
      perPage: rowsPerPage
    })
    setCurrentPage(page.selected + 1)
  }

  // ** Function to handle per page
  const handlePerPage = e => {
    setGetData({
      projectId: projectID,
      page: currentPage,
      perPage: parseInt(e.target.value)
    })
    setRowsPerPage(parseInt(e.target.value))
  }

  // ** Custom Pagination
  const CustomPagination = () => {
    const count = Math.ceil(totalRecord / rowsPerPage)

    return (
      <div className="d-flex align-items-center justify-content-between mt-1">
        <div style={{marginLeft:'10px'}} className='d-flex align-items-center'>
          <Label for='sort-select'> Select No. of Records </Label>
          <Input className='dataTable-select' style={{ width: '70px', height: '33px', margin: '5px' }} type='select' id='sort-select' value={rowsPerPage} onChange={e => handlePerPage(e)}>
            <option value={3}>3</option>
            <option value={5}>5</option>
            <option value={7}>7</option>
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
            <option value={75}>75</option>
            <option value={100}>100</option>
          </Input>
        </div>
        <ReactPaginate previousLabel={''} nextLabel={''} breakLabel='...' pageCount={Math.ceil(count) || 1} marginPagesDisplayed={2} pageRangeDisplayed={2} activeClassName='active' forcePage={currentPage !== 0 ? currentPage - 1 : 0} onPageChange={page => handlePagination(page)} pageClassName='page-item' breakClassName='page-item' nextLinkClassName='page-link' pageLinkClassName='page-link' breakLinkClassName='page-link' previousLinkClassName='page-link' nextClassName='page-item next-item' previousClassName='page-item prev-item' containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-2'} />
      </div>
    )
  }

  // ** Table data to render
  const dataToRender = () => {
    const filters = {
      q: searchValue
    }

    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })

    if (data?.length > 0) {
      return data
    } else if (data?.length === 0 && isFiltered) {
      return []
    } else {
      return data?.slice(0, rowsPerPage)
    }
  }

  return (
    <>
      <ProjectDetails />
      <Fragment>
      <Row className=''>
            <Col className=' ms-1'>
              <Button color="secondary" disabled={selected.length === 0} className="mb-1  float-start" onClick={() => setConfirmationModal(!confirmationModal)}>Delete</Button>
            </Col>
            <Col >{(LoginUserRole === 'Viewer') || (selected.length > 0) ? "" : <AddActions />}</Col>
          </Row>
        <Card>
          <Row>
            <Col sm='10 mt-1'><h4 className="ms-1 ">Identify actions that will inform a Forward Works Plan for the execution of the Environmental Impact Assessment.</h4></Col>
          </Row>
          <hr />
          <Row className="mt-0">
            {(data?.length === 0) ? (<h6 className=' text-center'> Actions Will Appear Here </h6>) : (
              <> <Row>
                <Col sm='12'>
                  <DataTable noHeader pagination paginationServer wrap='true' className='react-dataTable ' columns={serverSideColumns} sortIcon={<ChevronDown size={15} />} paginationComponent={CustomPagination} data={dataToRender()} />
                </Col></Row>
                <Row className='mx-0 mt-0 mb-50'>
                  <Col sm='3 '>

                  </Col>
                </Row> </>)}
          </Row>
        </Card>
      </Fragment>
      <div className='demo-inline-spacing'><div className='vertically-centered-modal'>
        <Modal isOpen={confirmationModal} toggle={cancelHandler} className='modal-dialog-centered'>
          <ModalHeader toggle={cancelHandler}>Confirmation</ModalHeader>
          <ModalBody>Do you want to delete this record ?</ModalBody>
          <ModalFooter>
            <Button color="primary" type='submit' onClick={onConfirmDelete}>{isLoading === true ? <Spinner size='sm' /> : 'YES'}</Button>
            <Button className="offset-2" color="secondary" onClick={cancelHandler}> NO</Button></ModalFooter>
        </Modal>
      </div>
      </div>
    </>
  )
}

export default ViewAction

