// ** Auth Endpoints
export default {
  loginEndpoint: `${process.env.REACT_APP_BASENAME}/login`,
  registerEndpoint: `${process.env.REACT_APP_BASENAME}/register`,
  refreshEndpoint: `${process.env.REACT_APP_BASENAME}/refreshtoken`,
  logoutEndpoint: `${process.env.REACT_APP_BASENAME}/logout`,
 
  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: "Bearer",
 
  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: "accessToken",
  storageRefreshTokenKeyName: "refreshToken"
}