// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import axios from 'axios'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

 export const UserDetails = createAsyncThunk(
  'data/userdetails', 
  async (data) => {
    const response = await axios.get(`${process.env.REACT_APP_BASENAME}/userdetails/${data}`)
     return response
  }
)

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser(),
    userDetails:''
  },
  reducers: {
    handleLogin: (state, action) => {
      //console.log('action.payload', action.payload)
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
      localStorage.removeItem('firstName')
      localStorage.removeItem('lastName')
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(UserDetails.fulfilled, (state, action) => {
        state.userDetails = action.payload.data
        localStorage.setItem('firstName', action.payload.data.firstName)
        localStorage.setItem('lastName', action.payload.data.lastName)
      })
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
