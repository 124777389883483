import { useState, Fragment, useEffect } from "react"
import axios from "axios"
import { ModalHeader, ModalBody, ModalFooter, Form, Button, Modal, Card, CardBody, CardText, Row, Input, Col, Table, Spinner } from 'reactstrap'
import { Droplet, Feather, Layers, Truck, Check, Codepen } from 'react-feather'
import { useDispatch, useSelector } from 'react-redux'
import { handleEditedScopeForPIED, handlePrintReport, handleUpdate } from "../../redux/navbar"
import AddManagementStrategy from "../managementStrategy/AddManagementStrategy"
import AddActions from "../actions/AddActions"
import toast from 'react-hot-toast'
import Avatar from '@components/avatar'
import { Link } from "react-router-dom"
import LinkActionsModal from "./LinkActionsModal"
import LinkStrategyModal from "./LinkStrategyModal"
import PrintScopingReport from "./PrintScopingReport"
// import useClickOutside from "../ClickOutsideHook/Outsideclick"

const PotentialImpactEvevntDetails = ({ potentialImpactEvent, setPopoverOpen, setPotentialImpactEvent, For}) => {

    const editedScopeForPIED = useSelector(state => state.navbar.editedScopeForPIED)
    // const customisation_data = useSelector(state => state.navbar.customization_data)
    console.log(editedScopeForPIED)
    const [saveLoader, setSaveLoader] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    // const [isLoadingPrint, setIsLoadingPrint] = useState(false)
    const [errorPotentialImpactEvevntDetails, setErrorPotentialImpactEvevntDetails] = useState('')
    const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
    const [errorsourceDescription, setErrorsourceDescription] = useState('')
    const [errorpathwayDescription, setErrorpathwayDescription] = useState('')
    const [errorreceptorDescription, setErrorreceptorDescription] = useState('')
    const [errorlinkageDescription, setErrorlinkageDescription] = useState('')
    const [errorrelevantLegislation, setErrorrelevantLegislation] = useState('')
    const dispatch = useDispatch()
    const [newPotentialImpactDesciption, setPotentialImpactDesciption] = useState('')                                        
    const [newSourceDescription, setSourceDescription] = useState('')
    const [newPathwayDescription, setPathwayDescription] = useState('')
    const [newReceptorDescription, setReceptorDescription] = useState('')
    const [newLinkageDescription, setLinkageDescription] = useState('')
    const [newRelevantLegislation, setRelevantLegislation] = useState('')
    const NewScopeId = useSelector(state => state.navbar.scopeId)
   
    // const ref = useRef(null)
    // useClickOutside(ref, () => setPopoverOpen(true))
    //  to set edited scope Details into the useState of PIED
  
    useEffect(() => {
        if (editedScopeForPIED?._id !== undefined) {
            setPotentialImpactDesciption(`During ${editedScopeForPIED?.stage?.toLowerCase()} ${editedScopeForPIED?.source?.toLowerCase()} effects ${editedScopeForPIED?.pathway?.toLowerCase()} impacting ${editedScopeForPIED?.receptor?.toLowerCase()}.`)
            setSourceDescription(editedScopeForPIED.sourceDescription ? editedScopeForPIED.sourceDescription : newSourceDescription)
            setPathwayDescription(editedScopeForPIED.pathwayDescription ? editedScopeForPIED.pathwayDescription : newPathwayDescription)
            setReceptorDescription(editedScopeForPIED.receptorDescription ? editedScopeForPIED.receptorDescription : newReceptorDescription)
            setLinkageDescription(editedScopeForPIED.linkageDescription ? editedScopeForPIED.linkageDescription : newLinkageDescription)
            setRelevantLegislation(editedScopeForPIED.relevantLegislation ? editedScopeForPIED.relevantLegislation : newRelevantLegislation)
        }
        dispatch(handlePrintReport([editedScopeForPIED?._id]))
    }, [editedScopeForPIED])

    //function for tosty Message 
    const Tosty = (msg) => {
        toast(
            <Row className='demo-vertical-spacing'>
                <div className='d-flex'><div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /></div>
                    <div className='d-flex flex-column'><h6>{msg}</h6></div>
                </div>
            </Row>)
    }

    const cancelHandler = () => {
        setPathwayDescription('')
        setSourceDescription('')
        setPathwayDescription('')
        setReceptorDescription('')
        setLinkageDescription('')
        setRelevantLegislation('')
        setErrorPotentialImpactEvevntDetails(1)
        setErrorsourceDescription(1)
        setErrorpathwayDescription(1)
        setErrorreceptorDescription(1)
        setErrorlinkageDescription(1)
        setErrorrelevantLegislation(1)
       if (editedScopeForPIED?.linkage) {
        console.log(editedScopeForPIED)
        setPotentialImpactEvent(false)
        setPopoverOpen(false)
        dispatch(handleEditedScopeForPIED({}))
       }
    }

    const onHandleSubmit = () => {
        if (newPotentialImpactDesciption?.trim().length === 0 || newSourceDescription?.trim().length === 0 || newPathwayDescription?.trim().length === 0 || newReceptorDescription?.trim().length === 0 || newLinkageDescription?.trim().length === 0 || newRelevantLegislation?.trim().length === 0) {
            setErrorPotentialImpactEvevntDetails(newPotentialImpactDesciption?.trim().length === 0 ? "0" : '1')
            setErrorsourceDescription(newSourceDescription?.trim().length === 0 ? "0" : '1')
            setErrorpathwayDescription(newPathwayDescription?.trim().length === 0 ? "0" : '1')
            setErrorreceptorDescription(newReceptorDescription?.trim().length === 0 ? "0" : '1')
            setErrorlinkageDescription(newLinkageDescription?.trim().length === 0 ? "0" : '1')
            setErrorrelevantLegislation(newRelevantLegislation?.trim().length === 0 ? "0" : '1')
            
        } else {
            setSaveLoader(true)
            axios.post(`${process.env.REACT_APP_BASENAME}/savepotentialimpact`, { projectScopeId: editedScopeForPIED._id, potentialImpactDesciption: newPotentialImpactDesciption, sourceDescription: newSourceDescription, pathwayDescription: newPathwayDescription, receptorDescription: newReceptorDescription, linkageDescription: newLinkageDescription, relevantLegislation: newRelevantLegislation })
                .then((res) => {
                    if (res.data.data) {
                        setSaveLoader(false)
                        Tosty(res.data.data.message)
                        cancelHandler()
                        dispatch(handleUpdate(res.data))
                    }
                })
        }
    }
    // on Handle save and Print Functionality
    // const onHandlePrint = () => {
    //     setIsLoadingPrint(true)
    //     dispatch(handlePrintReport(editedScopeForPIED?._id))
    //     if (newPotentialImpactDesciption?.trim().length === 0 || newSourceDescription?.trim().length === 0 || newPathwayDescription?.trim().length === 0 || newReceptorDescription?.trim().length === 0 || newLinkageDescription?.trim().length === 0 || newRelevantLegislation?.trim().length === 0) {
    //         setErrorPotentialImpactEvevntDetails(newPotentialImpactDesciption?.trim().length === 0 ? "0" : '1')
    //         setErrorsourceDescription(newSourceDescription?.trim().length === 0 ? "0" : '1')
    //         setErrorpathwayDescription(newPathwayDescription?.trim().length === 0 ? "0" : '1')
    //         setErrorreceptorDescription(newReceptorDescription?.trim().length === 0 ? "0" : '1')
    //         setErrorlinkageDescription(newLinkageDescription?.trim().length === 0 ? "0" : '1')
    //         setErrorrelevantLegislation(newRelevantLegislation?.trim().length === 0 ? "0" : '1')
    //         setIsLoadingPrint(false)
    //     } else {
    //         axios.post(`${process.env.REACT_APP_BASENAME}/savepotentialimpact`, { projectScopeId: editedScopeForPIED._id, potentialImpactDesciption: newPotentialImpactDesciption, sourceDescription: newSourceDescription, pathwayDescription: newPathwayDescription, receptorDescription: newReceptorDescription, linkageDescription: newLinkageDescription, relevantLegislation: newRelevantLegislation })
    //             .then((res) => {
    //                 if (res.data.data) {
    //                     setIsLoadingPrint(false)
    //                     Tosty(res.data.data.message)
    //                     window.open(`/print-page/${editedScopeForPIED._id}`, '_blank')
    //                     // navigate(`/print-page/${editedScopeForPIED._id}`)
    //                     cancelHandler()
    //                     dispatch(handleUpdate(res.data))
    //                     //dispatch(handlePrintReport([]))
    //                 }
    //             })
    //     }
    // }

    const onHandleLinkage = (linkage) => {
        setIsLoading(true)
        let scopeIdForLinkage = 0
        if (For === 'Create') {
            scopeIdForLinkage = NewScopeId
        } else {
            scopeIdForLinkage = editedScopeForPIED?._id
        }
        //save and update Linkage
        axios.post(`${process.env.REACT_APP_BASENAME}/savescopelinkage`, { projectScopeId: scopeIdForLinkage, linkage: linkage.target.value })
            .then((res) => {
                setIsLoading(false)
                dispatch(handleEditedScopeForPIED(res.data.data.projectScopeDetails))
                dispatch(handleUpdate(res.data))
            })
    }
   
    return (
        <>
            {/* //Model Popup for Potential Impact Evevnt Details */}
            <div  className='modal-dialog modal-dialog-centered modal-lg'>
                <Modal backdrop="static" isOpen={potentialImpactEvent} toggle={cancelHandler} className='modal-dialog-centered modal-lg'>
                    <ModalHeader toggle={cancelHandler}></ModalHeader>
                    <ModalBody><h4 className="text-center">Potential Impact Event Details</h4><br />
                        <Form id='printableArea' onSubmit={(e) => e.preventDefault()}>
                            <Fragment><Row>
                                <card className="card-group">
                                    <Card style={{ marginRight: '2%' }} md='3' xl='3' >
                                        {/* as disscussed with client to add height */}
                                        <CardBody>
                                            <CardText><Layers size={40} /></CardText>
                                            <h4>Stage</h4>
                                            <CardText>{editedScopeForPIED?.stage}</CardText>
                                        </CardBody>
                                    </Card>
                                    <Card style={{ marginRight: '2%' }} md='3' xl='3' >
                                        <CardBody>
                                            <CardText><Codepen size={40} /></CardText>
                                            <h4>Source</h4>
                                            <CardText>{editedScopeForPIED?.source}</CardText>
                                        </CardBody>
                                    </Card>
                                    <Card style={{ marginRight: '2%' }} md='3' xl='3' >
                                        <CardBody>
                                            <CardText><Droplet size={40} /></CardText>
                                            <h4>Pathway</h4>
                                            <CardText>{editedScopeForPIED?.pathway}</CardText>
                                        </CardBody>
                                    </Card>
                                    <Card style={{ marginRight: '2%' }} md='3' xl='3'>
                                        <CardBody>
                                            <CardText><Feather size={40} /></CardText>
                                            <h4>Receptor</h4>
                                            <CardText>{editedScopeForPIED?.receptor} </CardText>
                                        </CardBody>
                                    </Card>
                                </card>
                                <Col md='12' xl='12'>
                                    <Row>
                                        <CardText className="mt-1 mb-1"> Potential Impact Description </CardText>
                                        <Col md='12' xl='12'>
                                            <Input type='textarea' defaultValue={`During ${editedScopeForPIED?.stage?.toLowerCase()} ${editedScopeForPIED?.source?.toLowerCase()} effects ${editedScopeForPIED?.pathway?.toLowerCase()} impacting ${editedScopeForPIED?.receptor?.toLowerCase()}.`} placeholder='Enter Potential Impact Description' disabled={(LoginUserRole === 'Viewer')} onChange={(e) => {
                                                setErrorPotentialImpactEvevntDetails(1)
                                                setPotentialImpactDesciption(e.target.value)
                                            }} name='potentialImpactDesciption' rows='3' />
                                            {errorPotentialImpactEvevntDetails === '0' ? <span style={{ color: "red" }}>Enter Potential Impact Description</span> : ''}

                                        </Col>
                                    </Row>
                                    <Row>
                                        <CardText className="mt-1 mb-1">Source Description</CardText>
                                        <Col md='12' xl='12'>
                                            <Input type='textarea' onChange={(e) => {
                                                setErrorsourceDescription(1)
                                                setSourceDescription(e.target.value)
                                            }} name='sourceDescription' defaultValue={editedScopeForPIED?.sourceDescription} placeholder='Enter Source Description' rows='3' disabled={(LoginUserRole === 'Viewer')} />
                                            {errorsourceDescription === '0' ? <span style={{ color: "red" }}>Enter Source Description</span> : ''}
                                        </Col>
                                        <Col md='2' xl='2'>
                                            {/* If LoginUserRole is Viewer then hide Add Action Button logic */}
                                            {/* {(LoginUserRole === 'Viewer') ? '' : <AddActions For={'PIED'} />} */}
                                        </Col>
                                    </Row>

                                    <Row>
                                        <CardText className="mt-1 mb-1">Pathway Description</CardText>
                                        <Col md='12' xl='12'>
                                            <Input type='textarea' onChange={(e) => {
                                                setErrorpathwayDescription(1)
                                                setPathwayDescription(e.target.value)
                                            }} name='pathwayDescription' defaultValue={editedScopeForPIED?.pathwayDescription} rows='3' placeholder="Enter Pathway Description" disabled={(LoginUserRole === 'Viewer')} />
                                            {errorpathwayDescription === '0' ? <span style={{ color: "red" }}>Enter Pathway Description</span> : ''}
                                        </Col>
                                        <Col md='2' xl='2'>
                                            {/* If LoginUserRole is Viewer then hide Add Action Button logic */}
                                            {/* {(LoginUserRole === 'Viewer') ? '' : <AddActions For={'PIED'} />} */}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <CardText className="mt-1 mb-1"> Receptor Description</CardText>
                                        <Col md='12' xl='12'>
                                            <Input type='textarea' onChange={(e) => {
                                                setErrorreceptorDescription(1)
                                                setReceptorDescription(e.target.value)
                                            }} name='receptorDescription' defaultValue={editedScopeForPIED?.receptorDescription} placeholder='Enter Receptor Description' rows='3' disabled={(LoginUserRole === 'Viewer')} />
                                            {errorreceptorDescription === '0' ? <span style={{ color: "red" }}>Enter Receptor Description</span> : ''}
                                        </Col>
                                        <Col md='2' xl='2'>
                                            {/* If LoginUserRole is Viewer then hide Add Action Button logic */}
                                            {/* {(LoginUserRole === 'Viewer') ? '' : <AddActions For={'PIED'} />} */}
                                        </Col>
                                    </Row>
                                    <CardText style={{ color: '#444444', fontSize: '15px' }} className="mt-1 mb-1"> Is there a linkage ?</CardText>
                                    <div className="row">
                                        <div >
                                            <div className="row">
                                                <div>
                                                    {(editedScopeForPIED?.linkage === 'Yes') ? <Button id='Yes' color='primary' style={{ width: '85px' }} value="Yes" onClick={(e) => onHandleLinkage(e)}>
                                                        {isLoading === true ? <Spinner size='sm' /> : 'Yes'}
                                                    </Button> : <Button id='Yes' outline color='primary' value="Yes" style={{ width: '85px' }} onClick={(e) => onHandleLinkage(e)}>Yes</Button>}&nbsp;&nbsp;
                                                    {(editedScopeForPIED?.linkage === 'No') ? <Button id='No' color='primary' style={{ width: '85px' }} value="No" onClick={(e) => onHandleLinkage(e)}>
                                                        {isLoading === true ? <Spinner size='sm' /> : 'No'}
                                                    </Button> : <Button id='No' outline color='primary' value="No" style={{ width: '85px' }} onClick={(e) => onHandleLinkage(e)}>No</Button>}&nbsp;&nbsp;
                                                    {(editedScopeForPIED?.linkage === 'Unconfirmed') ? <Button id='Unconfirmed' color='primary' style={{ width: '180px' }} value="Unconfirmed" onClick={(e) => onHandleLinkage(e)}>
                                                        {isLoading === true ? <Spinner size='sm' /> : 'Unconfirmed'}
                                                    </Button> : <Button id='Unconfirmed' outline color='primary' style={{ width: '180px' }} value="Unconfirmed" onClick={(e) => onHandleLinkage(e)}>Unconfirmed</Button>}
                                                </div>
                                            </div >
                                            {/* {(editedScopeForPIED?.linkage === 'Yes') ? <Button id='Yes' color='primary' style={{width:'85px'}} value="Yes">
                                                 Yes
                                            </Button> : <Button id='Yes' outline color='primary'  value="Yes" style={{width:'85px'}}>Yes</Button>}&nbsp;&nbsp;
                                            {(editedScopeForPIED?.linkage === 'No') ? <Button id='No' color='primary' style={{width:'85px'}} value="No" >
                                               No
                                            </Button> : <Button id='No' outline color='primary'  value="No" style={{width:'85px'}}>No</Button>}&nbsp;&nbsp;
                                            {(editedScopeForPIED?.linkage === 'Unconfirmed') ? <Button id='Unconfirmed' color='primary' style={{ width: '180px' }} value="Unconfirmed">
                                            Unconfirmed
                                            </Button> : <Button id='Unconfirmed' outline color='primary' style={{ width: '180px' }} value="Unconfirmed" >Unconfirmed</Button>} */}
                                        </div>
                                    </div >
                                    <CardText className="mt-1 mb-1"> Linkage Justification</CardText>

                                    <Input type='textarea' onChange={(e) => {
                                        setErrorlinkageDescription(1)
                                        setLinkageDescription(e.target.value)
                                    }} name='linkageDescription' defaultValue={editedScopeForPIED?.linkageDescription} rows='3' placeholder="Enter  Linkage Justification" disabled={(LoginUserRole === 'Viewer')} />
                                    {errorlinkageDescription === '0' ? <span style={{ color: "red" }}>Enter  Linkage Justification</span> : ''}

                                    <CardText className="mt-1 mb-1">Applicable Guidelines/Standards/Legislation</CardText>

                                    <Input type='textarea' onChange={(e) => {
                                        setErrorrelevantLegislation(1)
                                        setRelevantLegislation(e.target.value)
                                    }} name='relevantLegislation' defaultValue={editedScopeForPIED?.relevantLegislation} rows='3' disabled={(LoginUserRole === 'Viewer')} placeholder='Enter Applicable Guidelines/Standards/Legislation' />
                                    {errorrelevantLegislation === '0' ? <span style={{ color: "red" }}>Enter Applicable Guidelines/Standards/Legislation</span> : ''}

                                    {/* <CardText className="mt-1 mb-1">Are there any management strategies identified to avoid, minimise or offset potential impacts?</CardText> */}

                                    <CardText className="mt-1 mb-1">
                                        {(LoginUserRole === 'Viewer') ? '' : <LinkStrategyModal For='PIED' /> /*<AddManagementStrategy For={'PIED'} scope={editedScopeForPIED} />*/}
                                        <div style={{marginTop:'10px'}} >{(LoginUserRole === 'Viewer') ? '' : <LinkActionsModal setPopoverOpen={setPopoverOpen} For={'PIED'} />}</div>
                                    </CardText>

                                </Col> </Row>
                            </Fragment>
                        </Form>
                    </ModalBody>
                    <ModalFooter className=" modal-footer justify-content-center " >
                        {(LoginUserRole === 'Viewer') ? '' : (<><Button color="primary" type='submit' onClick={onHandleSubmit}> {saveLoader === true ? <Spinner size='sm' /> : 'SUBMIT'} </Button>
                          <PrintScopingReport For='PIED' /> </>)}
                    </ModalFooter>
                </Modal>
            </div>


        </>
    )
}

export default PotentialImpactEvevntDetails
