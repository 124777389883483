// ** Reactstrap Imports
import { ModalHeader, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle, ModalBody, ModalFooter, Button, Table, Row, Modal, Input, Label, Spinner } from 'reactstrap'
import { useState } from 'react'
import Select from 'react-select'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { handlePrintReport, handleReportType, handleUpdate } from '../../redux/navbar'
import toast from 'react-hot-toast'
import Avatar from '@components/avatar'
import { Check } from 'react-feather'
// ** Config
import ESGLogo from '../../assets/images/logo/LoginLogo.jpg'
import PdfReport from '../Reports/PdfReport'
import WordReport from '../Reports/WordReport'
import Demo from '../Reports/Demo'

const options = [
    {
        value: 'Project summary',
        label: 'Project summary'
    },
    {
        value: 'Scoping report',
        label: 'Scoping report'
    },
    {
        value: 'Impact assessment report',
        label: 'Impact assessment report'
    }
]

const PrintScopingReport = ({ setSelected, For, selected }) => {
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [report, setReport] = useState(false)
    const [reportvalue, setReportValue] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const selectedProjectId = useSelector(state => state.navbar.printReport)
    const dispatch = useDispatch()

    const onDeleteClick = () => {
        setConfirmationModal(!confirmationModal)
    }

    const handleChangeReport = (val) => {
        if (val) {
            setReportValue(val.value)
        } else {
            setReportValue('')
        }
    }
    console.log(reportvalue)

    const handleReports = (e) => {
        dispatch(handleReportType(e.target.innerHTML))
    }

    //Multiple Scope deletion Code
    const onConfirmDelete = () => {
        setIsLoading(true)
        axios.post(`${process.env.REACT_APP_BASENAME}/deletescope`, { projectScopeIds: selectedProjectId.toString() })
            .then((res) => {
                setIsLoading(false)
                if (res.data.data) {
                    dispatch(handlePrintReport([]))
                    toast(
                        <Row className='demo-vertical-spacing'>
                            <div className='d-flex'>
                                <div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /></div>
                                <div className='d-flex flex-column'><h6>{res.data.data.message}</h6></div>
                            </div>
                        </Row>)
                    setConfirmationModal(!confirmationModal)
                }
                setSelected([])
                dispatch(handleUpdate(res.data))
            })
    }

    // cancel Handler for for state empty 
    const cancleHandler = (from) => {
        if (from === 'print') {
            setSelected([])
            dispatch(handlePrintReport([]))
            setReport(!report)
        } else {
            setSelected([])
            dispatch(handlePrintReport([]))
            setConfirmationModal(!confirmationModal)
        }
    }

    return (
        <>
            <UncontrolledButtonDropdown>
                <DropdownToggle disabled={selected?.length === 0} color='secondary' caret>
                    Download Report
                </DropdownToggle>
                <DropdownMenu end>
                    {options.map((reports) => {
                        return <>
                            <UncontrolledButtonDropdown direction='end'>
                                <DropdownToggle color='' caret>
                                    <span onClick={handleReports} value={reports.label} >{reports.label}</span>
                                </DropdownToggle>
                                <DropdownMenu onChange={handleChangeReport}>
                                    <PdfReport />
                                    <WordReport />
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </>
                    })}
                </DropdownMenu>
            </UncontrolledButtonDropdown>

            {/* <form className='d-flex'>
                <Select onChange={handleChangeReport} color="secondary" className='w-25 mb-1' 
                options={options} 
                placeholder='Select Report' 
                isClearable/>
                <div className='d-flex align-items-center' style={{marginLeft:'15px', height:'38px'}} >
                <div className='form-check'>
                <Input type='radio' name='ex1' id='ex1-inactive' />
                <Label  className='form-check-label' for='ex1-inactive'>
                    PDF
                </Label>
            </div>
            <div className='form-check'  style={{marginLeft:'15px'}} >
                <Input type='radio' name='ex1' id='ex2-inactive' />
                <Label className='form-check-label' for='ex2-inactive'>
                    Word
                </Label>
            </div> 
                </div>
            </form> */}
            {/* <Button width="45px" disabled={selectedProjectId.length === 0} onClick={() => navigat('/print-page', {replace:true})}   color="secondary" >PRINT REPORT</Button> */}
            {/* <Button width="45px" disabled={selectedProjectId.length === 0} color="secondary" tag={Link}  to={`/print-page/${selectedProjectId}`}  target='blank'>PRINT REPORT</Button>   */}
            {For === 'PIED' ? '' : <Button width="45px" disabled={selected.length === 0} className='ms-2' color="secondary" onClick={onDeleteClick}>DELETE</Button>}

            {/* //Model Popup */}
            < div className='demo-inline-spacing' >
                <div className='vertically-centered-modal '>
                    <Modal backdrop='static' isOpen={confirmationModal} toggle={() => cancleHandler('delete')} className='modal-dialog-centered'>
                        <ModalHeader toggle={() => cancleHandler('delete')}>Confirmation Box</ModalHeader>
                        <ModalBody>Do you want to delete this record ?</ModalBody>
                        <ModalFooter>
                            <Button color="primary" type='submit' onClick={onConfirmDelete}> {isLoading === true ? <Spinner size='sm' /> : 'YES'}</Button>
                            <Button className="offset-2" color="secondary" onClick={() => cancleHandler('delete')}>NO</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </ div>
        </>
    )
}

export default PrintScopingReport