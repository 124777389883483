// ** React Imports
import { NavLink } from "react-router-dom"

// ** Third Party Components
import classnames from "classnames"

// ** Reactstrap Imports
import { Badge } from "reactstrap"

const VerticalNavMenuLink = ({ item, activeItem }) => {
  // ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const LinkTag = item.externalLink ? "a" : NavLink

  // ** Hooks

  return (
    <li
      className={classnames({
        "nav-item": !item.children,
        disabled: item.disabled,
        active: item.navLink === activeItem
      })}
    >
      <LinkTag
        className="d-flex align-items-center"
        // target={item.newTab ? "_blank" : undefined}
        /*eslint-disable */
        {...(item.externalLink === true
          ? {
            href: item.navLink || "/",
          }
          : {
            to: item.navLink || "/",
            className: ({ isActive }) => {
              if (isActive && !item.disabled) {
                return "d-flex align-items-center active";
              }
            },
          })}
        // onClick={(item) => item.onClick}
        onClick={(e) => {

          if (
            item.navLink.length === 0 ||
            item.navLink === "#" ||
            item.disabled === true
          ) {

            e.preventDefault();
          }
        }
        }
      >

        {
          (() => {
            if (item.title === 'Potential Impact Events') {
              return <div className="d-flex align-items-center">
                {item.icon}
                <span className="text-truncate">Potential Impact<br/>Events </span>
              </div>
            } else if (item.title == 'Management Strategy') {
              return <div className="d-flex align-items-center">
                {item.icon}
                <span className="text-truncate">Management<br/>Strategy </span>
              </div>
            } else if (item.title == 'Impact Significance Assessment') {
              return <div className="d-flex align-items-center">
                {item.icon}
                <span className="text-truncate">Impact Significance<br/>Assessment </span>
              </div>
            } else {
              return <div className="d-flex align-items-center">
                {item.icon}
                <span className="text-truncate">{item.title}</span>
              </div>
            }
          })()
        }
         {/* original navigations */}
         {/* {item.icon}
         <span className="text-truncate">{item.title}</span> */}

        {/* removed class menu-item */}
        {item.badge && item.badgeText ? (
          <Badge className="ms-auto me-1" color={item.badge} pill>
            {item.badgeText}
          </Badge>
        ) : null}
      </LinkTag>
    </li >
  );
};

export default VerticalNavMenuLink;
