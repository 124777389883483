import Select from 'react-select'
import { selectThemeColors } from '@utils'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import AddLogo from '../../assets/images/portrait/small/add.png'
import { useDispatch, useSelector } from 'react-redux/es/exports'
import { handleCostumiseStages, handleCostumiseStagesModal, handleCustomisationClose } from '../../redux/navbar'
import { Button, Col, Row, Spinner } from 'reactstrap'
import { XCircle, Check, PlusCircle, AlignCenter } from 'react-feather'
import Avatar from '@components/avatar'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

const DropdownStage = () => {
  const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
   const activeTabFromRedux = useSelector((state) => state.navbar.activeTab)
  const [isLoading, setIsLoading] = useState(false)
  const parms = useParams()
  const [data, setData] = useState("")
  const [add, setAdd] = useState(1)
  const dispatch = useDispatch()
  const [error, setError] = useState()
  const [stage] = useState([])
  const projectId = parms.projectId
  const databaseUserId = localStorage.getItem('loggedInUserId')
  const [selected, setSelected] = useState([])
  const addHandler = () => {
    setAdd(0)
    setData("")
  }
  const cancelHandler = () => {
    setData('')
    setAdd(1)
    setError()
  }
  const colourOptions = [stage]

   //Tosty Message component
const Tosty = (msg, type) => {
  toast(
    <Row className='demo-vertical-spacing'>
      <div className='d-flex'><div className='me-1'>{type === 'Error' ? <Avatar size='sm' color='danger' icon={<Check size={12} />} /> : <Avatar size='sm' color='success' icon={<Check size={12} />} />}</div>
        <div className='d-flex flex-column'><h6>{msg}</h6></div>
      </div>
    </Row>)
}

// get customisation by current projectId
  useEffect(() => {
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_BASENAME}/getcustomization/${projectId}`)
      .then((res) => {
        if (res.data.data?.stages) {
          setSelected(res.data.data?.stages)
          setIsLoading(false)
        }
      })
      stage.length = 0
      setIsLoading(true)
      stage.push({ value: "", label: "Select Stage"})
      axios.get(`${process.env.REACT_APP_BASENAME}/getstages/${databaseUserId}`).then(res => {
        res.data.data?.map(ele => stage.push({ value: ele.stage, label: ele.stage }))
        res.data.data?.map(ele => colourOptions.push({ value: ele.stage, label: ele.stage }))
        setIsLoading(false)
      })
  }, [activeTabFromRedux === '1'])


// delete stages to customisation
  const handleChangeForAddStages = (val) => {
    if (val !== null) {
      if (val.label !== null || val.isEmpty({})) {
        if (val.value === '') {
          Tosty('Select Stage can not be selected. Select another value', 'Error')
        } else {
          const check = selected.indexOf(val.label)
          if (check === -1) {
            const selectedIndex = selected.indexOf(val.label)
            let newSelected = []
            if (selectedIndex === -1) {
              newSelected = newSelected.concat(selected, val.label)
            } else if (selectedIndex === 0) {
              newSelected = newSelected.concat(selected.slice(1))
            } else if (selectedIndex === selected.length - 1) {
              newSelected = newSelected.concat(selected.slice(0, -1))
            } else if (selectedIndex > 0) {
              newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
            }
            dispatch(handleCustomisationClose('false'))
            setSelected(newSelected)
            dispatch(handleCostumiseStages(newSelected))
          } else {
            Tosty('Stage already existed.', 'Error')
          }
        }
       
      }
    }
  }

  // Add stages to customisation
  const handleChangeForDeleteStages = (id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
    dispatch(handleCostumiseStages(newSelected))
  }


  // get all stages 
  // useEffect(() => {
    // stage.length = 0
    // axios.get(`${process.env.REACT_APP_BASENAME}/getstages/${databaseUserId}`).then(res => {
    //   res.data.data?.map(ele => stage.push({ value: ele.stage, label: ele.stage }))
    //   res.data.data?.map(ele => colourOptions.push({ value: ele.stage, label: ele.stage }))
  //   })

  // }, [parms.projectId])

  // save customisation stages
  const saveHandler = () => {
    if (data !== null) {
      const check = selected.indexOf(data)
      if (data.length !== 0) {
        if (check === -1) {
          handleChangeForDeleteStages(data)
          const userIdData = { userId: databaseUserId, stage: data }
          axios.post(`${process.env.REACT_APP_BASENAME}/savestage`, userIdData).then(() => {
            stage.push({ value: data, label: data })
            colourOptions.push({ value: data, label: data })
            dispatch(handleCostumiseStagesModal(data))
          })
          dispatch(handleCostumiseStagesModal(data))
          setAdd(1)
        } else {
          Tosty('Custom Stage already exists', 'Error')
        }
      } else {
        setError(0)
      }
    }
  }

  dispatch(handleCostumiseStages(selected))

  return (
  <div>
    <Row  >
      <h4>Select Stages</h4></Row>
      {isLoading === true ? (<> <center><Spinner color="primary" className='text-center' style={{ height: '2rem', width: '2rem' }} /></center>  </>)  : (<Row>  <Col className='mt-1'  md='4' xl='4' > 
      <Select onChange={handleChangeForAddStages} styles={{display:'flex', flexDirection:'row'}} theme={selectThemeColors} className='react-select' classNamePrefix='select' maxMenuHeight={200}  name='clear' placeholder='Select Stage' options={stage} isClearable={true} />
       </Col>
      <Col  md='1' xl='1'  className='text-center mt-1'>
        {(LoginUserRole === 'Editor') ? (<><PlusCircle  onClick={addHandler}  size={30} /></>) : ''}
      </Col>
      <Col className='mt-1 ' md='6' xl='6' >
        {selected?.map(ele => <React.Fragment key={ele}>
          <Button.Ripple key={ele} className='btn btn-outline-primary round waves-effect mb-1' style={{ marginRight:'10px'}}  size='sm' color='secondary' outline>
            <span className='round height-60-per'>{ele}</span><XCircle size={15} className='align-middle me-25 ms-1' onClick={() => handleChangeForDeleteStages(ele)} />
          </Button.Ripple></React.Fragment>)}
                {/* code for adding custome stages */}
      {(add === 0) ? <Row ><Col sm='6' className='mt-1 mb-1'>
        <input id='input' className='form-control' placeholder='Enter Custom Stages' onChange={(e) => {
            setError(1)
            setData(e.target.value.trim())
          }} />
           {error === 0 ? <span style={{ color: "red" }}>Enter Custom Stages</span> : " "}
        </Col>
        <Col sm='6' className='mt-1 mb-1'>
        <Button color='primary' className='ms-1' onClick={saveHandler}>Add</Button>
        <Button color='secondary' className='ms-1' onClick={cancelHandler}>Cancel</Button>
        </Col>
      </Row> : "" }
      </Col>
    </Row>) }
    
  </div>
  )
}
export default DropdownStage