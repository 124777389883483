import { Col, Row, Input, Button, Label, Card, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import React, { Fragment, useState, useEffect } from 'react'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { ChevronDown, Check } from 'react-feather'
import toast from 'react-hot-toast'
import Avatar from '@components/avatar'
import { handleProjectPotentialImpactfromUpadateStrtegypage, handleProjectScopeIds, handlerefresh } from '../../redux/managementStrategy'
import { useParams } from 'react-router-dom'
import AddManagementStrategy from '../managementStrategy/AddManagementStrategy'
import EditManagementStrategy from '../managementStrategy/EditManagementStrategy'

const LinkStrategyModal = ({For}) => {
  const dispatch = useDispatch()
  const parms = useParams()
  const RefreshPage = useSelector(state => state.managementStrategy.refreshpage)
  const [selected, setSelected] = useState([])
  const [unlink, setUnlink] = useState([])
  const [selectedStrategy, setSelectedStrategy] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [linkStrategy, setlinkStrategy] = useState(false)
  const projectIddata = parms.projectId
  const updateOne = useSelector(state => state.navbar.onUpdate)
  const event_id = useSelector(state => state.navbar.eventId)
  const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
  const ConfrimDelete = useSelector(state => state.managementStrategy.deleteConfirm)
  const activeTabFromRedux = useSelector((state) => state.navbar.activeTab)
  const databaseUserId = localStorage.getItem('loggedInUserId')
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [spinButton, setSpinButton] = useState(false)
  const [searchValue] = useState('')
  const [data, setData] = useState([])
  const [totalRecord, setTotalRecords] = useState()
  const [getData, setGetData] = useState({
    projectId: parms.projectId,
    page: currentPage,
    perPage: rowsPerPage,
    q: searchValue
  })
 // const [refresh, setRfresh] = useState(false)

  useEffect(() => {
    // if (projectIddata) {
        setIsLoading(true)
        axios.post(`${process.env.REACT_APP_BASENAME}/getstrategiespagewise`, getData)
          .then((res) => {
            const selected_strategy = res.data.data.allData.map((ele) => {
              if (ele.scopes.length > 0) {
                if (ele?.scopes?.find((data) => data === event_id.join())) {
                  return ele._id        
                } 
              }
            })
            const data = selected_strategy.filter((ele) => ele !== undefined)
            const selectedData = res.data.data.allData.filter((event) => event.scopes.includes(event_id.join(' ')))
            const unselectedData = res.data.data.allData.filter((event) => !event.scopes.includes(event_id.join(' ')))
        
            setSelected(data)
            setSelectedStrategy(data)
            setIsLoading(false)
            setData([...selectedData, ...unselectedData])
            dispatch(handleProjectPotentialImpactfromUpadateStrtegypage(res.data.data.allData))
            dispatch(handleProjectScopeIds(res.data.data.allData))
            setTotalRecords(res.data.data.total)        
          })
    // }
  }, [getData, RefreshPage, updateOne, activeTabFromRedux === '4'])
  
  //console.log('selectedStrategy', selectedStrategy)
 
  const onHandleSelect = (e, id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    // let unSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    
    if (unlink.length <= selectedStrategy.length) {
      if (e.target.checked) {
        const find_id = selectedStrategy.find((_id) => _id === id)
        if (find_id) {
         const data =  unlink.filter((_id) => _id !== id) 
         setUnlink(data)
        } 
      } else {
        const find_id = selectedStrategy.find((_id) => _id === id)
        if (find_id) {
          const data = unlink.find((ele) => ele === id)
           if (!data) {
            setUnlink([...unlink, id]) 
           }
        }
      }
    }
    
    setSelected(newSelected) 
    //setUnlink(unSelected)
  }

  // cancel Handler for delete
  const cancelHandler = () => {
    dispatch(handlerefresh())
    setSpinButton(false)
    setUnlink([])
    setlinkStrategy(!linkStrategy)
  }

  //to delete Management Stratagy
  const onConfirmDelete = () => {
    setIsLoading(true)
    axios.post(`${process.env.REACT_APP_BASENAME}/deleteprojectstrategies`, { userId: databaseUserId, selectIds: selected.toString() })
      .then((res) => {
        //dispatch(handleProjectActiondeleteConfirmRefreshPage(res.data))
        if (res.data.data) {
          setIsLoading(false)
          toast(
            <Row className='demo-vertical-spacing'>
              <div className='d-flex'><div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /></div>
                <div className='d-flex flex-column'><h6>{res.data.data.message}</h6></div></div>
            </Row>
          )
          cancelHandler()
        }
      })
  }

  const linkMStrategy = () => {
    setSpinButton(true)
    if ((selected.length > 0 || unlink.length > 0) && event_id.length > 0) {
        const e_id =  event_id.join(' ')
        axios.post(`${process.env.REACT_APP_BASENAME}/linkstrategy`, {link : selected, unlink, pie: e_id }).then((res) => {
          dispatch(handlerefresh())
          if (res.data.data.message) {
            dispatch(handlerefresh())
            toast(
                <Row className='demo-vertical-spacing'>
                    <div className='d-flex'><div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /> </div>
                        <div className='d-flex flex-column'><h6>{res.data.data.message}</h6></div>
                    </div>
                </Row>)
            cancelHandler()
        }
        })
      }
  }

  //Pagination Functions
  const isSelected = (id) => selected.indexOf(id) !== -1
  const serverSideColumns = [
    {
      minWidth: '50px',
      cell: row => {
        return (
          <>{(LoginUserRole === 'Editor') ? <Input type='checkbox' checked={isSelected(row?._id) } className='cursor-pointer' onChange={(e) => onHandleSelect(e, row?._id)} /> : ''} </>
        )
      }
    },
    {
      sortable: true,
      name: 'Title',
      minWidth: '25%',
      wrap:true,
      selector: row => row?.title
    },
    {
      sortable: true,
      minWidth: '25%',
      name: 'Relevant Potential Impact Event',
      wrap:true,
      selector: row => { return row.scopes?.length !== 0 && row.scopes !== null ? row.scopes.toString() : "-" }
    },
    {
      sortable: true,
      name: 'Type',
      minWidth: '25%',
      wrap:true,
      selector: row => row?.type
    }
    // {
    //   name: 'Action',
    //   minWidth: '20%',
    //   cell: row => {
    //     return (
    //       <>
    //         {(LoginUserRole === 'Editor') ? (<><Col className='position-absolute bottom-0  '><EditManagementStrategy mapDataForEditDelete={row} /> </Col></>) : <EditManagementStrategy mapDataForEditDelete={row} />}
    //       </>)
    //   }
    // }
  ]

  // to handle pagination
  const handlePagination = page => {
    setGetData({
      projectId: projectIddata,
      page: page.selected + 1,
      perPage: rowsPerPage
    })
    setCurrentPage(page.selected + 1)
  }

  // ** Function to handle per page
  const handlePerPage = e => {
    setGetData({
      projectId: projectIddata,
      page: currentPage,
      perPage: parseInt(e.target.value),
      q: searchValue
    })
    setRowsPerPage(parseInt(e.target.value))
  }

  // ** Custom Pagination
  const CustomPagination = () => {
    const count = Math.ceil(totalRecord / rowsPerPage)

    return (
      <div className="d-flex align-items-center justify-content-between mt-1">
         <div style={{marginLeft:'10px'}} className='d-flex align-items-center'>
                        <Label for='sort-select'> Select No. of Records </Label>
                        <Input className='dataTable-select' style={{width:'100px', height:'33px', margin:'5px'}} type='select' id='sort-select' value={rowsPerPage} onChange={e => handlePerPage(e)} >
                          <option value={3}>3</option>
                          <option value={5}>5</option>
                          <option value={7}>7</option>
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={75}>75</option>
                          <option value={100}>100</option>
                        </Input>
                      </div>
      <ReactPaginate previousLabel={''} nextLabel={''} breakLabel='...' pageCount={Math.ceil(count) || 1} marginPagesDisplayed={2} pageRangeDisplayed={2} activeClassName='active' forcePage={currentPage !== 0 ? currentPage - 1 : 0} onPageChange={page => handlePagination(page)} pageClassName='page-item' breakClassName='page-item' nextLinkClassName='page-link' pageLinkClassName='page-link' breakLinkClassName='page-link' previousLinkClassName='page-link' nextClassName='page-item next-item' previousClassName='page-item prev-item' containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-0'} />
      </div>
    )
  }

  // ** Table data to render
  const dataToRender = () => {
    const filters = {
      q: searchValue
    }
    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })

    if (data?.length > 0) {
      return data
    } else if (data?.length === 0 && isFiltered) {
      return []
    } else {
      return data?.slice(0, rowsPerPage)
    }
  }

  return (
    <>
    {(For === 'PIED') ? (<a className='cursor-pointer' style={{ color: 'blue' }} onClick={() => setlinkStrategy(!linkStrategy)} >+<u>LINK MANAGEMENT STRATEGY</u> </a>) : <button className="btn btn-outline-secondary w-100" onClick={() => setlinkStrategy(!linkStrategy)} >Management Strategy</button>}
      <div >
      <Modal backdrop='static' isOpen={linkStrategy} toggle={cancelHandler} className='modal-dialog-centered modal-lg '>
          <ModalHeader
            toggle={cancelHandler}>
        </ModalHeader>
     
        <ModalBody>
        <Fragment>
        <Card>
          <Row className='mt-1'>
            {/* <Col sm='10 '><h4 className="ms-1 float-start">All Management Strategies</h4></Col></Row>
           <Row> <Col className='ms-1'>
           <Button color="secondary"  disabled={selected.length === 0} className="mb-1 float-start" onClick={() => setlinkStrategy(!linkStrategy)}>Delete</Button>
              </Col> */}
            <Col >{(LoginUserRole === 'Viewer') || <AddManagementStrategy For='PIED' />}</Col>
            {/* {selected.length === 0 || ConfrimDelete.yes === "yes" || ConfrimDelete.No === "No" ? <>
              <Col className=' ms-1'>
                {(LoginUserRole === 'Viewer') ? "" : <AddManagementStrategy />}
              </Col>
            </> : <Col sm='6 offset-2'><Button color="secondary" className="mb-1 float-start" onClick={() => setlinkStrategy(!linkStrategy)}>Delete</Button></Col>} */}
          </Row>
          <hr />
          <Row className="mt-0 ms-1">
            { (data?.length === 0) ? (<h6 className=' text-center'> Management Strategies Will Appear Here</h6>) : (
                <><Row>
                  <Col sm='12 '>
                    <DataTable noHeader pagination paginationServer className='react-dataTable ' columns={serverSideColumns} sortIcon={<ChevronDown size={15} />} paginationComponent={CustomPagination} data={dataToRender()}/>
                  </Col>
                </Row>
                  <Row className='mx-0 mt-0 mb-50'>
                    <Col sm='3 '>
                     
                    </Col>
                  </Row></>
              )}
          </Row>
        </Card>
      </Fragment>
      <div className='demo-inline-spacing'><div className='vertically-centered-modal'>
        <Modal backdrop="static" toggle={cancelHandler} className='modal-dialog-centered'>
          <ModalHeader toggle={cancelHandler}>Confirmation</ModalHeader>
          <ModalBody>Do you want to delete this record ?</ModalBody>
          <ModalFooter>
            <Button color="primary" type='submit' onClick={onConfirmDelete}>{isLoading === true ? <Spinner size='sm'  /> : 'YES'}</Button>
            <Button className="offset-2" color="secondary" onClick={cancelHandler}> NO</Button></ModalFooter>
        </Modal>
      </div>
      </div>
          </ModalBody>
          <ModalFooter className="modal-footer justify-content-center">
              <Button type='submit' onClick={linkMStrategy} color='primary'>{spinButton === true ? <Spinner size='sm' className='ms-75' /> : 'SAVE'} </Button>
              <Button onClick={cancelHandler}> CANCEL</Button>
          </ModalFooter>
      </Modal>
    </div>
    </>
  )
}

export default LinkStrategyModal
