import React, {useEffect, useState, useRef} from 'react'
import toast from 'react-hot-toast'
import axios from 'axios'
import { useSelector } from 'react-redux'
import Avatar from '@components/avatar'
import { ModalHeader, ModalBody, ModalFooter, Form, Button, Modal, Row, Col, Input, Spinner, Label } from 'reactstrap'
import { Check, FileText, Plus, PlusCircle, XCircle } from 'react-feather'
import useClickOutside from '../ClickOutsideHook/Outsideclick'

const OutcomeModal = ({setPopoverOpen}) => {
  const [outcomeModal, setOutcomeModal] = useState(false)
  const selectedEvent = useSelector(state => state.navbar.customization_data)
  const [isLoading, setIsLoading] = useState(false)
  const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
  const [env_outcome_Err, setenv_outcome_Err] = useState("1")
  const [measure_outcome_Err, setmeasure_outcome_Err] = useState("1")
  const [lead_indicator_Err, setlead_indicator_Err] = useState("1")
  const [statement_criteria_Err, setstatement_criteria_Err] = useState("1")
  const [measure_outcome, setMeasureOutcome] = useState('')
  const [statement_criteria, setStatementCriteria] = useState('')
  const [outcomeData, setOutcomeData] = useState({
      env_outcome:'',
      measure_outcome:[],
      lead_indicator:'',
      statement_criteria:[]
  })
  const ref = useRef(null)
  useClickOutside(ref, () => setPopoverOpen(true))

  const cancelHandler = () => {
    setOutcomeData({
      env_outcome:'',
      measure_outcome:[],
      lead_indicator:'',
      statement_criteria:[]
    })
    setenv_outcome_Err('1')
    setmeasure_outcome_Err('1')
    setlead_indicator_Err('1')
    setstatement_criteria_Err('1')
    setOutcomeModal(false)
    setPopoverOpen(false)
  }
 
 useEffect(() => {
  axios.get(`${process.env.REACT_APP_BASENAME}/getoutcomemonitoringdata/${selectedEvent._id}`)
  .then((res) => {
    if (res.data.data) {
      setOutcomeData({
        env_outcome:res.data.data.env_outcome,
        measure_outcome:res.data.data.measure_outcome ? res.data.data.measure_outcome : [],
        lead_indicator:res.data.data.lead_indicator,
        statement_criteria:res.data.data.statement_criteria
      })
    }
  })
 }, [])

const onChangeOutcome = (e) => {
    setOutcomeData({ ...outcomeData, [e.target.name]: e.target.value.trim()})
}

const onChangeMeasureOutcome = (e) => {
  setMeasureOutcome(e.target.value)
}

const onChangeStatementCriteria = (e) => {
  setStatementCriteria(e.target.value)
}

useEffect(() => {

}, [])

const addOutcome = () => {
  if (measure_outcome) {
    setOutcomeData({ ...outcomeData, measure_outcome: [...outcomeData.measure_outcome, measure_outcome]})
    setMeasureOutcome('')
  } 
}

const addStatementCriteria = () => {
  if (statement_criteria) {
    setOutcomeData({ ...outcomeData, statement_criteria: [...outcomeData.statement_criteria, statement_criteria]})
    setStatementCriteria('')
  }
}
console.log(outcomeData)
const saveOutcomeData = () => {
  if (statement_criteria && measure_outcome) {
    outcomeData.measure_outcome = outcomeData.measure_outcome.length === 0 ? [measure_outcome] : outcomeData.measure_outcome
    outcomeData.statement_criteria = outcomeData.statement_criteria.length === 0 ? [statement_criteria] : outcomeData.statement_criteria
  }
  if (outcomeData.env_outcome.trim().length !== 0 && outcomeData.measure_outcome.length !== 0 && outcomeData.lead_indicator.length !== 0 && outcomeData.statement_criteria.length !== 0) {
   setIsLoading(true)
    outcomeData.impact_event_id = selectedEvent._id
      axios.post(`${process.env.REACT_APP_BASENAME}/saveoutcomemonitoring`, outcomeData).then((res) => {
          if (res.data.message) {
              toast(
                  <Row className='demo-vertical-spacing'>
                  <div className='d-flex'><div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /> </div>
                      <div className='d-flex flex-column'><h6>{res.data.message}</h6></div>
                  </div>
              </Row>
              )
              setIsLoading(false)
              cancelHandler()
          }
        })
  } else {
    setenv_outcome_Err(outcomeData.env_outcome.length !== 0 ? "1" : "")
    setmeasure_outcome_Err(outcomeData.measure_outcome.length !== 0 ? "1" : "")
    setlead_indicator_Err(outcomeData.lead_indicator.length !== 0 ? "1" : "")
    setstatement_criteria_Err(outcomeData.statement_criteria.length !== 0 ? "1" : "")
    setIsLoading(false)
  }
}

const handleDeleteformeasureoutcome = (id) => {
   outcomeData.measure_outcome.splice(id, 1)
   setOutcomeData({ ...outcomeData, measure_outcome: [...outcomeData.measure_outcome]})
}

const handleDeleteforstatement = (id) => {
  outcomeData.statement_criteria.splice(id, 1)
  setOutcomeData({ ...outcomeData, statement_criteria: [...outcomeData.statement_criteria]})
}

  return (
    <>
    {(LoginUserRole === 'Editor') ? <div onClick={() => setOutcomeModal(true)} style={{ width: '190px', paddingLeft: '5px', paddingRight: '5px', marginBottom: '0px' }} className='d-flex hovereffect cursor-pointer align-items-center justify-content-between'>
      <p><FileText size={20}/></p>
       <p style={{ fontSize: '15px' }} >Outcomes and <br/> Monitoring Criteria</p>
      </div> : ''
    // <span onClick={() => setOutcomeModal(true)} className='w-100 mt-1 cursor-pointer'> <FileText size={20} /> 
    // </span> 
     }
   
    <Modal  backdrop='static' isOpen={outcomeModal} toggle={cancelHandler} className='modal-dialog-centered modal-lg '>
    <div ref={ref}></div>
    <ModalHeader toggle={cancelHandler}></ModalHeader>
    <h4 className="text-center mb-2 mt-1">Outcomes and Monitoring Criteria</h4>
    <ModalBody>
        <Form onSubmit={(e) => e.preventDefault()}>
            <Row>
                <Col>
                    <div >
                      <Label>Statement of the environmental outcome that are expected to occur </Label> 
                      <Input defaultValue={outcomeData.env_outcome} className="" disabled={(LoginUserRole === 'Viewer')} style={{ width: "100%" }} type='textarea' onChange={onChangeOutcome} name='env_outcome' placeholder='Enter value' />
                      {env_outcome_Err.length === 0 ? <span style={{ color: "red" }}>Please enter expected outcome</span> : " "}
                    </div>
                    <div className='mt-1' >
                    <Label>Statement of the criteria to be adopted to measure those environmental outcomes </Label> 
                    <div className='d-flex align-items-center'>
                    <Input value={measure_outcome} disabled={(LoginUserRole === 'Viewer')} style={{ width: "100%", marginRight:'10px' }} type='input' onChange={onChangeMeasureOutcome} placeholder='Enter value' />
                    <PlusCircle onClick={addOutcome} style={{cursor:'pointer'}} size={30} />
                    </div>
                    {outcomeData?.measure_outcome?.map((outcome, i) => {
                      return <li>{outcome} <span className='cursor-pointer' onClick={() => handleDeleteformeasureoutcome(i)}><XCircle color='red' size={15}/></span> </li>
                    })}
                    {measure_outcome_Err.length === 0 ? <span style={{ color: "red" }}>Please enter measure outcome</span> : " "}
                    </div>
                    <div className='mt-1'>
                    <Label>Is there a high reliance on management strategies to achieve an environmental outcome?</Label> 
                        <select className="form-select" name="lead_indicator" onChange={onChangeOutcome} disabled={(LoginUserRole === 'Viewer')} aria-label="Floating label select example">
                            {outcomeData.lead_indicator ? '' : <option selected label='Select type'></option>}
                             {
                              ['Yes', 'No'].map((lead_indi) => {
                                return <>
                                       <option selected={outcomeData.lead_indicator === lead_indi} value={lead_indi}>{lead_indi}</option>
                                      </>
                              })
                             }
                        </select>
                        {lead_indicator_Err.length === 0 ? <span style={{ color: "red" }}>Please select lead indicator</span> : " "}
                    </div>
                    <div className='mt-1' >
                    <Label> (If Yes) Statement of leading indicator criteria that will be used to give an early warning that a control measure may fall or be falling </Label> 
                    <div className='d-flex align-items-center'>
                    <Input value={statement_criteria} disabled={(LoginUserRole === 'Viewer')} style={{ width: "100%", marginRight:'10px' }} type='input' onChange={onChangeStatementCriteria} placeholder='Enter value' />
                    <PlusCircle onClick={addStatementCriteria} style={{cursor:'pointer'}} size={30} />
                    </div>  
                    {outcomeData.statement_criteria.map((state_criteria, i) => {
                      return <li>{state_criteria} <span className='cursor-pointer' onClick={() => handleDeleteforstatement(i)}><XCircle color='red' size={15}/></span></li>
                    })}
                    {statement_criteria_Err.length === 0 ? <span style={{ color: "red" }}>Please enter measure outcome</span> : " "}
                    </div>
                </Col>
            </Row>
        </Form>
    </ModalBody>
    <ModalFooter className="modal-footer justify-content-center">
        {(LoginUserRole === "Editor") ? (<><Button color="primary" onClick={saveOutcomeData}>
            {isLoading === true ? <Spinner size='sm' /> : 'SAVE'}  
        </Button>
            <Button onClick={cancelHandler}>CANCEL</Button></>) : ''}
    </ModalFooter>
</Modal>
</>
  )
}

export default OutcomeModal
