import axios from "axios"
import { Check, Trash, Eye, Edit2, Edit, Trash2, Folder } from 'react-feather'
import { ModalHeader, ModalBody, ModalFooter, Form, Button, Modal, Row, Col, Input, Spinner } from 'reactstrap'
import toast from 'react-hot-toast'
import React, { useState, useEffect, useRef } from 'react'
import { handleManagementStrategy } from '../../redux/navbar'
import { useDispatch, useSelector } from 'react-redux'
import PotentialImpactEvent from "./PotentialImpactEvent"
import { handleProjectManagementStrategyRefreshPage } from "../../redux/managementStrategy"
import Avatar from '@components/avatar'
import useClickOutside from "../ClickOutsideHook/Outsideclick"

const level = ['Very High', 'High', 'Moderate', 'Low']
const assumption = ['Very High', 'High', 'Moderate', 'Low']

const EditManagementStrategy = ({ setPopoverOpen }) => {
    const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
    const propsFromViewProject = useSelector(state => state.managementStrategy.selectedStrategy)
    console.log('propsFromViewProject', propsFromViewProject)
    const [managementStratagyData, setManagementStratagyData] = useState({
        titleInput: propsFromViewProject.title,
        descriptionInput: propsFromViewProject.description,
        selectInput: propsFromViewProject.type
    })
    const [levelErr, setLevelErr] = useState('')
    const [levelDescErr, setLevelDecErr] = useState('')
    const [assumpErr, setAssumpErr] = useState('')
    const [assumpDescErr, setAssumpDecErr] = useState('')
    const [inputTitleError, setInputTitleError] = useState("1")
    const [descriptionInputError, setDescriptionInputError] = useState("1")
    const [selectInputError, setSelectInputError] = useState("1")
    const [isLoading, setIsLoading] = useState(false)
    const databaseUserId = localStorage.getItem('loggedInUserId')
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [certaintyModal, setCertaintyModal] = useState(false)
    const projectIddata = useSelector(state => state.navbar.projectId)
    const dataFromPotentialImpactq = useSelector(state => state.managementStrategy.potentialImpactFromPotential)
    const dispatch = useDispatch()
    const [certaintyData, setCertaintyData] = useState({
        level: '',
        levelDescription: '',
        assumptions: '',
        assumptionDesc: ''
    })
    const [editManagementStratagy, setEditManagementStratagy] = useState(false)
    const ref = useRef(null)
    useClickOutside(ref, () => setPopoverOpen(true))

    //Tosty Message component
    const Tosty = (msg, type) => {
        toast(
            <Row className='demo-vertical-spacing'>
                <div className='d-flex'><div className='me-1'>{type === 'Error' ? <Avatar size='sm' color='danger' icon={<Check size={12} />} /> : <Avatar size='sm' color='success' icon={<Check size={12} />} />}</div>
                    <div className='d-flex flex-column'><h6>{msg}</h6></div>
                </div>
            </Row>)
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASENAME}/getcertaintybystrategy/${propsFromViewProject._id}`)
            .then((res) => {
                console.log(res)
                if (res.data.data) {
                    setCertaintyData({
                        level: res.data?.data?.level,
                        levelDescription: res.data?.data.levelDescription,
                        assumptions: res.data?.data.assumptions,
                        assumptionDesc: res.data?.data.assumptionDesc
                    })
                }
            })
    }, [])

    //cancelHandler for state and error state empty
    const cancelHandler = () => {
        setManagementStratagyData({
            titleInput: "",
            descriptionInput: "",
            selectInput: ""
        })
        setCertaintyData({
            level: '',
            levelDescription: '',
            assumptions: '',
            assumptionDesc: ''
        })
        setSelectInputError("1")
        setInputTitleError("1")
        setDescriptionInputError("1")
        setCertaintyModal(false)
        setEditManagementStratagy(false)
        setLevelErr('')
        setLevelDecErr('')
        setAssumpErr('')
        setAssumpDecErr('')
        setPopoverOpen(false)
    }

    // on handlechange of Edit Management Data
    const onHandleChange = (e) => {
        setManagementStratagyData({ ...managementStratagyData, [e.target.name]: e.target.value.trim() })
    }

    const onHandleCertainty = (e) => {
        setCertaintyData({ ...certaintyData, [e.target.name]: e.target.value.trim() })
    }

    const handleCertaintyData = () => {
        setLevelErr('')
        setLevelDecErr('')
        setAssumpErr('')
        setAssumpDecErr('')
        if (certaintyData.level.trim().length !== 0 && certaintyData.levelDescription.trim().length !== 0 && certaintyData.assumptions.trim().length !== 0 && certaintyData.assumptionDesc.trim().length !== 0) {
            setIsLoading(true)
            const postDataFrommadal = { linkageId: propsFromViewProject._id, projectId: projectIddata, level: certaintyData.level, levelDescription: certaintyData.levelDescription, assumptions: certaintyData.assumptions, assumptionDesc: certaintyData.assumptionDesc, userId: databaseUserId, scopes: propsFromViewProject.scopes, formanagement_strategy: true }
            axios.post(`${process.env.REACT_APP_BASENAME}/addcertainty`, postDataFrommadal).then((res) => {
                console.log(res)
                dispatch(handleProjectManagementStrategyRefreshPage(res.data))
                if (res.data.message) {
                    Tosty(res.data.message)
                    setIsLoading(false)
                    cancelHandler()
                }
            })

        } else {
            if (certaintyData.level.trim().length === 0) {
                setLevelErr("Add level")
            } else if (certaintyData.levelDescription.trim().length === 0) {
                setLevelDecErr("Add description")
            } else if (certaintyData.assumptions.trim().length === 0) {
                setAssumpErr("Add assumption")
            } else if (certaintyData.assumptionDesc.trim().length === 0) {
                setAssumpDecErr("Add description")
            }
        }
    }

    // to edit Management Stratagy Data
    const editMangementHandler = () => {
        setIsLoading(true)
        const ArrayPotentialScopeId = dataFromPotentialImpactq.potentialImpactFromPotentialdata
        if (managementStratagyData.titleInput.trim().length !== 0 && managementStratagyData.descriptionInput.trim().length !== 0 && managementStratagyData.selectInput.length !== 0) {
            const postDataFrommadal = { strategyId: propsFromViewProject._id, projectId: projectIddata, title: managementStratagyData.titleInput, description: managementStratagyData.descriptionInput, type: managementStratagyData.selectInput, userId: databaseUserId, scopes: ArrayPotentialScopeId }
            axios.post(`${process.env.REACT_APP_BASENAME}/updateprojectstrategy`, postDataFrommadal).then((res) => {
                if (res.data.message) {
                    Tosty(res.data.message, 'Data')
                    setIsLoading(false)
                    setEditManagementStratagy(false)
                    dispatch(handleProjectManagementStrategyRefreshPage(res.data))
                    dispatch(handleManagementStrategy(res.data))
                }
            })
            if (dataFromPotentialImpactq.potentialImpactFromPotentialdata) {
                const ArrayPotentialScopeId = dataFromPotentialImpactq.potentialImpactFromPotentialdata?.toString()
                axios.post(`${process.env.REACT_APP_BASENAME}/postProjectPotentialImpactandScopeId`, { scopeid: ArrayPotentialScopeId, projectid: projectIddata, strategyid: propsFromViewProject.id }).then((res) => console.log(res.data, "potentialeditpage"))
                setIsLoading(false)
                setEditManagementStratagy(!editManagementStratagy)
            }
            // setEditManagementStratagy(!editManagementStratagy)
        } else {
            setDescriptionInputError(managementStratagyData.descriptionInput.length !== 0 ? "1" : "")
            setInputTitleError(managementStratagyData.titleInput.length !== 0 ? "1" : "")
            setSelectInputError(managementStratagyData.selectInput.length !== 0 ? "1" : "")
            setIsLoading(false)
        }

    }

    // on delete Management Stratagy
    const onConfirmDelete = () => {
        setIsLoading(true)
        axios.post(`${process.env.REACT_APP_BASENAME}/deleteprojectstrategies`, { selectIds: propsFromViewProject._id, userId: databaseUserId })
            .then((res) => {
                setIsLoading(false)
                dispatch(handleManagementStrategy(res.data))
                dispatch(handleProjectManagementStrategyRefreshPage(res.data))
                if (res.data) {

                    Tosty('Management strategy deleted.', 'Data')
                }
                //dispatch(handleUpdate(res.data))
                setConfirmationModal(!confirmationModal)
            })
    }
    return (
        <>
            {(LoginUserRole === 'Editor') ? <> 
            <div className=" d-flex flex-column">
                    <div style={{ width: '70px', paddingLeft: '5px', paddingRight: '5px', marginBottom: '0px' }} className='d-flex hovereffect cursor-pointer align-items-center justify-content-between' onClick={() => setEditManagementStratagy(!editManagementStratagy)}>
                        <p> <Edit size={18} /> </p>
                        <p style={{ fontSize: '15px' }} >Edit</p>
                    </div>
                    <div onClick={() => setConfirmationModal(true)} style={{ width: '90px', paddingLeft: '5px', paddingRight: '5px', marginBottom: '0px' }} className='d-flex hovereffect cursor-pointer align-items-center justify-content-between'>
                        <p > <Trash2 size={18} /> </p>
                        <p style={{ fontSize: '15px' }}>Delete</p>
                    </div>
                    <div onClick={() => setCertaintyModal(true)} style={{ width: '242px', paddingLeft: '5px', paddingRight: '5px', marginBottom: '0px' }} className='d-flex hovereffect cursor-pointer align-items-center justify-content-between'>
                        <p > <Folder size={20} /> </p>
                        <p style={{ fontSize: '15px' }}>Undertake uncertainty <br/> and sensitivity Assessment</p>
                    </div>     
                </div>
            {/* <div className=" d-flex flex-column">
                <span className='w-100 cursor-pointer' onClick={() => setEditManagementStratagy(!editManagementStratagy)} > <Edit size={20} /> </span>
                <span className='w-100 mt-1 cursor-pointer' onClick={() => setConfirmationModal(true)} ><Trash2 size={20} /></span>
                <span className='w-100 mt-1 cursor-pointer' onClick={() => setCertaintyModal(true)} ><Folder size={20} /></span>
            </div>  */}
            </> : <Eye color='#6e6b7b' className='w-100 mt-1 cursor-pointer' size={20} onClick={() => setEditManagementStratagy(!editManagementStratagy)} />}
            <div>
                <Modal backdrop='static' isOpen={editManagementStratagy} toggle={cancelHandler} className='modal-dialog-centered modal-lg '>
                    <div ref={ref}></div>
                    <ModalHeader toggle={cancelHandler}></ModalHeader>
                    <h4 className="text-center mb-2 mt-1"> {(LoginUserRole === 'Viewer') ? 'View Management Strategy' : 'Edit Existing Management Strategy'}</h4>
                    <ModalBody>
                        <Form onSubmit={(e) => e.preventDefault()}>
                            <Row>
                                <Col>
                                    {propsFromViewProject.title ? <label style={{ marginBottom: '5px' }}>Title </label> : ''}
                                    <Input name='titleInput' defaultValue={propsFromViewProject.title} disabled={(LoginUserRole === 'Viewer')} onChange={onHandleChange} placeholder='Title' />
                                    {inputTitleError.length === 0 ? <span style={{ color: "red" }}>Add Title</span> : " "}

                                    {propsFromViewProject.description ? <label className="mt-1" >Description </label> : ''}
                                    <Input defaultValue={propsFromViewProject.description} disabled={(LoginUserRole === 'Viewer')} style={{ width: "100%", marginTop: '5px' }} type='Description' onChange={onHandleChange} name='descriptionInput' placeholder='Textarea' />
                                    {descriptionInputError.length === 0 ? <span style={{ color: "red" }}>Add Description</span> : " "}

                                    {propsFromViewProject.type ? <label className="mt-1" >Type </label> : ''}
                                    <div >
                                        <select className="form-select" style={{ marginTop: '5px' }} name="selectInput" onChange={onHandleChange} disabled={(LoginUserRole === 'Viewer')} aria-label="Floating label select example">
                                            <option selected  >{propsFromViewProject.type} </option>
                                            {propsFromViewProject.type !== "Avoid" ? <option value={"Avoid"}>Avoid</option> : null}
                                            {propsFromViewProject.type !== "Minimise" ? <option value={"Minimise"} >Minimise</option> : null}
                                            {propsFromViewProject.type !== "Offset" ? <option value={"Offset"}>Offset</option> : null}
                                        </select>
                                        {selectInputError.length === 0 ? <span style={{ color: "red" }}>Select Type</span> : " "}
                                        <PotentialImpactEvent For='Strategy' scopeid={propsFromViewProject} />
                                    </div>
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer justify-content-center">
                        {(LoginUserRole === "Editor") ? (<><Button color="primary" onClick={editMangementHandler}>
                            {isLoading === true ? <Spinner size='sm' /> : 'UPDATE'}
                        </Button>
                            <Button onClick={cancelHandler}>CANCEL</Button></>) : ''}
                    </ModalFooter>
                </Modal>
                {/* uncertainty modal */}
                <Modal backdrop='static' isOpen={certaintyModal} toggle={cancelHandler} className='modal-dialog-centered modal-lg '>
                    <div ref={ref}></div>
                    <ModalHeader toggle={cancelHandler}></ModalHeader>
                    <h4 className="text-center mb-2 mt-1"> {(LoginUserRole === 'Viewer') ? 'View Undertake uncertainty and sensitivity assessment' : 'Undertake uncertainty and sensitivity assessment'}</h4>
                    <ModalBody>
                        <Form onSubmit={(e) => e.preventDefault()}>
                            <Row>
                                <Col>
                                    {<label className="mb-1"  >Level of certainty of management strategy effectiveness </label>}
                                    <div >
                                        <select className="form-select" name="level" onChange={onHandleCertainty} disabled={(LoginUserRole === 'Viewer')} aria-label="Floating label select example">
                                            {certaintyData.level ? '' : <option selected > Select level certainty </option>}
                                            {
                                                level.map((level_item) => {
                                                    return <option selected={certaintyData.level === level_item} value={level_item} > {level_item} </option>
                                                })
                                            }
                                        </select>
                                        {levelErr ? <span style={{ color: "red" }}>{levelErr}</span> : ''}
                                    </div>
                                    <label className="mt-1" >Level of certainty of management strategy effectiveness description  </label>
                                    <Input defaultValue={certaintyData.levelDescription} type='textarea' className=" mt-2 h-20%" disabled={(LoginUserRole === 'Viewer')} style={{ width: "100%" }} onChange={onHandleCertainty} name='levelDescription' placeholder='Enter value' />
                                    <div>
                                        {levelDescErr ? <span style={{ color: "red" }}>{levelDescErr}</span> : ''}
                                    </div>

                                    {<label className="mb-1 mt-1"  >Sensitivity to change of assumptions of the control measures </label>}
                                    <div >
                                        <select className="form-select" name="assumptions" onChange={onHandleCertainty} disabled={(LoginUserRole === 'Viewer')} aria-label="Floating label select example">
                                            {certaintyData?.assumptions ? '' : <option selected > Select level sensitivity </option>}
                                            {
                                                assumption.map((assumption_item) => {
                                                    return <option selected={certaintyData?.assumptions === assumption_item} value={assumption_item} > {assumption_item} </option>
                                                })
                                            }
                                        </select>
                                        {assumpErr ? <span style={{ color: "red" }}>{assumpErr}</span> : ''}
                                    </div>
                                    <label className="mt-1" >Sensitivity to change of assumptions of the control measures description</label>
                                    <Input defaultValue={certaintyData.assumptionDesc} className=" mt-2" disabled={(LoginUserRole === 'Viewer')} style={{ width: "100%" }} type='textarea' onChange={onHandleCertainty} name='assumptionDesc' placeholder='Enter value' />
                                    {assumpDescErr ? <span style={{ color: "red" }}>{assumpDescErr}</span> : ''}
                                </Col>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer justify-content-center">
                        {(LoginUserRole === "Editor") ? (<><Button color="primary" onClick={handleCertaintyData}>
                            {isLoading === true ? <Spinner size='sm' /> : 'SAVE'}
                        </Button>
                            <Button onClick={cancelHandler}>CANCEL</Button></>) : ''}
                    </ModalFooter>
                </Modal>
            </div>
            <div className='demo-inline-spacing'>
                <div className='vertically-centered-modal'>
                    <Modal backdrop='static' isOpen={confirmationModal} toggle={() => setConfirmationModal(!confirmationModal)} className='modal-dialog-centered'>
                        <div ref={ref}></div>
                        <ModalHeader toggle={() => setConfirmationModal(!confirmationModal)}>Confirmation </ModalHeader>
                        <ModalBody>Do you want to delete this record ?</ModalBody>
                        <ModalFooter>
                            <Button color="primary" type='submit' onClick={onConfirmDelete}> {isLoading === true ? <Spinner size='sm' /> : 'YES'}  </Button>
                            <Button className="offset-2" color="primary" onClick={() => setConfirmationModal(!confirmationModal)}>NO</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        </ >
    )
}

export default EditManagementStrategy