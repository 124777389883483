// ** React Imports
import { lazy } from 'react'
///import PrintPage from '../../views/ProjectScope/PrintPage'
//import PrintReport from '../../views/ProjectScope/Print'
// const Home = lazy(() => import('../../views/pages/dashboard/Home'))
const Login = lazy(() => import('../../views/pages/authentication/Login'))
const Register = lazy(() => import('../../views/pages/authentication/Register'))
const TermCondition = lazy(() => import('../../views/pages/authentication/TermCondition'))
const ForgotPassword = lazy(() => import('../../views/pages/authentication/ForgotPassword'))
const ResetPassword = lazy(() => import('../../views/pages/authentication/ResetPassword'))
const UserStatusPage = lazy(() => import('../../views/pages/authentication/UserStatusPage'))
const ProjectUserStatus = lazy(() => import('../../views/Permissions/ProjectUserStatus'))
const PrintPage = lazy(() => import('../../views/ProjectScope/PrintPage'))
const AuthenticationRoutes = [
  {
    path: '/login',
    element: <Login />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },

  {
    path: '/register',
    element: <Register />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },

  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    layout: 'BlankLayout',
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/termcondition',
    element: <TermCondition />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/user-status',
    element: <UserStatusPage />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/accept-invitation',
    element: <ProjectUserStatus />,
    meta: {
      layout: 'blank',
      publicRoute: true,
      restricted: true
    }
  },
  {
    path: '/print-page/:id',
    element: <PrintPage />,
    meta: {
      layout: 'blank',
      publicRoute: true
     // restricted: false
    }
  }

]

export default AuthenticationRoutes
