// // ** Navigation imports
// import apps from './apps'
// import pages from './pages'
// import others from './others'
// import charts from './charts'
import dashboards from './dashboards'
// import uiElements from './ui-elements'
// import formsAndTables from './forms-tables'

// ** Merge & Export
export default [...dashboards]

import { PlusCircle, Mail, Home } from "react-feather"
// import AddNewScopingModal from "../../views/Custom Components/AddNewScopingModal"


// export default [
//   {
//     id: "createProject",
//     title: "CREATE PROJECT",
//     icon: <PlusCircle size={20} />,
//     navLink: "/CreateProject"
//     // onClick: () => { }
//     //newTab: "_blank"

//   },
//   {
//     id: "home",
//     title: "Home",
//     icon: <Home size={20} />,
//     navLink: "/home"
//   },
//   {
//     id: "ProjectDetails",
//     title: "ProjectDetails ",
//     icon: <Mail size={20} />,
//     navLink: "/ProjectDetails"
//   }
// ]

