// ** React Imports
import { Outlet, useParams } from 'react-router-dom'
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout'

// ** Menu Items Array
import navigation from '@src/navigation/vertical'
import { CheckSquare, Circle, Clipboard, LifeBuoy, MapPin, PieChart, Unlock, User, Users } from "react-feather"
import { useSelector, useDispatch } from "react-redux"
import {useEffect} from 'react'
import axios from 'axios'
import { handleAllProjects } from '../redux/navbar'
import ProjectMenus from '../views/ProjectDetails/ProjectMenus'

const VerticalLayout = props => {
  const dispatch = useDispatch()
  const param = useParams()
  const {userDetails} = useSelector((state) => state.authentication)
  const allprojectsfromRedux = useSelector((state) => state.navbar.AllProjects)
  const loggedInUserId = localStorage.getItem('loggedInUserId')
  useEffect(() => {
    if (allprojectsfromRedux.length === 0 && loggedInUserId) {
      axios.get(`${process.env.REACT_APP_BASENAME}/getprojectsbyuser/${localStorage.getItem('loggedInUserId')}`)
        .then(res => {
          dispatch(handleAllProjects(res.data.data))
        }).catch(err => {
          console.log(err)
        })
    }
    axios.get(`${process.env.REACT_APP_BASENAME}/updatenteractiontime/${localStorage.getItem('loggedInUserId')}`).then((data) => {
      console.log(data)
    })
    }, [loggedInUserId])

    console.log(param)
   const menuarray =  userDetails?.role === 'Admin' ? [] : [ 
    {
    id:"dashboard",
    title:"Dashboard",
    icon: <PieChart size={18}/>,
    navLink:"/dashboard"
   },
   {
    id:"customise",
    title:"Customise",
    icon: <Clipboard size={18}/>,
    navLink:"/customise"
   },
   {
    id:"impactevent",
    title:"Potential Impact Events",
    icon: <LifeBuoy size={18}/>,
    navLink:"/impact-event"
   },
   {
    id:"managestrategy",
    title:"Management Strategy",
    icon: <Users size={18}/>,
    navLink:"/manage-strategy"
   },
   {
    id:"impactsignificance",
    title:"Impact Significance Assessment",
    icon: <LifeBuoy size={18}/>,
    navLink:"/impact-signficance"
   },
   {
    id:"action",
    title:"Actions",
    icon: <CheckSquare size={18} />,
    navLink:"/action"
   },
   {
    id:"permissions",
    title:"Permissions",
    icon: <Unlock size={18}/>,
    navLink:"/permissions"
   }
]

const navigationroute = userDetails?.role === 'Admin' ? [
   {
    id: "adminrouter",
    title: "ADMIN CONSOLE",
    icon: <User/>,
    navLink: "/admin-console"
  }
 ] : [...navigation]

let routes = []

 if (param.projectId) {
    routes = menuarray.map((item) => {
    return {
      id: `${item._id}`,
      title: `${item.title}`,
      wrap: true,
      icon: item.icon,
      navLink: `${item.navLink}/${param.projectId}`
    }
  })
 } 
 

  return (
    <Layout menuData={
      [
        ...navigationroute, ...routes
        
        /*...allprojectsfromRedux.map((item) => {
          return {
            id: `${item._id}`,
            title: `${item.projectName}`,
            wrap: true,
            icon: < MapPin size={5} />,
            navLink: `/project-details/${item._id}`
          }
        } 
        )*/
      ]
    }  {...props}>
      
      <Outlet />
    </Layout >
  )
}

export default VerticalLayout

