import ManagementStrategy from "../managementStrategy/ManagementStrategy"
// ** React Imports
import { Fragment, useState, useEffect } from 'react'
import { getCountry } from "country-state-picker"
// ** Reactstrap Imports
import {TabContent, TabPane, Nav, NavItem, NavLink, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Button} from 'reactstrap'
import axios from 'axios'
// ** Icons Imports
import {Clipboard, Star, Users, Unlock, PieChart, CheckSquare, LifeBuoy } from 'react-feather'
import Scoping from '../../views/ProjectScope/Scoping'
import CustomizeProject from '../CustomiseProject/CustomizeProject'
import { useDispatch, useSelector } from 'react-redux'
import {  handleActiveTab, handleCustomisationClose, handleProjectId, handleProjectUserDetails } from "../../redux/navbar"
import Dashboard from "../pages/dashboard/Chart/Dashboard"
import Permissions from "../Permissions/Permissions"
import ViewActions from "../actions/ViewActions"
import { useParams } from "react-router-dom"

const ProjectDetails = () => {
  const dispatch = useDispatch()
  // useParams to get projectId
  const parms = useParams()

  const [confirmationModal, setConfirmationModal] = useState(false)
  const [isLoading, setIsLoding] = useState(false)
  const [projectDetails, setProjectDetails] = useState({})
  const [countryName, setCountryName] = useState()
  // const [valueOf, setValueOf] = useState('')
  const activeTabFromRedux = useSelector((state) => state.navbar.activeTab)
  // console.log('Data', activeTabFromRedux)
  const [active, setActive] = useState(activeTabFromRedux)
  
  const CustomiseCloseValue = useSelector(state => state.navbar.closeCustomisation)
  const projectID = useSelector(state => state.navbar.projectId)

  // to find out the active tab
  const toggle = (a) => {
    dispatch(handleActiveTab(a))
    if (active !== a) {
      setActive(a)
    }
  }

  useEffect(() => {
    if (projectID === parms.projectId) {
      toggle(activeTabFromRedux)
    } else {
      setIsLoding(true)
      toggle('1')
      dispatch(handleProjectId(parms.projectId))
    }
      const loggedInUserId = localStorage.getItem('loggedInUserId')
      //to get th eproject Details and the logged in user Role in selected project
      axios.post(`${process.env.REACT_APP_BASENAME}/getprojectdetails`, {projectId:parms.projectId, userId:loggedInUserId})
        .then((res) => {
            if (res.data.data) {
              setProjectDetails(res.data.data.project)
              dispatch(handleProjectUserDetails(res.data.data.role))
              setIsLoding(false)
              const country = getCountry(res.data.data.project.countryId)
              setCountryName(country.name)
            }
        })
  }, [parms.projectId, activeTabFromRedux])

  const withoutSaving = () => {
  // console.log('Data toggleValue', active)
    if (CustomiseCloseValue === 'false') {
    dispatch(handleCustomisationClose('true')) 
    setConfirmationModal(!confirmationModal)
    toggle(activeTabFromRedux)
    }
  }

  return (
    <> 
    {/* Project Deatils shown on the top of Project Deatils Page. */}
    {isLoading === true ? '' : (<><h2>{projectDetails?.projectName ? projectDetails?.projectName : ''}</h2>
      <h6>{`${projectDetails?.projectTypeId ? `${projectDetails?.projectTypeId },` : ''} ${projectDetails?.stateId ? `${projectDetails?.stateId},` : ''} ${countryName ?? ''} `}</h6></>)}
      <hr />
      {/* Are you sure you want to Leave without saving changes */}
      <div className='demo-inline-spacing'>
                <div className='vertically-centered-modal'>
                    <Modal isOpen={confirmationModal} toggle={() => setConfirmationModal(!confirmationModal)} className='modal-dialog-centered'>
                        <ModalHeader toggle={() => setConfirmationModal(!confirmationModal)}>Confirmation </ModalHeader>
                        <ModalBody> Are you sure you want to Leave without saving changes ? </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type='submit' onClick={() => { withoutSaving() }}>{isLoading === true ? <Spinner  size='sm'/> : 'YES'} </Button>
                            <Button className="offset-2" color="secondary" onClick={() => setConfirmationModal(!confirmationModal)}> NO</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
      </>
  )
}

export default ProjectDetails
