
import Select from 'react-select'
import { selectThemeColors } from '@utils'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import AddLogo from '../../assets/images/portrait/small/add.png'
import { useDispatch, useSelector } from 'react-redux/es/exports'
import { handleCostumisePathway, handleCostumisePathwayModal, handleCustomisationClose } from '../../redux/navbar'
import { Button, Col, Row, Spinner } from 'reactstrap'
import { XCircle, Check, PlusCircle } from 'react-feather'
import Avatar from '@components/avatar'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

//Tosty Message component
const Tosty = (msg, type) => {
  toast(
    <Row className='demo-vertical-spacing'>
      <div className='d-flex'><div className='me-1'>{type === 'Error' ? <Avatar size='sm' color='danger' icon={<Check size={12} />} /> : <Avatar size='sm' color='success' icon={<Check size={12} />} />}</div>
        <div className='d-flex flex-column'><h6>{msg}</h6></div>
      </div>
    </Row>)
}
const DropdownPathways = () => {

  const [data, setData] = useState("")
  const parms = useParams()
  const [isLoading, setIsLoading] = useState(false)
  const [prefix, setPrefix] = useState("")
  const [add, setAdd] = useState()
  const [error, setError] = useState()
  const [errorInput, setErrorInput] = useState()
  const [selected, setSelected] = useState([])
  const dispatch = useDispatch()
  const [stage] = useState([])
  const projectId = parms.projectId
  const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
   const activeTabFromRedux = useSelector((state) => state.navbar.activeTab)
  const databaseUserId = localStorage.getItem('loggedInUserId')
  const colourOptions = [stage]
  const addHandler = () => {
    setAdd(0)
    setData("")
    setPrefix("")
  }
  const cancelHandler = () => {
    setAdd(1)
    setData('')
    setError()
    setErrorInput()
  }

  Array.prototype.removeValue = function (name, value) {
    const array = $.map(this, function (v) {
      return v[name] === value ? null : v
    })
    this.length = 0 //clear original array
    this.push.apply(this, array) //push all elements except the one we want to delete
  }

  //fro add pathway in array
  const handleChangeForAddPathways = (val) => {
    if (val !== null) {
      if (val.label !== null || val.isEmpty({})) {
        if (val.value === '') {
          Tosty('Select Pathway can not be selected. Select another value', 'Error')
        } else {
        const check = selected.indexOf(val.label)
        if (check === -1) {
          const selectedIndex = selected.indexOf(val.label)
          let newSelected = []
          if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, val.label)
          } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
          } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
          } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
          }
          setSelected(newSelected)
          dispatch(handleCustomisationClose('false'))
          dispatch(handleCostumisePathway(newSelected))
        } else {
          Tosty('Pathway already existed.', "Error")
        }
      }
    }
    }
  }

  // For Remove pathway from Array
  const handleChangeForDeletePathways = (id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
    dispatch(handleCostumisePathway(newSelected))
  }

  // get pathways from loggedIn userId
  useEffect(() => {
    setIsLoading(true)
    stage.length = 0
    stage.push({ value: "", label: "Select Pathway"})
    axios.get(`${process.env.REACT_APP_BASENAME}/getpathways/${databaseUserId}`).then(res => {
      res.data.data?.map(ele => stage.push({ value: `${ele.pathway}-${ele.prefix}`, label: `${ele.pathway}` }))
      res.data.data?.map(ele => colourOptions.push({ value: `${ele.pathway}-${ele.prefix}`, label: `${ele.pathway}-${ele.prefix}` }))
      setIsLoading(false)
    })
    setIsLoading(true)
    axios.get(`${process.env.REACT_APP_BASENAME}/getcustomization/${projectId}`)
      .then((res) => {
        if (res.data.data?.pathways) {
          setSelected(res.data.data?.pathways)
          setIsLoading(false)
        }
      })
  }, [activeTabFromRedux === '1'])


  // save customise Pathaway
  const saveHandler = () => {
    if (data.length !== 0 && prefix.length !== 0) {
      const check = selected.indexOf(data)
      if (check === -1) {
        handleChangeForDeletePathways(data)
        const userIdData = { userId: databaseUserId, pathway: data, preFix: prefix }
        axios.post(`${process.env.REACT_APP_BASENAME}/savepathway`, userIdData).then(() => {
          dispatch(handleCostumisePathwayModal(data))
          stage.push({ value: `${data}-${prefix}`, label: `${data}` })
        })
        setAdd(1)
      } else {
        Tosty("Custom Pathway already exists", "Error")
      }
    } else {
      if (data.length === 0 && prefix.length === 0) {
        setErrorInput(0)
        setError(0)
      } else if (data.length === 0) setErrorInput(0)
      else if (prefix.length === 0) {
        setError(0)
      }
    }
  }
  dispatch(handleCostumisePathway(selected))

  return (

    <div>
      <Row className='mt-1'>
        <h4 >Select Pathways</h4>
      </Row>
      {isLoading === true ? (<> <center><Spinner color="primary" className='text-center' style={{ height: '2rem', width: '2rem' }} /></center>  </>)  : (<Row>
        <Col className='mt-1 ' md='4' xl='4' >
          <Select onChange={handleChangeForAddPathways} theme={selectThemeColors} className='react-select' maxMenuHeight={200} classNamePrefix='select' placeholder='Select Pathway' name='clear' options={stage} isClearable />
        </Col>
        <Col md='1' xl='1'  className='text-center mt-1 '>
          {(LoginUserRole === 'Editor') ? (<> <PlusCircle onClick={addHandler}  size={30} /></>) : ''}
        </Col>
        <Col className='mt-1' md='6' xl='6' >
          {selected?.map(ele => <React.Fragment key={ele}>
            <Button.Ripple className='btn btn-outline-primary round waves-effect mb-1' style={{ marginRight:'10px'}} size='sm' color='secondary' outline>
              <span className='round height-60-per'>{ele}</span><XCircle size={15} className='align-middle me-25 ms-1' onClick={() => handleChangeForDeletePathways(ele)} />
            </Button.Ripple>
          </React.Fragment>
          )}

          {/* Code to enter custom pathway and prefix */}
          {
            (add === 0) ? <Row className='mt-1 mb-1'>
              <Row>
                <Col sm='6'>
                  <input type='text' id='Pathways' name='Pathways' className='form-control float-start' placeholder='Enter Custom Pathways' pattern="[A-Za-z]{1,}" title="Only alphabets are accepted"  onChange={(e) => {
                      setErrorInput(1)
                      setData(e.target.value.trim())
                    }} />
                  {errorInput === 0 ? <span style={{ color: "red" }}>Enter Custom Pathways</span> : " "}
                </Col>
                <Col sm='4'>
                  <input type='text' id='prefix'
                    name='prefix' className='form-control float-end'
                    placeholder='Enter Prefix' onChange={(e) => {
                      setError(1)
                      setPrefix(e.target.value.trim())
                    }} />
                  {error === 0 ? <span style={{ color: "red" }}>Enter Custom Pathway Prefix</span> : " "}
                </Col>
              </Row>
              <Col sm='6 mt-1'>
                <Button color='primary' className='ms-1' onClick={saveHandler}>Add</Button>
                <Button color='secondary' className='ms-1' onClick={cancelHandler}>Cancel</Button>
              </Col>
            </Row> : ""}
        </Col>
      </Row>)}
     
    </div>
  )
}
export default DropdownPathways
