import { Col, Row, Input, Button, Label, Card, Modal, ModalHeader, ModalBody, ModalFooter, PopoverBody, UncontrolledPopover, Spinner, Popover, PopoverHeader, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import DataTable from 'react-data-table-component'
import axios from 'axios'
import React, { Fragment, useState, useEffect, useRef } from 'react'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import { ChevronDown, Check, MoreHorizontal } from 'react-feather'
import toast from 'react-hot-toast'
import Avatar from '@components/avatar'
import AddManagementStrategy from './AddManagementStrategy'
import EditManagementStrategy from './EditManagementStrategy'
import { handleProjectPotentialImpactfromUpadateStrtegypage, handleProjectScopeIds, handleSelectedStrategy } from '../../redux/managementStrategy'
import { handleUpdate } from '../../redux/navbar'
import { useParams } from 'react-router-dom'
import StragetgyActions from './StragetgyActions'
import ExcelReport from '../Reports/ExcelReport'
import useClickOutside from '../ClickOutsideHook/Outsideclick'

const ViewManagementStrategy = () => {
  const dispatch = useDispatch()
  const [popoverOpen, setPopoverOpen] = useState(false)
  const parms = useParams()
  const [selected, setSelected] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [confirmationModal, setConfirmationModal] = useState(false)
  const projectIddata = parms.projectId
  const updateOne = useSelector(state => state.navbar.onUpdate)
  const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
  const ConfrimDelete = useSelector(state => state.managementStrategy.deleteConfirm)
  const refreshPage = useSelector(state => state.managementStrategy.deleteConfirm.refreshPage)
  const activeTabFromRedux = useSelector((state) => state.navbar.activeTab)
  const databaseUserId = localStorage.getItem('loggedInUserId')
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [searchValue] = useState('')
  const [data, setData] = useState([])
  const [totalRecord, setTotalRecords] = useState()
  const [getData, setGetData] = useState({
    projectId: parms.projectId,
    page: currentPage,
    perPage: rowsPerPage,
    q: searchValue
  })

  const ref = useRef(null)
  useClickOutside(ref, () => setPopoverOpen(false))


  useEffect(() => {
    // if (projectIddata) {
        setIsLoading(true)
        axios.post(`${process.env.REACT_APP_BASENAME}/getstrategiespagewise`, getData)
          .then((res) => {
            setIsLoading(false)
            setData(res.data.data?.allData)
            dispatch(handleProjectPotentialImpactfromUpadateStrtegypage(res.data.data.allData))
            dispatch(handleProjectScopeIds(res.data.data.allData))
            setTotalRecords(res.data.data.total)  
          })
    // }
  }, [refreshPage, getData, updateOne, activeTabFromRedux === '4'])


  const onHandleSelect = (id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected) 
  }

  // cancel Handler for delete
  const cancelHandler = () => {
    setSelected([])
    setConfirmationModal(!confirmationModal)
  }

  const handleRow = (row) => {
     dispatch(handleSelectedStrategy(row))

  }

  //to delete Management Stratagy
  const onConfirmDelete = () => {
    setIsLoading(true)
    axios.post(`${process.env.REACT_APP_BASENAME}/deleteprojectstrategies`, { userId: databaseUserId, selectIds: selected.toString() })
      .then((res) => {
        //dispatch(handleProjectActiondeleteConfirmRefreshPage(res.data))
        if (res.data.data) {
          setIsLoading(false)
          dispatch(handleUpdate(res.data.data))
          toast(
            <Row className='demo-vertical-spacing'>
              <div className='d-flex'><div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /></div>
                <div className='d-flex flex-column'><h6>{res.data.data.message}</h6></div></div>
            </Row>
          )
          cancelHandler()
        }
      })
  }

  //Pagination Functions
  const isSelected = (id) => selected.indexOf(id) !== -1
  const serverSideColumns = [
    {
      minWidth: '50px',
      cell: row => {
        return (
          <>{(LoginUserRole === 'Editor') ? <Input type='checkbox' checked={isSelected(row?._id)} className='cursor-pointer' onChange={() => onHandleSelect(row?._id)} /> : ''} </>
        )
      }
    },
    {
      sortable: true,
      name: 'Title',
      minWidth: '100px',
      wrap:true,
      selector: row => row?.title
    },
    {
      sortable: true,
      minWidth: '260px',
      name: 'Relevant Potential Impact Event',
      wrap:true,
      selector: row => { return row.scopes?.length !== 0 && row.scopes !== null ? row.scopes.map((scope, i) => `${scope}${i < row.scopes.length - 1 ? ', ' : ''}`) : "-" }
    },
    {
      sortable: true,
      name: 'Type',
      minWidth: '100px',
      wrap:true,
      selector: row => row?.type
    },
    {
      sortable: true,
      name: 'Level of certainty',
      minWidth: '100px',
      wrap:true,
      selector:row => row?.certainty_data?.level
    },
    {
      sortable: true,
      name: 'Sensitivity to change',
      minWidth: '100px',
      wrap:true,
      selector:row => row?.certainty_data?.assumptions
    },
    {
      name: 'Action',
      minWidth: '100px',
      wrap:true,
      selector: row => {
        return (
          <>
             {/* <div>
              <span outline id='popLeft'>
              <MoreHorizontal onClick={() => handleRow(row)} />
              </span>
              <UncontrolledPopover placement='right' target='popLeft'>
                 <EditManagementStrategy/>
              </UncontrolledPopover>
            </div> */}
                <span color='primary' outline id='controlledPopover'>
                  <MoreHorizontal onClick={() => handleRow(row)} />
                  </span>
                  <Popover
                    style={{zIndex:1200, position:''}}
                    placement='top'
                    isOpen={popoverOpen}
                    target='controlledPopover'
                    toggle={() => setPopoverOpen(true)}
                  >
                    {/* <PopoverHeader>Controlled Popover</PopoverHeader> */}
                    <PopoverBody>
                    <span ref={ref} className='d-flex'>
                    <EditManagementStrategy setPopoverOpen={setPopoverOpen} />
                    </span>
                    </PopoverBody>
                  </Popover>
            {/* {<StragetgyActions rowData ={row} />} */}
            {/* {(LoginUserRole === 'Editor') ? (<><Col className='position-absolute bottom-0  '><EditManagementStrategy mapDataForEditDelete={row}/> </Col></>) : <EditManagementStrategy mapDataForEditDelete={row} />} */}
          </>)
      }
    }
  ]

  // to handle pagination
  const handlePagination = page => {
    console.log(page)
    setGetData({
      projectId: projectIddata,
      page: page.selected + 1,
      perPage: rowsPerPage
    })
    setCurrentPage(page.selected + 1)
  }

  // ** Function to handle per page
  const handlePerPage = e => {
    setGetData({
      projectId: projectIddata,
      page: currentPage,
      perPage: parseInt(e.target.value),
      q: searchValue
    })
    setRowsPerPage(parseInt(e.target.value))
  }

  // ** Custom Pagination
  const CustomPagination = () => {
    const count = Math.ceil(totalRecord / rowsPerPage)

    return (
      <div className="d-flex align-items-center justify-content-between mt-1">
      <div className='d-flex align-items-center'>
                        <Label for='sort-select'> Select No. of Records </Label>
                        <Input className='dataTable-select' style={{width:'70px', height:'33px', margin:'5px'}} type='select' id='sort-select' value={rowsPerPage} onChange={e => handlePerPage(e)} >
                          <option value={3}>3</option>
                          <option value={5}>5</option>
                          <option value={7}>7</option>
                          <option value={10}>10</option>
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={75}>75</option>
                          <option value={100}>100</option>
                        </Input>
                      </div>
      <ReactPaginate previousLabel={''} nextLabel={''} breakLabel='...' pageCount={Math.ceil(count) || 1} marginPagesDisplayed={2} pageRangeDisplayed={2} activeClassName='active' forcePage={currentPage !== 0 ? currentPage - 1 : 0} onPageChange={page => handlePagination(page)} pageClassName='page-item' breakClassName='page-item' nextLinkClassName='page-link' pageLinkClassName='page-link' breakLinkClassName='page-link' previousLinkClassName='page-link' nextClassName='page-item next-item' previousClassName='page-item prev-item' containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-0'} />
      </div>
    )
  }

  // ** Table data to render
  const dataToRender = () => {
    const filters = {
      q: searchValue
    }
    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })

    if (data?.length > 0) {
      return data
    } else if (data?.length === 0 && isFiltered) {
      return []
    } else {
      return data?.slice(0, rowsPerPage)
    }
  }

  return (
    <>
      <Fragment>
      <Row> <Col>
           <UncontrolledButtonDropdown className='mb-1'>
               <DropdownToggle disabled={selected.length === 0} color='secondary' caret>
                 Download Report
               </DropdownToggle>
               <DropdownMenu end>
                    <ExcelReport selected ={selected}/>
               </DropdownMenu>
               </UncontrolledButtonDropdown> 
               <Button color="secondary" style={{marginLeft:'10px'}} disabled={selected.length === 0} className="mb-1" onClick={() => setConfirmationModal(!confirmationModal)}>Delete</Button>
              </Col>
            <Col >{(LoginUserRole === 'Viewer') || (selected.length > 0) ? "" : <AddManagementStrategy />}</Col>
            {/* {selected.length === 0 || ConfrimDelete.yes === "yes" || ConfrimDelete.No === "No" ? <>
              <Col className=' ms-1'>
                {(LoginUserRole === 'Viewer') ? "" : <AddManagementStrategy />}
              </Col>
            </> : <Col sm='6 offset-2'><Button color="secondary" className="mb-1 float-start" onClick={() => setConfirmationModal(!confirmationModal)}>Delete</Button></Col>} */}
          </Row>
        <Card>
          <Row className='mt-1'>
            <Col sm='10 '><h4 className="ms-1 float-start">Identify relevant management strategies and their role including avoid, minimise or offset.</h4></Col></Row>
       
          <hr />
          <Row className="mt-0 ms-1">
            { (data?.length === 0) ? (<h6 className=' text-center'> Management Strategies Will Appear Here</h6>) : (
                <><Row>
                  <Col sm='12 '>
                    <DataTable noHeader pagination paginationServer className='react-dataTable ' columns={serverSideColumns} sortIcon={<ChevronDown size={15} />} paginationComponent={CustomPagination} data={dataToRender()}/>
                  </Col>
                </Row>
                  <Row className='mx-0 mt-0 mb-50'>
                    <Col sm='3 '>
                    </Col>
                  </Row></>
              )}
          </Row>
        </Card>
      </Fragment>
      <div className='demo-inline-spacing'><div className='vertically-centered-modal'>
        <Modal  backdrop='static' isOpen={confirmationModal} toggle={cancelHandler} className='modal-dialog-centered'>
          <ModalHeader toggle={cancelHandler}>Confirmation</ModalHeader>
          <ModalBody>Do you want to delete this record ?</ModalBody>
          <ModalFooter>
            <Button color="primary" type='submit' onClick={onConfirmDelete}>{isLoading === true ? <Spinner size='sm'  /> : 'YES'}</Button>
            <Button className="offset-2" color="secondary" onClick={cancelHandler}> NO</Button></ModalFooter>
        </Modal>
      </div>
      </div>
    </>
  )
}

export default ViewManagementStrategy
