import { useNavigate } from "react-router-dom"
import toast from 'react-hot-toast'
import { Check, Edit } from 'react-feather'
import Avatar from '@components/avatar'
import { useSkin } from "@hooks/useSkin"
require('dotenv').config()
import { Row, CardText, FormGroup, Label, Form, Input, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"
//** Import Country State Picker */
import { getCountries, getStates } from 'country-state-picker'
import axios from 'axios'
import "@styles/react/pages/page-authentication.scss"
import { Fragment, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { handleEditProject } from "../../redux/navbar"

const CreateProject = () => {

    const dispatch = useDispatch()
    const [centeredModal, setCenteredModal] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate()
    const [projectType, setprojectType] = useState([])
    const [selectedState, setSelectedState] = useState('')
    const userID = localStorage.getItem('loggedInUserId')  // userId is undefined
    const EditProjectId = useSelector(state => state.navbar.onEditProject)
    const [EditProject, setEditProject] = useState(EditProjectId ? EditProjectId : '')
    const [projectData, setProjectData] = useState({
        projectName: '',
        projectTypeId: '',
        description: '',
        countryId: '',
        stateId: '',
        userId: userID
    })
    const [errorprojectName, setErrorprojectName] = useState()
    const [errorprojectType, setErrorprojectType] = useState()
    const [errorDescription, setErrorDescription] = useState()
    const [errorCountryId, setErrorCountryId] = useState()
    const [errorstate, setErrorstate] = useState()
    const countries = getCountries()
    //Tosty Message component
    const Tosty = (msg, type) => {
        toast(
            <Row className='demo-vertical-spacing'>
                <div className='d-flex'><div className='me-1'>{type === 'Error' ? <Avatar size='sm' color='danger' icon={<Check size={12} />} /> : <Avatar size='sm' color='success' icon={<Check size={12} />} />}</div>
                    <div className='d-flex flex-column'><h6>{msg}</h6></div>
                </div>
            </Row>)
    }

    // on Country Change
    const onStateChangeOnCountryChange = (e) => {
        setSelectedState(e.target.value)
        projectData.stateId = ''
    }

    // set state on selection of CountryId
    const states = getStates(EditProjectId ? projectData?.countryId : selectedState)

    // ** Hooks
    const { skin } = useSkin()
    const illustration =
        skin === "dark" ? "forgot-password-v2-dark.svg" : "forgot-password-v2.svg",
        source = require(`@src/assets/images/pages/${illustration}`).default
    console.log(source)


    const modal = () => {
        return setCenteredModal(!centeredModal)
    }

    // get Project Types on page load
    useEffect(() => {
        modal()
        axios.get(`${process.env.REACT_APP_BASENAME}/getprojecttypes`)
            .then(res => { setprojectType(res.data.data) })
        if (EditProject) {
            axios.post(`${process.env.REACT_APP_BASENAME}/getprojectdetails`, { projectId: EditProject, userId: userID })
                .then((res) => {
                    if (res.data.data) {
                        setProjectData(res.data.data.project)
                       // onStateChangeOnCountryChange(res.data.data.project.stateId)
                    }
                })
        }

    }, [EditProject])

    // oncahnge event for project data values
    const onHandleChange = (e) => {
        setProjectData({ ...projectData, [e.target.name]: e.target.value })
    }
    console.log(projectData)
    // cancel handler to empty state and error message
    const onHandleCancel = () => {
        dispatch(handleEditProject({}))
        setEditProject('')
        setCenteredModal(!centeredModal)
        setProjectData({})
        setErrorprojectName('')
        setErrorprojectType('')
        setErrorDescription('')
        setErrorstate('')
        setErrorstate('')
        navigate('/home')
        dispatch(handleEditProject(''))
    }
    // on location selection
 
    //on submittion of create project 
    const onSubmit = () => {

        setIsLoading(true)
        if (projectData.projectName === '' || projectData.projectTypeId === '' || projectData.description === '' || projectData.countryId === '' || projectData.stateId === '') {
            if (projectData.projectName.trim().length === 0) setErrorprojectName('0')
            if (projectData.projectTypeId.trim().length === 0) setErrorprojectType('0')
            if (projectData.description.trim().length === 0) setErrorDescription('0')
            if (projectData.countryId.trim().length === 0) setErrorCountryId('0')
            if (projectData.stateId.trim().length === 0) setErrorstate('0')
            setIsLoading(false)
        } else {
            axios.post(`${process.env.REACT_APP_BASENAME}/createproject`, {
                projectName: projectData.projectName,
                projectTypeId: projectData.projectTypeId,
                description: projectData.description,
                countryId: projectData.countryId,
                stateId: projectData.stateId,
                userId: userID,
                location:projectData.location
            }).then(res => {
                setIsLoading(false)
                if (res.data.errorMessage) {   // When Error message Occured
                    Tosty(res.data.errorMessage, 'Error')
                    setCenteredModal(!centeredModal)
                    navigate("/home")
                } else if (res.data.data) {
                    Tosty(res.data.data.message, 'Data')
                    setCenteredModal(!centeredModal)
                    navigate("/home")
                }
            })
        }
    }
    
    //to update project details 
    const onUpdate = () => {
        
        setIsLoading(true)
        if (projectData.projectName === '' || projectData.projectTypeId === '' || projectData.description === '' || projectData.countryId === '' || projectData.stateId === '') {
            if (projectData?.projectName.trim().length === 0 || projectData.projectName === '') setErrorprojectName('0')
            if (projectData?.projectTypeId.length === 0 || projectData?.projectTypeId === '') setErrorprojectType('0')
            if (projectData?.description.trim().length === 0 || projectData.description === '') setErrorDescription('0')
            if (projectData?.countryId.length === 0 || projectData?.countryId === '') setErrorCountryId('0')
            if (projectData?.stateId.length === 0) setErrorstate('0')
            setIsLoading(false)
        } else {
            axios.post(`${process.env.REACT_APP_BASENAME}/updateproject`, {
                projectId: EditProject,
                projectName: projectData.projectName,
                projectTypeId: projectData.projectTypeId,
                description: projectData.description,
                countryId: projectData.countryId,
                stateId: projectData.stateId,
                userId: userID,
                location:projectData.location
            }).then(res => {
                setIsLoading(false)
                if (res.data.errorMessage) {   // When Error message Occured
                    Tosty(res.data.errorMessage, 'Error')
                    setCenteredModal(!centeredModal)
                    navigate("/home")
                } else if (res.data.data) {
                    Tosty(res.data.data.message, 'Data')
                    onHandleCancel()
                    setEditProject('')
                    setCenteredModal(!centeredModal)
                    navigate("/home")
                }
                
            })
        }
    }
    return (
        <>
            <Fragment className='vertically-centered-modal'>
                <Modal  backdrop='static' isOpen={centeredModal} toggle={onHandleCancel} className='modal-dialog-centered'>
                    <ModalHeader toggle={onHandleCancel}></ModalHeader>
                    <ModalBody>
                        <Form>
                            <Row >
                                <h4 className="text-center mb-1">{(projectData?._id) ? 'Update Project' : 'Create New Project'}</h4>
                                <div className="col-md-6 offset-0">
                                    <CardText className="mt-1">Project Name</CardText>
                                    <Input type='text' id='projectName' defaultValue={projectData?.projectName} placeholder="Enter Project Name" name="projectName" onChange={(e) => {
                                        setErrorprojectName(1)
                                        onHandleChange(e)
                                    }} />
                                    {errorprojectName === '0' ? <span style={{ color: "red" }}>Enter Project Name</span> : ''}</div>
                                <div className="col-md-6 offset-0">
                                    <CardText className="mt-1">Type of Project</CardText>
                                    <select className="form-select" id="projectTypeId" onChange={(e) => {
                                        setErrorprojectType(1)
                                        onHandleChange(e)
                                    }} name="projectTypeId" >
                                        {(projectData?.projectTypeId) ? null : <option selected value="">Select Project Type</option>}
                                        {
                                            projectType?.map((projectType) => (
                                                <option selected={(projectData?.projectTypeId === projectType?.projectType)} value={projectType?.projectType}>{projectType?.projectType}</option>
                                            ))
                                        }
                                    </select>
                                    {errorprojectType === '0' ? <span style={{ color: "red" }}>Select Project Type</span> : ''}
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-12 offset-0">
                                    <CardText className="mt-1">Description</CardText>
                                    <Input type='textarea' id='description' defaultValue={projectData?.description} name='description' onChange={(e) => {
                                        setErrorDescription(1)
                                        onHandleChange(e)
                                    }} rows='3' placeholder='Enter Project Description' />
                                    {errorDescription === '0' ? <span style={{ color: "red" }}>Enter Project Description</span> : ''}
                                </div>
                            </Row>
                            <Row>
                                <div className="col-md-6 offset-0">
                                    <CardText className="mt-1">Country</CardText>
                                    <select className="form-select" id="countryId" name='countryId' onChange={(e) => {
                                        setErrorCountryId(1)
                                        onHandleChange(e)
                                        onStateChangeOnCountryChange(e)
                                    }} >
                                        {(projectData?.countryId) ? null : <option selected value="" >Select Country</option>}
                                        {
                                            countries.map(country => (
                                                <option selected={(projectData?.countryId === country.code)} value={country.code} > {country.name}</option>
                                            ))
                                        }
                                    </select>
                                    {errorCountryId === '0' ? <span style={{ color: "red" }}>Select Country</span> : ''}
                                </div>
                                <div className="col-md-6 offset-0">
                                    <CardText className="mt-1">State/Province</CardText>
                                    <select className="form-select" id="stateId" onChange={(e) => {
                                        setErrorstate(1)
                                        onHandleChange(e)
                                    }} name='stateId' >
                                        {(projectData?.stateId) ? null : <option selected value="">Select State</option>}
                                        {
                                            states?.map((state) => (
                                                <option selected={(projectData?.stateId === state)} value={(projectData?.stateId === state) ? projectData?.stateId : state}>{(projectData?.stateId === state) ? projectData?.stateId : state}</option>
                                            ))
                                        }
                                    </select>
                                    {errorstate === '0' ? <span style={{ color: "red" }}>Select State</span> : ''}
                                </div>
                            </Row>
                            <Row>
                            <div>
                            <CardText className=" col-md-6 mt-1">Location</CardText>
                                    <Input type='location' id='location' defaultValue={projectData?.location} placeholder="'Latitude, Longitude' (Optional)" name="location" onChange={(e) => {
                                        onHandleChange(e)
                                    }} />
                                </div>
                            </Row>
                        </Form>
                    </ModalBody>
                    <ModalFooter className=" modal-footer justify-content-center mt-2" >
                        {(projectData?._id) ? <Button color="primary" type='submit' onClick={onUpdate}> {isLoading === true ? <Spinner size='sm' /> : 'UPDATE'} </Button> : <Button color="primary" type='submit' onClick={onSubmit}> {isLoading === true ? <Spinner size='sm' /> : 'SUBMIT'}</Button>}
                       <Button color="secondary" onClick={onHandleCancel}>CANCEL</Button>
                    </ModalFooter>
                </Modal>
            </Fragment>
        </>
    )
}

export default CreateProject
