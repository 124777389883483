// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"
export const getBookmarks = createAsyncThunk(
  "management/getBookmarks",
  async () => {
    const response = await axios.get("/api/bookmarks/data")
    return {
      data: response.data.suggestions,
      bookmarks: response.data.bookmarks
    }
  }
)

export const updateBookmarked = createAsyncThunk(
  "management/updateBookmarked",
  async (id) => {
    await axios.post("/api/bookmarks/update", { id })
    return id
  }
)

export const managementSlice = createSlice({
  name: "management",
  initialState: { potentialImpactFromPotential: { potentialImpactFromPotentialdata: "", stageid: ""},
    deleteConfirm: { yes: "", 
    No: "",
    refreshPage: ""
    },

    projectActiondeleteConfirm: {
      yes: "",
      No: "",
      refreshPage: ""
    },
    refreshpage : false,
    projectScopeIds: [],
    projectActionUserStatus: {},
    ProjectScopeIdsfromScopingToPotential: "",
    projectPotentialImpactfromUpadateStrtegypage: {},
    selectedStrategy : [],
    selectedPIE : []
  },

  reducers: {
    handleProjectPotentialImpactfromUpadateStrtegypage: (state, action) => { state.projectPotentialImpactfromUpadateStrtegypage = action.payload },
    
    handleProjectActionUserStatus: (state, action) => { state.projectActionUserStatus = action.payload },
    
    handleProjectActiondeleteConfirmRefreshPage: (state, action) => { state.projectActiondeleteConfirm.refreshPage = action.payload },
    
    handleProjectActiondeleteConfirmYes: (state, action) => { state.projectActiondeleteConfirm.yes = action.payload },
    
    handleProjectActiondeleteConfirmNo: (state, action) => { state.projectActiondeleteConfirm.No = action.payload },
    
    handleProjectManagementStrategyRefreshPage: (state, action) => { state.deleteConfirm.refreshPage = action.payload },
    
    handleDeleteConfirmYes: (state, action) => { state.deleteConfirm.yes = action.payload },
    
    handleDeleteConfirmNo: (state, action) => { state.deleteConfirm.No = action.payload },
    
    handlePotentialImpactFromPotential: (state, action) => { state.potentialImpactFromPotential.potentialImpactFromPotentialdata = action.payload },
    
    handlePotentialImpactFromPotentialStageid: (state, action) => { state.potentialImpactFromPotential.stageid = action.payload },
    
    handleProjectScopeIds: (state, action) => { state.projectScopeIds = action.payload },

    handlerefresh : (state) => { state.refreshpage = !state.refreshpage },
    
    handleProjectScopeIdsfromScopingToPotential: (state, action) => { state.ProjectScopeIdsfromScopingToPotential = action.payload },
    
    handleSelectedStrategy : (state, action) => { state.selectedStrategy = action.payload },

    handleSelectedPIE : (state, action) => { state.selectedPIE = action.payload }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookmarks.fulfilled, (state, action) => {
        state.suggestions = action.payload.data
        state.bookmarks = action.payload.bookmarks
      })
      .addCase(updateBookmarked.fulfilled, (state, action) => {
        let objectToUpdate

        // ** find & update object
        state.suggestions.find((item) => {
          if (item.id === action.payload) {
            item.isBookmarked = !item.isBookmarked
            objectToUpdate = item
          }
        })

        // ** Get index to add or remove bookmark from array
        const bookmarkIndex = state.bookmarks.findIndex(
          (x) => x.id === action.payload
        )
        if (bookmarkIndex === -1) {
          state.bookmarks.push(objectToUpdate)
        } else {
          state.bookmarks.splice(bookmarkIndex, 1)
        }
      })
  }
})

export const { handlePotentialImpactFromPotential, handleProjectPotentialImpactfromUpadateStrtegypage, handleProjectScopeIdsfromScopingToPotential, handleProjectManagementStrategyRefreshPage, handleProjectActiondeleteConfirmRefreshPage, handleProjectActionUserStatus, handleProjectActiondeleteConfirmYes, handleProjectScopeIds, handleProjectActiondeleteConfirmNo, handleDeleteConfirmNo, handleDeleteConfirmYes, handleDeleteConfirm, handlePotentialImpactFromPotentialStageid, handlerefresh, handleSelectedStrategy, handleSelectedPIE } = managementSlice.actions
export default managementSlice.reducer
