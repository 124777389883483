import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { CSVLink } from 'react-csv'
import { useSelector } from 'react-redux'
import { DropdownItem, Spinner } from 'reactstrap'

const ExcelReport = ({ selected }) => {
  const propsFromViewProject = useSelector(state => state.managementStrategy.selectedStrategy)
  const [excelData, setExcelData] = useState([])
  console.log(propsFromViewProject, excelData)
  const [isLoading, setIsLoading] = useState(false)


  useEffect(() => {
    setIsLoading(true)
    axios.post(`${process.env.REACT_APP_BASENAME}/getImpactDataforExcel`, { selectedId: selected }).then((res) => {
      setExcelData(res.data.data)
      setIsLoading(false)
    })
  }, [])

  const headers = [
    { label: "Management Strategy", key: "Management_Strategy" },
    { label: "Description", key: "Description" },
    { label: "Type", key: "Type" },
    { label: "Relevant pottential impact events", key: "Relevant_pottential_impact_events" }
  ]

  const data = excelData.map((strategydata) => {
    return {
      Management_Strategy: strategydata.title,
      Description: strategydata.description,
      Type: strategydata.type,
      Relevant_pottential_impact_events: `${strategydata.scopes?.map((item) => item)}`
    }
  })

  const event_id = excelData.map((event) => `${event.scopes?.map((item) => item)}`)
  event_id.filter((ele) => ele !== '')
  // const  data = [
  //     { Management_Strategy: "Ahmed", Description: "Tomi", email: "ah@smthing.co.com" },
  //     { firstname: "Raed", lastname: "Labes", email: "rl@smthing.co.com" },
  //     { firstname: "Yezzi", lastname: "Min l3b", email: "ymin@cocococo.com" }
  //   ]

  return (
    <DropdownItem className='w-100' >
      {isLoading === true ? <Spinner size='sm' /> : <CSVLink filename = {`management_strategy_[${event_id.length === 0 ? '' : event_id}]`} data={data} headers={headers}>
        Excel Download
      </CSVLink>}
      </DropdownItem>
  )
}

export default ExcelReport
