import { lazy } from 'react'
import ManagementStrategy from '../../views/managementStrategy/ManagementStrategy'
import CustomizeProject from '../../views/CustomiseProject/CustomizeProject'
import Dashboard from '../../views/pages/dashboard/Chart/Dashboard'
import ProjectDetails from '../../views/ProjectDetails/ProjectDetails'
import Scoping from '../../views/ProjectScope/Scoping'
import ViewAction from '../../views/actions/ViewActions'
import Permissions from '../../views/Permissions/Permissions'
import UserDetails from '../../views/UserDetails/UserDetails'
import PdfReport from '../../views/Reports/PdfReport'
import ImpactSignificance from '../../views/ImpactSignificance/ImpactSignificance'
import AdminConsole from '../../views/AdministrationConsole/AdminConsole'
//import PrintReport from '../../views/ProjectScope/Print'
const Print = lazy(() => import('../../views/ProjectScope/PrintPage'))
const Home = lazy(() => import('../../views/pages/dashboard/Home/Home'))
const CreateProject = lazy(() => import('../../views/CreateProject/CreateProject'))
//const ProjectDetails = lazy(() => import('../../views/ProjectDetails/NewProjectDetails'))
const DashboardRoutes = [
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/create-project',
    element: <CreateProject />
  },
  {
    path: '/admin-console',
    element: <AdminConsole/>
  },
  {
    path:'/userdetails/userId',
    element: <UserDetails/>
  },
  {
    path: '/project-details/:projectId',
    //element: <NewProjectDetails/>
    element: <ProjectDetails/>
  },
  {
    path:'/dashboard/:projectId',
    element: <Dashboard/>
  },
  {
    path:'/customise/:projectId',
    element: <CustomizeProject/>
  },
  {
    path:'/impact-event/:projectId',
    element: <Scoping/>
  },
  {
    path:'/manage-strategy/:projectId',
    element: <ManagementStrategy/>
  },
  {
    path:'/action/:projectId',
    element: <ViewAction/>
  },
  {
    path:'/permissions/:projectId',
    element: <Permissions/>
  },
  {
    path:'/impact-signficance/:projectId',
    element:<ImpactSignificance/>
  },
  {
    path: '/print',
     element: <Print/>,
     meta: {
      layout: 'blank'
    }
  }

]

export default DashboardRoutes
