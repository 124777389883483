import axios from "axios"
import { Check, Trash, Eye, Edit2, Calendar } from 'react-feather'
import Avatar from '@components/avatar'
import Flatpickr from 'react-flatpickr'
import Select from 'react-select'
import { selectThemeColors } from '@utils'
import { ModalHeader, ModalBody, ModalFooter, Form, Button, Modal, Input, Row, Col, Spinner, InputGroup, InputGroupText } from 'reactstrap'
import toast from 'react-hot-toast'
import React, { Fragment, useState } from 'react'
import { handleManagementStrategy, handleCostumiseStagesModal } from '../../redux/navbar'
import { useDispatch, useSelector } from 'react-redux'
import moment from "moment"
import { handleProjectActiondeleteConfirmRefreshPage } from "../../redux/managementStrategy"
import PotentialImpactEvent from "../managementStrategy/PotentialImpactEvent"

const EditActions = (propsFromViewProject) => {
    console.log('propsFromViewProject', propsFromViewProject)
    const [selectedFullName, SetSelectedFullName] = useState(propsFromViewProject.mapDataForEditDelete.stakeHolder)
    const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
    const [picker, setPicker] = useState(propsFromViewProject.mapDataForEditDelete.startDate)
    const [endDatepicker, setEndDatePicker] = useState(propsFromViewProject.mapDataForEditDelete.endDate)
    const statusFromPermission = useSelector(state => state.managementStrategy.projectActionUserStatus)
    const dataFromPotentialImpactq = useSelector(state => state.managementStrategy.potentialImpactFromPotential)
    const [error, setError] = useState()
    const [editActionData, setEditActionData] = useState({
        subject: propsFromViewProject.mapDataForEditDelete.subject,
        description: propsFromViewProject.mapDataForEditDelete.description,
        selectInput: ""
    })
    const databaseUserId = localStorage.getItem('loggedInUserId')
    const [isLoading, setIsLoading] = useState(false)
    const dispatch = useDispatch()
    const [addScoping, setaddScoping] = useState(false)
    const [subjectDataValidation, setSubjectDataValidation] = useState("1")
    const [descriptionValidation, setDescriptionValidation] = useState("1")
    const [startDateValidation, setStartDateValidation] = useState("1")
    const [endDateValidation, setEndDateValidation] = useState("1")
    const [fullNameValidation, setFullName] = useState("1")
    const [listOfUsers] = useState([])
    const [confirmationModal, setConfirmationModal] = useState(false)
    const [completedModal, setCompletedModal] = useState(false)

    const d = (e) => {
        setSubjectDataValidation("1")
        setDescriptionValidation("1")
        setEditActionData({ ...editActionData, [e.target.name]: e.target.value.trim() })
        setError(1)
    }

    console.log('dataFromPotentialImpactq', dataFromPotentialImpactq)
    //Tosty Message component
    const Tosty = (msg, type) => {
        toast(
            <Row className='demo-vertical-spacing'>
                <div className='d-flex'><div className='me-1'>{type === 'Error' ? <Avatar size='sm' color='danger' icon={<Check size={12} />} /> : <Avatar size='sm' color='success' icon={<Check size={12} />} />}</div>
                    <div className='d-flex flex-column'><h6>{msg}</h6></div>
                </div>
            </Row>)
    }

    //list of users from Permission tab whoes status is Active
    listOfUsers.length = 0
    const isArray = (a) => {
        return (!!a) && (a.constructor === Array)
    }
    if (statusFromPermission.length !== 0 && isArray(statusFromPermission) === true) {
        statusFromPermission?.map(ele => {
            if (ele.status === "Active") {
                const check = listOfUsers.indexOf(ele.fullName)
                if (check === -1) {
                    listOfUsers.push({ value: ele.fullName, label: ele.fullName })
                }
            }
        })
    }

    // handle onchange of Users Dropdown
    const handleChangeUser = (data) => {
        SetSelectedFullName("")
        if (data !== null) {
            SetSelectedFullName(data.value)
            setFullName("1")
        }
    }

    // to Edit Exisiting Action
    const EditAction = () => {
        setIsLoading(true)
        const ArrayPotentialScopeId = dataFromPotentialImpactq.potentialImpactFromPotentialdata
        const postDataFrommadal = { projectActionId: propsFromViewProject.mapDataForEditDelete._id, projectId: propsFromViewProject.mapDataForEditDelete.projectId, description: editActionData.description, subject: editActionData.subject, userId: databaseUserId, startDate: picker, endDate: endDatepicker, fullName: selectedFullName, scopes: ArrayPotentialScopeId }
        console.log('postDataFrommadal', postDataFrommadal)
        if (editActionData.subject.length !== 0 && editActionData.description.length !== 0 && databaseUserId.length !== 0 && picker !== [] && endDatepicker !== [] && selectedFullName !== undefined && selectedFullName.length !== 0) {
            axios.post(`${process.env.REACT_APP_BASENAME}/updeteprojectaction`, postDataFrommadal).then((res) => {
                dispatch(handleProjectActiondeleteConfirmRefreshPage(res.data))
                dispatch(handleManagementStrategy(res.data))
                setIsLoading(false)
                if (res.data.message) {
                    Tosty(res.data.message, 'Data')
                    return setaddScoping(!addScoping)
                }
            })
            // return setaddScoping(!addScoping)
        } else {
            setSubjectDataValidation(editActionData.subject.length !== 0 ? "1" : "")
            setDescriptionValidation(editActionData.description.length !== 0 ? "1" : "")
            setStartDateValidation(picker !== [] ? "1" : "")
            setEndDateValidation(endDatepicker !== [] ? "1" : "")
            setFullName(selectedFullName !== "" ? "1" : "")
            setIsLoading(false)
        }
        dispatch(handleCostumiseStagesModal("projectScope"))
    }

    //To delete Action
    const onConfirmDelete = () => {
        setIsLoading(true)
        axios.post(`${process.env.REACT_APP_BASENAME}/deleteprojectaction`, { selectIds: propsFromViewProject.mapDataForEditDelete._id, userId: databaseUserId })
            .then((res) => {
                setIsLoading(false)
                dispatch(handleProjectActiondeleteConfirmRefreshPage(res.data))
                if (res.data.data) {
                    setConfirmationModal(!confirmationModal)
                    Tosty('Action Deleted.', "Data")
                }
            })
    }

    //To update completed action Yes / No
    const ActionConfirmation = (msg) => {
        setIsLoading(true)
        const postDataFrommadal = { projectActionId: propsFromViewProject.mapDataForEditDelete._id, projectId: propsFromViewProject.mapDataForEditDelete.projectId, completedStatus: msg }
        axios.post(`${process.env.REACT_APP_BASENAME}/updeteprojectactionstatus`, postDataFrommadal).then((res) => {
            dispatch(handleProjectActiondeleteConfirmRefreshPage(res.data))
            dispatch(handleManagementStrategy(res.data.Project))
            if (res.data.data) {
                setIsLoading(false)
                setCompletedModal(!completedModal)
                Tosty(res.data.message, 'Data')
            }
        })
    }

    // cancel Handler to empty state and ErrorState
    const cancelHandler = () => {
        setIsLoading(false)
        setSubjectDataValidation('1')
        setDescriptionValidation('1')
        setStartDateValidation('1')
        setEndDateValidation('1')
        setFullName('1')
        setaddScoping(!addScoping)
    }

    const defaultValueSelect = [{ value: propsFromViewProject.mapDataForEditDelete.stakeHolder, label: propsFromViewProject.mapDataForEditDelete.stakeHolder }]
    return (
        <Fragment>
            {(LoginUserRole === 'Editor') ? (<><Edit2 color='#6e6b7b' className='cursor-pointer' size={17} onClick={() => setaddScoping(!addScoping)} />
                <Trash className='cursor-pointer ms-1' color='#6e6b7b' size={17} onClick={() => setConfirmationModal(!confirmationModal)} />
                <Check className='cursor-pointer ms-1' color='#6e6b7b' size={17} onClick={() => setCompletedModal(!completedModal)} /></>) : <Eye className='cursor-pointer' size={20} onClick={() => setaddScoping(!addScoping)} />}
            <div >
                <Modal backdrop='static' isOpen={addScoping} toggle={cancelHandler} className='modal-dialog-centered modal-lg '>
                    <ModalHeader toggle={cancelHandler}></ModalHeader>
                    <h4 className="text-center mb-2 mt-1">{(LoginUserRole === 'Viewer') ? 'View Action' : 'Edit Existing Action'}</h4>
                    <ModalBody><Form onSubmit={(e) => e.preventDefault()}>
                        <Row>
                            <Col>
                                {propsFromViewProject.mapDataForEditDelete.subject ? <label label> Subject </label> : <></>}
                                <Input name='subject' style={{marginTop:'5px'}} defaultValue={propsFromViewProject.mapDataForEditDelete.subject} disabled={(LoginUserRole === 'Viewer')} onChange={d} placeholder='Subject' />
                                {
                                    subjectDataValidation.length === 0 ? <span style={{ color: "red" }}>Enter Subject</span> : " "}

                                {propsFromViewProject.mapDataForEditDelete.description ? <label className="mt-1" > Description </label> : <></>}
                                <Input defaultValue={propsFromViewProject.mapDataForEditDelete.description} disabled={(LoginUserRole === 'Viewer')} style={{ width: "100%", marginTop:'5px' }} type='textarea' onChange={d} name='description' placeholder='Description' />
                                {descriptionValidation.length === 0 ? <span style={{ color: "red" }}>Enter Description</span> : " "}
                                <Row className=" mt-2">

                                    {(LoginUserRole === 'Viewer') ? <Col className="mt-1" md='4' xl='4' >

                                        <Input disabled="true" defaultValue={(moment(new Date(propsFromViewProject.mapDataForEditDelete.startDate)).format('ll'))} />

                                        {startDateValidation.length === 0 ? <span style={{ color: "red" }}>Select Start Date</span> : " "}</Col > : <Col md='4' xl='4' className="mt-0.5">
                                        {propsFromViewProject.mapDataForEditDelete.startDate ? <label label > Start Date </label> : <></>}
                                        <Flatpickr style={{marginTop:'5px'}} className='form-control bg-white ' options={{ dateFormat: 'd-m-Y' }} defaultValue={propsFromViewProject.mapDataForEditDelete.startDate} onChange={date => setPicker(date)} placeholder="Start Date" />
                                        {startDateValidation.length === 0 ? <span style={{ color: "red" }}>Select Start Date</span> : " "}
                                    </Col>}
                                    {(LoginUserRole === 'Viewer') ? <Col md='4' xl='4' className="mt-1">

                                        <Input disabled="true" defaultValue={(moment(new Date(propsFromViewProject.mapDataForEditDelete.endDate)).format('ll'))} />
                                        {endDateValidation.length === 0 ? <span style={{ color: "red" }}>Select End Date</span> : " "}
                                    </Col> : <Col md='4' xl='4' >
                                        {propsFromViewProject.mapDataForEditDelete.endDate ? <label label> End Date </label> : <></>}
                                        <Flatpickr style={{marginTop:'5px'}} id='date-picker' disabled={(LoginUserRole === 'Viewer')} className=' form-control bg-white' options={{ dateFormat: 'd-m-Y' }} defaultValue={propsFromViewProject.mapDataForEditDelete.endDate} onChange={date => setEndDatePicker(date)} placeholder="End Date" />
                                        {endDateValidation.length === 0 ? <span style={{ color: "red" }}>Select End Date</span> : " "}
                                    </Col>
                                    }
                                    {(LoginUserRole === 'Viewer') ? <Col md='4' xl='4' className="mt-1"> <Input disabled="true" defaultValue={propsFromViewProject.mapDataForEditDelete.stakeHolder} /> </Col> : <Col md='4' xl='4'>
                                        {defaultValueSelect ? <label style={{marginBottom:'5px'}} label> User list </label> : <></>}
                                        <Select disabled={(LoginUserRole === 'Viewer')} onChange={handleChangeUser} theme={selectThemeColors} placeholder='User List' className='react-select' classNamePrefix='select' defaultValue={defaultValueSelect} maxMenuHeight={200} name='clear' options={listOfUsers} isClearable />
                                        {fullNameValidation.length === 0 ? <span style={{ color: "red" }}>Select User</span> : " "}
                                    </Col>}
                                </Row>
                                {error === 0 ? <span style={{ color: "red" }}>Enter User</span> : " "}
                            </Col>
                        </Row>
                        {propsFromViewProject.editActionData?.scope ? <label label className="mt-1 mb-1"> Subject </label> : <></>}
                        {addScoping ? <PotentialImpactEvent For='Action' scopeid={propsFromViewProject.mapDataForEditDelete} /> : ''}
                    </Form>

                    </ModalBody>
                    <ModalFooter className="modal-footer justify-content-center">
                        {(LoginUserRole === 'Editor') ? (<><Button color="primary" onClick={EditAction}>{isLoading === true ? <Spinner size='sm' /> : "UPDATE"}</Button>
                            <Button onClick={cancelHandler}> CANCEL</Button></>) : ''}
                    </ModalFooter>
                </Modal>
            </div>
            {/* Confiramtion Modal */}
            <div className='demo-inline-spacing'>
                <div className='vertically-centered-modal'>
                    <Modal backdrop='static' isOpen={confirmationModal} toggle={() => setConfirmationModal(!confirmationModal)} className='modal-dialog-centered'>
                        <ModalHeader toggle={() => setConfirmationModal(!confirmationModal)}>Confirmation </ModalHeader>
                        <ModalBody> Do you want to delete this record ? </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type='submit' onClick={onConfirmDelete}>{isLoading === true ? <Spinner size='sm' /> : 'YES'} </Button>
                            <Button className="offset-2" color="secondary" onClick={() => setConfirmationModal(!confirmationModal)}> NO</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
            {/* Action Completed Confirmation Modal */}
            <div className='demo-inline-spacing'>
                <div className='vertically-centered-modal'>
                    <Modal backdrop='static' isOpen={completedModal} toggle={() => setCompletedModal(!completedModal)} className='modal-dialog-centered'>
                        <ModalHeader toggle={() => setCompletedModal(!completedModal)}>Action Completed Confirmation </ModalHeader>
                        <ModalBody>{propsFromViewProject.mapDataForEditDelete.description}
                            <Row>
                                <div className="text-center mt-1">
                                    {propsFromViewProject.mapDataForEditDelete.completedStatus === "Yes" ? <Button.Ripple color='primary' value="No" className="ms-1" onClick={() => setCompletedModal(!completedModal)}>
                                        {isLoading === true ? <Spinner color="primary" size='sm' /> : ''}  Yes
                                    </Button.Ripple> : <Button.Ripple outline color='primary' value="No" className="ms-1" onClick={() => ActionConfirmation('Yes')}>
                                        {isLoading === true ? <Spinner color="primary" size='sm' /> : ''}  Yes
                                    </Button.Ripple>}
                                    {propsFromViewProject.mapDataForEditDelete.completedStatus === "No" ? <Button.Ripple color='primary' value="No" className="ms-1" onClick={() => setCompletedModal(!completedModal)}>
                                        {isLoading === true ? <Spinner color="primary" size='sm' /> : ''}  No
                                    </Button.Ripple> : <Button.Ripple outline color='primary' value="No" className="ms-1" onClick={() => ActionConfirmation('No')}>
                                        {isLoading === true ? <Spinner color="primary" size='sm' /> : ''} No
                                    </Button.Ripple>}
                                </div>
                            </Row >
                        </ModalBody>
                    </Modal>
                </div>
            </div>
        </ Fragment>
    )
}

export default EditActions