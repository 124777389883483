import React, { useState, useEffect } from 'react'
import { Document, Packer, PageSizes, PageOrientation, Paragraph, SectionType, Table, TableCell, TableRow, TextRun, WidthType, PageSize } from "docx"
import { saveAs } from 'file-saver'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { DropdownItem, Spinner } from 'reactstrap'
import { getCountry } from 'country-state-picker'

const WordReport = () => {
    const report_type = useSelector((state) => state.navbar.report_type)
    const selectedProjectId = useSelector(state => state.navbar.printReport)
    const [data, setData] = useState([])
    const [relavaneManagementStratagy, setRelavaneManagementStratagy] = useState([])
    const [actionData, setActiondata] = useState([])
    const projectId = useSelector((state) => state.navbar.projectId)
    const [projectData, setProjectData] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [impactData, setImpactData] = useState([])

    let doc = ''
    const generateFile = () => {
        Packer.toBlob(doc).then((blob) => {
            saveAs(blob, `${report_type}.docx`)
        })
    }

    useEffect(() => {
        setIsLoading(true)
        switch (report_type) {
            case 'Project summary':
                axios.post(`${process.env.REACT_APP_BASENAME}/getprojectdetails`, { projectId }).then((res) => {
                    if (res.data.data) {
                        setProjectData(res.data.data?.project)
                        setIsLoading(false)
                    }
                })
                break
            case 'Scoping report':
                axios.post(`${process.env.REACT_APP_BASENAME}/printscopingreport`, { projectScopeIds: selectedProjectId })
                    .then((res) => {
                        setData(res.data.data)
                        setIsLoading(false)
                    })

                axios.post(`${process.env.REACT_APP_BASENAME}/getprojectstrategies`, { projectScopeId: selectedProjectId })
                    .then((res) => {
                        setRelavaneManagementStratagy(res.data.data)
                    })

                axios.post(`${process.env.REACT_APP_BASENAME}/getprojectactionsforreport`, { projectScopeId: selectedProjectId })
                .then((res) => {
                    if (res.data.data) {
                        setActiondata(res.data.data)
                    }
                })
                break
            case 'Impact assessment report':
                axios.post(`${process.env.REACT_APP_BASENAME}/printscopingreport`, { projectScopeIds: selectedProjectId })
                    .then((res) => {
                        if (res.data.data) {
                            setData(res.data.data)
                        }
                    })

                axios.post(`${process.env.REACT_APP_BASENAME}/getprojectstrategies`, { projectScopeId: selectedProjectId })
                    .then((res) => {
                        if (res.data.data) {
                            setRelavaneManagementStratagy(res.data.data)
                        }
                    })

                axios.post(`${process.env.REACT_APP_BASENAME}/getimpactassesementdata`, { projectScopeId: selectedProjectId })
                    .then((res) => {
                        if (res.data.data) {
                            setImpactData(res.data.data)
                            setIsLoading(false)
                        }
                    })

                axios.post(`${process.env.REACT_APP_BASENAME}/getprojectactionsforreport`, { projectScopeId: selectedProjectId })
                    .then((res) => {
                        if (res.data.data) {
                            setActiondata(res.data.data)
                        }
                    })
        }

    }, [projectId])
    console.log('impactData', impactData)
    switch (report_type) {
        case 'Project summary':
            <>
                {Object.keys(projectData).length > 0 ? doc = new Document({
                    sections: [
                        {
                            children: [
                                new Table({

                                    width: {
                                        size: '100%',
                                        type: WidthType.DXA
                                    },
                                    margins: {
                                        left: 110,
                                        bottom: 50,
                                        top: 50,
                                        right: 110
                                    },
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({
                                                                    text: "Project Summary",
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    columnSpan: 3,
                                                    shading: {
                                                        fill: '#4e7ff2'
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Project name")],
                                                    shading: {
                                                        fill: '#79C585'
                                                    },
                                                    width: {
                                                        size: '20%'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${projectData?.projectName}`)]
                                                    // width:{
                                                    //     type:WidthType.DXA,
                                                    //     size:'20%'
                                                    // }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Type of project")],
                                                    shading: {
                                                        fill: '#79C585'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${projectData?.projectTypeId}`)]
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Project description")],
                                                    shading: {
                                                        fill: '#79C585'
                                                    }

                                                }),
                                                new TableCell({
                                                    children: projectData?.description.split('\n').map(t => new Paragraph(t))
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Country")],
                                                    shading: {
                                                        fill: '#79C585'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${getCountry(projectData?.countryId).name}`)]
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("State/Province")],
                                                    shading: {
                                                        fill: '#79C585'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${projectData?.stateId}`)]
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Project coordinates")],
                                                    shading: {
                                                        fill: '#79C585'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${projectData?.location}`)]
                                                })
                                            ]
                                        })
                                    ]
                                })
                            ]
                        }
                    ]
                }) : ''}
            </>
            return <DropdownItem className='w-100' onClick={generateFile} end> {isLoading === true ? <Spinner size='sm' /> : 'Word'}</DropdownItem>
            break
        case 'Scoping report':
            doc = new Document({
                sections: data.map((projectScope) => {
                    return {
                        properties: {
                            page: {
                                size: {
                                    width: PageSize.A3,
                                    height: PageSize.A3,
                                    orientation: PageOrientation.LANDSCAPE
                                }
                            }
                        },
                        children: [
                            new Table({
                                width: {
                                    size: '100%',
                                    type: WidthType.DXA
                                },

                                margins: {
                                    left: 110,
                                    bottom: 50,
                                    top: 50,
                                    right: 110
                                },
                                rows: [
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: 'ID',
                                                                bold: true
                                                            })
                                                        ]
                                                    })
                                                ],
                                                shading: {
                                                    fill: '#4e7ff2'
                                                },
                                                columnSpan: 1.5
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: `${projectScope.scopeId}`,
                                                                bold: true
                                                            })
                                                        ]
                                                    })
                                                ]
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: 'Stage',
                                                                bold: true
                                                            })
                                                        ]
                                                    })
                                                ],
                                                shading: {
                                                    fill: '#4e7ff2'
                                                }
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: `${projectScope.stage}`,
                                                                bold: true
                                                            })
                                                        ]
                                                    })
                                                ]
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: 'Potential impact event description',
                                                                bold: true
                                                            })
                                                        ]
                                                    })
                                                ],
                                                shading: {
                                                    fill: '#4e7ff2'
                                                }
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph({
                                                        children: [
                                                            new TextRun({
                                                                text: `${projectScope.potentialImpactDesciption}`,
                                                                bold: true
                                                            })
                                                        ]
                                                    })
                                                ]
                                            })
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph("Source")],
                                                shading: {
                                                    fill: '#79C585'
                                                }
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(`${projectScope?.source}`)]
                                            }),
                                            new TableCell({
                                                children: [new Paragraph("Source description")],
                                                shading: {
                                                    fill: '#79C585'
                                                }
                                            }),
                                            new TableCell({
                                                children: projectScope?.sourceDescription.split('\n').map(t => new Paragraph(t)),
                                                columnSpan: 3
                                            })
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph("Pathway")],
                                                shading: {
                                                    fill: '#79C585'
                                                }
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(`${projectScope.pathway}`)]
                                            }),
                                            new TableCell({
                                                children: [new Paragraph("Pathway description")],
                                                shading: {
                                                    fill: '#79C585'
                                                }
                                            }),
                                            new TableCell({
                                                children: projectScope?.pathwayDescription.split('\n').map(t => new Paragraph(t)),
                                                columnSpan: 3
                                            })
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph("Receptor")],
                                                shading: {
                                                    fill: '#79C585'
                                                }
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(`${projectScope.receptor}`)]
                                            }),
                                            new TableCell({
                                                children: [new Paragraph("Receptor description")],
                                                shading: {
                                                    fill: '#79C585'
                                                }
                                            }),
                                            new TableCell({
                                                children: projectScope?.receptorDescription.split('\n').map(t => new Paragraph(t)),
                                                columnSpan: 3
                                            })
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph("Confirmation of linkage")],
                                                shading: {
                                                    fill: '#79C585'
                                                }
                                            }),
                                            new TableCell({
                                                children: [new Paragraph(`${projectScope.linkage}`)]
                                            }),
                                            new TableCell({
                                                children: [new Paragraph("Linkage justification")],
                                                shading: {
                                                    fill: '#79C585'
                                                }
                                            }),
                                            new TableCell({
                                                children: projectScope?.linkageDescription.split('\n').map(t => new Paragraph(t)),
                                                columnSpan: 3
                                            })
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph("Actions (Forward Works Plan)")],
                                                shading: {
                                                    fill: '#79C585'
                                                }
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph(`${actionData[projectScope.scopeId]?.length === 0 ? '-' : actionData[projectScope.scopeId]?.map((action) => {
                                                        return `${action.subject}`
                                                    })
                                                        }`)
                                                ]
                                            }),
                                            new TableCell({
                                                children: [new Paragraph("Relevant Guidelines, Standards or Legislation")],
                                                shading: {
                                                    fill: '#79C585'
                                                }
                                            }),
                                            new TableCell({
                                                children: projectScope?.relevantLegislation.split('\n').map(t => new Paragraph(t)),
                                                columnSpan: 3
                                            })
                                        ]
                                    }),
                                    new TableRow({
                                        children: [
                                            new TableCell({
                                                children: [new Paragraph("Relevant Management Strategies and role")],
                                                shading: {
                                                    fill: '#79C585'
                                                }
                                            }),
                                            new TableCell({
                                                children: [
                                                    new Paragraph(`${relavaneManagementStratagy[projectScope.scopeId]?.length === 0 ? '-' : relavaneManagementStratagy[projectScope.scopeId]?.map((strategy) => {
                                                        return `${strategy.title}`
                                                    })}`)
                                                ],
                                                columnSpan: 6
                                            })
                                        ]
                                    })
                                ]
                            })
                        ]
                    }
                })
            })
            return <DropdownItem className='w-100' onClick={generateFile} end> {isLoading === true ? <Spinner size='sm' /> : 'Word'}</DropdownItem>
            break
        case 'Impact assessment report':
            doc = new Document({
                sections: data?.map((projectScope) => {
                    if (projectScope?.linkage === 'Yes') {
                       
                        let filtered_managementstrategy = impactData?.management_strategy?.filter((event_strategy) => event_strategy?.scopes?.includes(projectScope.scopeId))

                        if (!filtered_managementstrategy) {
                          filtered_managementstrategy = []
                        }

                        console.log('filtered_managementstrategy', impactData?.stakeholder_data?.filter((statement_data) => statement_data.impact_event_id === projectScope._id).map((statement) => {
                            return (statement?.stakeholder_engagement)
                        }) ?? [])
                         
                        return {
                            properties: {
                                type: SectionType.NEXT_PAGE,
                                page: {
                                    size: {
                                        width: PageSize.A3,
                                        height: PageSize.A3,
                                        orientation: PageOrientation.LANDSCAPE
                                    }
                                }
                            },
                            children: [
                                new Table({
                                    width: {
                                        size: '100%',
                                        type: WidthType.DXA
                                    },
                                    margins: {
                                        left: 110,
                                        bottom: 50,
                                        top: 50,
                                        right: 110
                                    },
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({
                                                                    text: 'ID',
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    shading: {
                                                        fill: '#4e7ff2'
                                                    }

                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({
                                                                    text: `${projectScope.scopeId}`,
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ]
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({
                                                                    text: 'Stage',
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    shading: {
                                                        fill: '#4e7ff2'
                                                    }

                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({
                                                                    text: `${projectScope.stage}`,
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    columnSpan: 1
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({
                                                                    text: "Potential impact event description",
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    shading: {
                                                        fill: '#4e7ff2'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({
                                                                    text: `${projectScope.potentialImpactDesciption}`,
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ]
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Source")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${projectScope?.source}`)]
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Source description")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: projectScope?.sourceDescription.split('\n').map(t => new Paragraph(t)),
                                                    columnSpan: 3
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Pathway")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${projectScope.pathway}`)]
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Pathway description")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: projectScope?.pathwayDescription.split('\n').map(t => new Paragraph(t)),
                                                    columnSpan: 3
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Receptor")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${projectScope.receptor}`)]
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Receptor description")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children:  projectScope?.receptorDescription.split('\n').map(t => new Paragraph(t)),
                                                    columnSpan: 3
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Confirmation of linkage")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${projectScope.linkage}`)]
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Linkage justification ")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: projectScope?.linkageDescription.split('\n').map(t => new Paragraph(t)),
                                                    columnSpan: 3
                                                })

                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Potential Impact Event Uncertainty and Sensitivity Assessment")],
                                                    shading: {
                                                        fill: '#79C585'
                                                    },
                                                    columnSpan: 6
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Level of certainty of the Source, Pathway or Receptor")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: 
                                                       impactData?.certainty_data?.find((item) => item.linkageId === projectScope._id)?.level.split('\n')?.map((certainty) => {
                                                            return new Paragraph(certainty)
                                                        }) ?? []
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Level of certainty of the Source, Pathway or Receptor Description")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: 
                                                       impactData?.certainty_data?.find((item) => item.linkageId === projectScope._id)?.levelDescription.split('\n').map((certainty) => {
                                                            return new Paragraph(certainty)
                                                        }) ?? [],
                                                    columnSpan: 3
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Sensitivity to change in assumptions of the Source, Pathway and Receptor")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: 
                                                        impactData?.certainty_data?.find((item) => item.linkageId === projectScope._id)?.assumptions.split('\n').map((certainty) => {
                                                            return new Paragraph(certainty)
                                                        }) ?? []
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Sensitivity to change in assumptions of the Source, Pathway and Receptor description")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: 
                                                       impactData?.certainty_data?.find((item) => item.linkageId === projectScope._id)?.assumptionDesc.split('\n').map((certainty) => {
                                                            return new Paragraph(certainty)
                                                        }) ?? [],
                                                    columnSpan: 3
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Actions (Forward Works Plan)")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph(`${actionData[projectScope.scopeId]?.length === 0 ? '-' : actionData[projectScope.scopeId]?.map((action) => {
                                                            return `${action.subject}`
                                                        })
                                                            }`)
                                                    ],
                                                    columnSpan: 1
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph('Relevant Guidelines, Standards or Legislation')],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${projectScope.relevantLegislation}`)],
                                                    columnSpan: 3
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Impact Assessment")],
                                                    shading: {
                                                        fill: '#79C585'
                                                    },
                                                    columnSpan: 6
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Raw Risk/Impact (Pre-Management Strategies)")],
                                                    columnSpan: 7,
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Likelihood")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: 
                                                      impactData?.rawrisk_data?.find((item) => item.impact_event_id === projectScope._id)?.likelihood.split('\n').map((rawrisk) => {
                                                            return new Paragraph(rawrisk)
                                                        }) ?? []
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Impact significance")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children:  
                                                    impactData?.rawrisk_data?.find((item) => item.impact_event_id === projectScope._id)?.significance.split('\n').map((rawrisk) => {
                                                            return new Paragraph(rawrisk)
                                                        }) ?? []
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Rating")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: 
                                                       impactData?.rawrisk_data?.find((item) => item.impact_event_id === projectScope._id)?.impact_significance.split('\n').map((rawrisk) => {
                                                            return new Paragraph(rawrisk)
                                                        }) ?? []
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Relevant Management Strategies and role")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph(`${relavaneManagementStratagy[projectScope.scopeId]?.length === 0 ? "-" : relavaneManagementStratagy[projectScope.scopeId]?.map((strategy) => {
                                                            return  `${strategy.title}`
                                                                })
                                                            }`)
                                                    ],
                                                    columnSpan: 5
                                                })

                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Raw Risk/Impact (Post-Management Strategies)")],
                                                    columnSpan: 7,
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Likelihood")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: 
                                                       impactData?.residual_risk?.find((item) => item.impact_event_id === projectScope._id)?.likelihood.split('\n').map((residual_risk) => {
                                                            return new Paragraph(residual_risk)
                                                        }) ?? []
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Impact significance")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: 
                                                       impactData?.residual_risk?.find((item) => item?.impact_event_id === projectScope?._id)?.consequence.split('\n').map((residual_risk) => {
                                                            return new Paragraph(residual_risk)
                                                        }) ?? []
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Rating")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: 
                                                      impactData?.residual_risk?.find((item) => item.impact_event_id === projectScope._id)?.impact_significance.split('\n').map((residual_risk) => {
                                                            return new Paragraph(residual_risk)
                                                        }) ?? []
                                                })
                                            ]
                                        }),
                                       
                                        //relevant management strategy
                                        
                                        // new TableRow({children: [    
                                        //     new TableCell({
                                        //         children:[new Paragraph("Level of certainty of Management Strategy effectiveness")],
                                        //         columnSpan: 1,
                                        //         shading: {
                                        //             fill: '#cbd1ce'
                                        //         }
                                        //     }),
                                        //     new TableCell({
                                        //         children: [ 
                                        //             new Paragraph({
                                        //             text: `strategy?.level`,
                                        //             bold: true
                                        //         })
                                        //     ]
                                        //     }),
                                        //     new TableCell({
                                        //         children: [new Paragraph("Level of certainty of Management Strategy effectiveness description")],
                                        //         columnSpan: 1,
                                        //         shading: {
                                        //             fill: '#cbd1ce'
                                        //         }
                                        //     }),
                                        //     new TableCell({
                                        //         children: [ 
                                        //             new Paragraph({
                                        //             text: `{strategy?.levelDescription}`,
                                        //             bold: true
                                        //         })
                                        //     ],
                                        //         columnSpan: 3
                                        //     })
                                        //   ]}),
                                          
                                        ...filtered_managementstrategy?.reduce((acc, strategy) => {
                                            //alert(`showing ${i}`)
                                            return acc.concat([
                                                new TableRow({
                                                    children: [
                                                        new TableCell({
                                                            children: [new Paragraph("Management Strategies Uncertainty and Sensitivity Assessment")],
                                                            columnSpan: 7,
                                                            shading: {
                                                                fill: '#79C585'
                                                            }
                                                        })
                                                    ]
                                                }),
                                                new TableRow({children: [    
                                                    new TableCell({
                                                        children:[new Paragraph("Level of certainty of Management Strategy effectiveness")],
                                                        columnSpan: 1,
                                                        shading: {
                                                            fill: '#cbd1ce'
                                                        }
                                                    }),
                                                    new TableCell({
                                                        children: [ 
                                                            new Paragraph({
                                                            text: `${strategy?.level}`,
                                                            bold: true
                                                        })
                                                    ]
                                                    }),
                                                    new TableCell({
                                                        children: [new Paragraph("Level of certainty of Management Strategy effectiveness description")],
                                                        columnSpan: 1,
                                                        shading: {
                                                            fill: '#cbd1ce'
                                                        }
                                                    }),
                                                    new TableCell({
                                                        children: strategy?.levelDescription.split('\n').map(t => new Paragraph(t)),
                                                        columnSpan: 3
                                                    })
                                                  ]}),
                                                  
                                                  new TableRow({
                                                    children: [
                                                        new TableCell({
                                                            children: [new Paragraph("Sensitivity to change of assumptions of the Control Measures")],
                                                            columnSpan: 1,
                                                            shading: {
                                                                fill: '#cbd1ce'
                                                            }
                                                        }),
                                                        new TableCell({
                                                            children: [
                                                                new Paragraph({
                                                                    text: `${strategy?.assumptions}`,
                                                                    bold: true
                                                                })
                                                            ]
                                                        }),
                                                        new TableCell({
                                                            children: [new Paragraph("Sensitivity to change of assumptions of the Control Measures description")],
                                                            columnSpan: 1,
                                                            shading: {
                                                                fill: '#cbd1ce'
                                                            }
                                                        }),
                                                        new TableCell({
                                                            children:strategy?.assumptionDesc.split('\n').map(t => new Paragraph(t)),
                                                            columnSpan: 3
                                                        })
                                                    ]
                                                })
                                            ]
                                            )
                                           }, []
                                        ),
                                       

                                        // new TableRow({
                                        //     children: [
                                        //         new TableCell({
                                        //             children: [new Paragraph("Sensitivity to change of assumptions of the Control Measures")],
                                        //             columnSpan: 1,
                                        //             shading: {
                                        //                 fill: '#cbd1ce'
                                        //             }
                                        //         }),
                                        //         new TableCell({
                                        //             children: []
                                        //         }),
                                        //         new TableCell({
                                        //             children: [new Paragraph("Sensitivity to change of assumptions of the Control Measures description")],
                                        //             columnSpan: 1,
                                        //             shading: {
                                        //                 fill: '#cbd1ce'
                                        //             }
                                        //         }),
                                        //         new TableCell({
                                        //             children: [],
                                        //             columnSpan: 3
                                        //         })
                                        //     ]
                                        // }),
                                        
                                        // outcome and monitoring
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Outcomes and Monitoring")],
                                                    columnSpan: 6,
                                                    shading: {
                                                        fill: '#79C585'
                                                    }
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Statement of the Environmental Outcomes that are expected to occur")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: 
                                                       impactData?.outcome_monitoring_data?.find((outcome_data) => outcome_data.impact_event_id === projectScope._id)?.env_outcome.split('\n').map((outcome) => {
                                                            return new Paragraph(outcome)
                                                        }) ?? [],
                                                          
                                                    columnSpan: 5
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Statement of the Criteria to be adopted to measure the Environmental Outcomes")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph(`${impactData?.outcome_monitoring_data?.filter((outcome_data) => outcome_data.impact_event_id === projectScope._id).map((outcome) => {
                                                            return `${outcome.measure_outcome.map((mea_out) => `${mea_out}`)}`
                                                        }) ?? []
                                                            }`)
                                                    ],
                                                    columnSpan: 5
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Is there a high reliance on Management Strategies to achieve an Environmental Outcome?")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph(`${impactData?.outcome_monitoring_data?.filter((outcome_data) => outcome_data.impact_event_id === projectScope._id).map((outcome) => {
                                                            return  `${outcome.lead_indicator}`
                                                        }) ?? []
                                                            }`)
                                                    ]
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Statement of Leading Indicator Criteria that will be used to give an early warning that a Control Measure may fail or be failing")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }

                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph(`${impactData?.outcome_monitoring_data?.filter((outcome_data) => outcome_data?.impact_event_id === projectScope?._id)?.map((outcome) => {
                                                            return `${outcome.statement_criteria?.map((mea_out) => `${mea_out}`)}`
                                                        }) ?? []
                                                            }`)
                                                    ],
                                                    columnSpan: 4
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Stakeholder engagement (stakeholder views of the Source, Pathway or Receptor and acceptability of impact level)")],
                                                    columnSpan: 7,
                                                    shading: {
                                                        fill: '#79C585'
                                                    }
                                                })

                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: 
                                                        impactData?.stakeholder_data?.find((statement_data) => statement_data.impact_event_id === projectScope._id)?.stakeholder_engagement.split('\n').map((statement) => {
                                                            return new Paragraph(statement)
                                                        }) ?? [],
                                                    columnSpan: 7
                                                })
                                            ]
                                        })
                                    ]
                                })
                            ]
                        }
                    } else if (projectScope?.linkage === 'No' || projectScope?.linkage === 'Unconfirmed') {
                        return {
                            properties: {
                                type: SectionType.NEXT_PAGE,
                                page: {
                                    size: {
                                        width: PageSize.A3,
                                        height: PageSize.A3,
                                        orientation: PageOrientation.LANDSCAPE
                                    }
                                }
                            },
                            children: [
                                new Table({
                                    width: {
                                        size: '100%',
                                        type: WidthType.DXA
                                    },
                                    margins: {
                                        left: 110,
                                        bottom: 50,
                                        top: 50,
                                        right: 110
                                    },
                                    rows: [
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({
                                                                    text: "ID",
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    shading: {
                                                        fill: '#4e7ff2'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({
                                                                    text: `${projectScope.scopeId}`,
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ]
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({
                                                                    text: "Stage",
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    shading: {
                                                        fill: '#4e7ff2'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({
                                                                    text: `${projectScope.stage}`,
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ]
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({
                                                                    text: "Potential Impact event description",
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ],
                                                    shading: {
                                                        fill: '#4e7ff2'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph({
                                                            children: [
                                                                new TextRun({
                                                                    text: `${projectScope.potentialImpactDesciption}`,
                                                                    bold: true
                                                                })
                                                            ]
                                                        })
                                                    ]
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Source")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${projectScope?.source}`)]
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Source description")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: projectScope?.sourceDescription.split('\n').map(t => new Paragraph(t)),
                                                    columnSpan: 3
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Pathway")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${projectScope.pathway}`)]
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Pathway description")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: projectScope?.pathwayDescription.split('\n').map(t => new Paragraph(t)),
                                                    columnSpan: 3
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Receptor")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${projectScope.receptor}`)]
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Receptor description")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: projectScope?.receptorDescription.split('\n').map(t => new Paragraph(t)),
                                                    columnSpan: 3
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Confirmation of linkage")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${projectScope.linkage}`)]
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Linkage justification")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: projectScope?.linkageDescription.split('\n').map(t => new Paragraph(t)),
                                                    columnSpan: 3
                                                })

                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Potential Impact Event Uncertainty and Sensitivity Assessment")],
                                                    shading: {
                                                        fill: '#79C585'
                                                    },
                                                    columnSpan: 6
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Level of certainty of the Source, Pathway or Receptor")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children:impactData?.certainty_data?.find((item) => item.linkageId === projectScope._id)?.level.split('\n').map((certainty) => {
                                                            return new Paragraph(certainty)
                                                        }) ?? []
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Level of certainty of the Source, Pathway or Receptor description")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: 
                                                        impactData?.certainty_data?.find((item) => item.linkageId === projectScope._id)?.levelDescription.split('\n').map((certainty) => {
                                                            return new Paragraph(certainty)
                                                        }) ?? [],
                                                    
                                                    
                                                    columnSpan: 3
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Sensitivity to change in assumptions of the Source, Pathway and Receptor")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: 
                                                        impactData?.certainty_data?.find((item) => item.linkageId === projectScope._id)?.assumptions.split('\n').map((certainty) => {
                                                            return new Paragraph(certainty)
                                                        }) ?? []
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph("Sensitivity to change in assumptions or the Source, Pathway and Receptor description")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: 
                                                        impactData?.certainty_data?.find((item) => item.linkageId === projectScope._id)?.assumptionDesc.split('\n')?.map((certainty) => {
                                                            return new Paragraph(certainty)
                                                        }) ?? [],
                                                    columnSpan: 3
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Actions (Forward Works Plan)")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph(`${actionData[projectScope.scopeId]?.length === 0 ? '-' : actionData[projectScope.scopeId]?.map((action) => {
                                                            return `${action.subject}`
                                                        })
                                                            }`)
                                                    ],
                                                    columnSpan: 1
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph('Relevant Guidelines, Standards or Legislation')],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [new Paragraph(`${projectScope.relevantLegislation}`)],
                                                    columnSpan: 3
                                                })
                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Relevant Management Strategies and Role")],
                                                    shading: {
                                                        fill: '#cbd1ce'
                                                    }
                                                }),
                                                new TableCell({
                                                    children: [
                                                        new Paragraph(`${relavaneManagementStratagy[projectScope.scopeId]?.length === 0 ? "-" : relavaneManagementStratagy[projectScope.scopeId]?.map((strategy) => {
                                                            return `${strategy.title}`
                                                        }) ?? []
                                                            }`)
                                                    ],
                                                    columnSpan: 5
                                                })

                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                    children: [new Paragraph("Stakeholder engagement (stakeholder views of the Source, Pathway or Receptor and acceptability of impact level)")],
                                                    columnSpan: 7,
                                                    shading: {
                                                        fill: '#79C585'
                                                    }
                                                })

                                            ]
                                        }),
                                        new TableRow({
                                            children: [
                                                new TableCell({
                                                        children: 
                                                        impactData?.stakeholder_data?.find((statement_data) => statement_data.impact_event_id === projectScope._id)?.stakeholder_engagement.split('\n').map((statement) => {
                                                            return new Paragraph(statement)
                                                        }) ?? [],
                                                    columnSpan: 7
                                                })
                                            ]
                                        })
                                    ]
                                })
                            ]
                        }
                    }
                })
            })
            return <DropdownItem className='w-100' onClick={generateFile} end> {isLoading === true ? <Spinner size='sm' /> : 'Word'}</DropdownItem>
    }

    return <>
        {/* <span onClick={generateFile} >
         Word
      </span> */}
    </>
}

export default WordReport
