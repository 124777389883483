import { useSelector } from 'react-redux'
import { Fragment, useState, useEffect } from 'react'
import axios from 'axios'
import { Card, CardHeader, CardBody, CardTitle, CardText, Row, Col, Spinner } from 'reactstrap'
import PotentialImpactSummary from './PotentialImpactSummary'
import StagesBar from './StagesBar'
import { useParams } from 'react-router-dom'
import ProjectDetails from '../../../ProjectDetails/ProjectDetails'
import StackedChart from './StackedChart'

const Dashboard = () => {
    const [isLoading, setIsLoding] = useState(false)
    const parms = useParams()
    const projectId = parms.projectId
    const updateOne = useSelector(state => state.navbar.onUpdate)
    const activeTabFromRedux = useSelector((state) => state.navbar.activeTab)
    const [projectScopeCount, setProjectScopeCount] = useState([])
    const [stage, setStage] = useState([])
    const [source, setSource] = useState([])
    const [pathway, setPathway] = useState([])
    const [receptor, setReceptor] = useState([])
    const [stagesCount, setStageCount] = useState([])
    const [receptorCount, setReceptorCount] = useState([])
    const [pathwayCount, setPathwayCount] = useState([])
    const [sourceCount, setSourceCount] = useState([])

    // to get scopes count and dashboard count from projectId
    useEffect(() => {
        setIsLoding(true)
        axios.get(`${process.env.REACT_APP_BASENAME}/getscopescount/${projectId}`)
            .then((res) => {
                setProjectScopeCount(res.data.data?.total)
            })
        axios.get(`${process.env.REACT_APP_BASENAME}/getdashboardcounts/${projectId}`)
            .then((res) => {    
                setIsLoding(false)
                setStage(!res.data.data?.stages ? [] : res.data.data?.stages)
                setStageCount(res.data.data?.stagesCount)
                setSource(!res.data.data?.sources ? [] : res.data.data?.sources)
                setSourceCount(res.data.data?.sourcesCount)
                setPathway(!res.data.data?.pathways ? [] : res.data.data?.pathways)
                setPathwayCount(res.data.data?.pathwaysCount)
                setReceptor(!res.data.data?.receptors ? [] : res.data.data?.receptors)
                setReceptorCount(res.data.data?.receptorsCount)
            })
    }, [updateOne, parms.projectId, activeTabFromRedux])
    console.log(stage)
    return (
        <> 
           <ProjectDetails/>
            <Fragment>
                <Row>
                    <Col md='6' xl='4'>
                        <Card color='primary' inverse>
                            <CardBody className='text-center'>
                                <CardTitle className='text-white' tag='h4'>
                                    No. of potential impact events
                                </CardTitle>
                                <CardText style={{fontSize:'22px'}}><b>{projectScopeCount}</b></CardText>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md='4' xl='4'>
                    <Col >
                        <Card >
                            <CardBody>
                                {stage.length === 0 ? (<h5 >Stages chart will appear here</h5>) : (<>< CardTitle tag='h4'>Stages</CardTitle>
                                    {isLoading === true ? <Spinner color="secondary" style={{ height: '2rem', width: '2rem' }} /> : <StagesBar Count={stagesCount}  value={stage} />}</>)}

                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card >
                            <CardBody>
                                {pathway.length === 0 ? (<h5 >Pathways chart will appear here</h5>) : (<>< CardTitle tag='h4'>Pathways</CardTitle>
                                    {isLoading === true ? <Spinner color="secondary" style={{ height: '2rem', width: '2rem' }} /> : <StagesBar Count={pathwayCount} value={pathway} />}</>)}
                            </CardBody>
                        </Card>
                    </Col>
                    </Col>
                    <Col md='4' xl='4'>
                    <Col >
                        <Card >
                            <CardBody>
                                {source.length === 0 ? (<h5 >Sources chart will appear here</h5>) : (<> < CardTitle tag='h4'>Sources</CardTitle>
                                    {isLoading === true ? <Spinner color="secondary" style={{ height: '2rem', width: '2rem' }} /> : <StagesBar Count={sourceCount} value={source} />}</>)}

                            </CardBody>
                        </Card>
                    </Col>
                   
                    <Col>
                        <Card >
                            <CardBody>
                                {receptor.length === 0 ? (<h5 >Receptors chart will appear here</h5>) : (<>< CardTitle tag='h4'>Receptor</CardTitle>
                                    {isLoading === true ? <Spinner color="secondary" style={{ height: '2rem', width: '2rem' }} /> : <StagesBar Count={receptorCount} value={receptor} />}</>)}
                            </CardBody>
                        </Card>
                    </Col>
                    </Col>
                    <Col md='4' xl='4'>
                        <Card >
                            <CardBody className='text-center'>
                                <CardTitle style={{margin:'2px'}} tag='h4'>Potential Impact Event Summary</CardTitle>
                                <PotentialImpactSummary/>
                                <CardTitle style={{marginBottom:'-18px', fontSize:'12px'}} >Linkage</CardTitle>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row>
                   
                </Row>
            </Fragment>
        </>
    )
}

export default Dashboard