
// ** Third Party Components
import { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { useParams } from 'react-router-dom'
const PotentialImpactSummary = () => {

const updateOne = useSelector(state => state.navbar.onUpdate)
const parmas = useParams()
const projectID = useSelector(state => state.navbar.projectId)
const activeTabFromRedux = useSelector((state) => state.navbar.activeTab)
const [count, setCount] = useState([])
let total = 0
    
    //get project linkage count
 useEffect(() => {
if (projectID) {
    axios.get(`${process.env.REACT_APP_BASENAME}/getprojectlinkagecount/${parmas.projectId}`)  // linkagae count of scoping
      .then((res) => {
        setCount(res.data.data)
        res.data.data.forEach(x => { total += x })
      })
}
  }, [updateOne, parmas.projectId, activeTabFromRedux])

    const donutColors = {
        Yes: '#79C585',
        No: '#1B1464',
        Unconfirmed: '#4377BC'
    }

    // ** Chart Options
    const options = {
        chart :{
            toolbar: {
                show: false
              }
        },
        legend: {
            show: true,
            position: 'bottom'
        }, 
        labels: ['Yes', 'No', 'Unconfirmed'],

       colors: [donutColors.Yes, donutColors.No, donutColors.Unconfirmed],
      
        dataLabels: {
            show : true,
            formatter (val) {
                return `${Math.round((val * total) / 100) }`
           }
        },
        plotOptions: {
            pie: {
                donut: {
                    labels: {
                        show: true,
                        name: {
                            fontSize: '2rem',
                            colors:'black',
                            fontFamily: 'Montserrat'
                        },
                        total: {
                            show: true,
                            fontSize: '1.4rem',
                            fontFamily: 'Montserrat',
                             label: 'Total',
                             colors: 'black'
                        }
                    }
                }
            }
        }
    }

    // ** Chart Series
    const series = count
    return (
        <>
        <Chart options={options} series={series} type='donut' height={300} />  
        </>
    )
}

export default PotentialImpactSummary