// ** Redux Imports
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"
export const getBookmarks = createAsyncThunk(
  "layout/getBookmarks",
  async () => {
    const response = await axios.get("/api/bookmarks/data")
    return {
      data: response.data.suggestions,
      bookmarks: response.data.bookmarks
    }
  }
)

export const updateBookmarked = createAsyncThunk(
  "layout/updateBookmarked",
  async (id) => {
    await axios.post("/api/bookmarks/update", { id })
    return id
  }
)


export const layoutSlice = createSlice({
  name: "layout",
  initialState: {
    token: "",
    projectId: "",
    loggedInUserId: '',
    projectUserDetails: "",
    scopeId: "",
    onUpdate: 0,
    onEditProject: {},
    onEditScope: '',
    editedScopeForPIED: {},
    ProjectScopeDetails: "",
    AllProjects: [],
    closeCustomisation:'true',
    LinkageModal: '',
    activeTab : '1',
    query: "",
    printReport: [],
     printData: [],
    relevantStratagyData: [],
    projectCustomisationData: [],
    costumise: {
      stages: "",
      source: "",
      receptor: "",
      pathway: "",
      pathwayPrefix: ""
    },
    costumiseModal: {
      stages: "",
      source: "",
      receptor: "",
      pathway: ""
    },
    addActions: {
      id: "",
      title: "",
      description: "",
      type: ""
    },
    managementStrategy: {
      id: "",
      title: "",
      description: "",
      type: ""
    },
    eventId:[],
    editManagementStrategy: {},
    deleteManagementStrategy: {},
    bookmarks: [],
    suggestions: [],
    customization_data : '',
    report_type:'',
    certainty_data:'',
    checksaveddata:false,
    close_popover:true
  },
  reducers: {
    handleCustomisationClose: (state, action) => { state.closeCustomisation = action.payload },
    
    handleAddActions: (state, action) => { state.addActions = action.payload },

    handlePrintData: (state, action) => { state.printData = action.payload },

    handleRelevantStratagyData: (state, action) => { state.relevantStratagyData = action.payload },

    handleActiveTab: (state, action) => { state.activeTab = action.payload },
    
    handleEditProject: (state, action) => { state.onEditProject = action.payload },
    
    handleEditedScopeForPIED: (state, action) => { state.editedScopeForPIED = action.payload },
    
    handleLoggedInUserId: (state, action) => { state.loggedInUserId = action.payload },
    
    handleEditManagementStrategy: (state, action) => { state.editManagementStrategy = action.payload },
    
    handleDeleteManagementStrategy: (state, action) => { state.editManagementStrategy = action.payload },
    
    handleManagementStrategy: (state, action) => { state.managementStrategy = action.payload },
    
    handleCostumisePathwayModal: (state, action) => { state.costumiseModal.pathway = action.payload },
    
    handleCostumiseSourceModal: (state, action) => { state.costumiseModal.source = action.payload },
    
    handleCostumiseReceptorModal: (state, action) => { state.costumiseModal.receptor = action.payload },
    
    handleCostumiseStagesModal: (state, action) => { state.costumiseModal.stages = action.payload },
    
    handleCostumiseStages: (state, action) => { state.costumise.stages = action.payload },
    
    handleCostumiseReceptor: (state, action) => { state.costumise.receptor = action.payload },
    
    handleCostumisePathwayPrefix: (state, action) => { state.costumise.pathwayPrefix = action.payload },
    
    handleCostumisePathway: (state, action) => { state.costumise.pathway = action.payload },
    
    handleCostumiseSource: (state, action) => { state.costumise.source = action.payload },
    
    handleSearchQuery: (state, action) => { state.query = action.payload },
    
    handleToken: (state, action) => { state.token = action.payload },
    
    handleProjectId: (state, action) => { state.projectId = action.payload },
    
    handleScopeId: (state, action) => { state.scopeId = action.payload },

    handleEventId: (state, action) => {
       const event_id = action.payload
       state.eventId.length = 0
       state.eventId.push(event_id)
    },

    handleCustomisationData: (state, action) => {
         state.customization_data = action.payload
    },

    handleReportType: (state, action) => {
     state.report_type = action.payload
    },

    handleCertaintyDataRedux: (state, action) => {
      state.certainty_data = action.payload
     },
    
    handleclosepopover: (state, action) => { state.close_popover = action.payload },

    handleProjectScopeDetails: (state, action) => { state.ProjectScopeDetails = action.payload },
    
    handleAllProjects: (state, action) => { state.AllProjects = action.payload },
    
    handleUpdate: (state, action) => { state.onUpdate = action.payload },
    
    handlePrintReport: (state, action) => { state.printReport = action.payload },
    
    handleLinkageModal: (state, action) => { state.LinkageModal = action.payload },
    
    handleProjectCustomisationData: (state, action) => { state.projectCustomisationData = action.payload },
    
    handleonEditScope: (state, action) => { state.onEditScope = action.payload },
    
    handleProjectUserDetails: (state, action) => { state.projectUserDetails = action.payload },

    handleCheckSavedData: (state, action) => { state.checksaveddata = action.payload }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookmarks.fulfilled, (state, action) => {
        state.suggestions = action.payload.data
        state.bookmarks = action.payload.bookmarks
      })
      .addCase(updateBookmarked.fulfilled, (state, action) => {
        let objectToUpdate

        // ** find & update object
        state.suggestions.find((item) => {
          if (item.id === action.payload) {
            item.isBookmarked = !item.isBookmarked
            objectToUpdate = item
          }
        })

        // ** Get index to add or remove bookmark from array
        const bookmarkIndex = state.bookmarks.findIndex(
          (x) => x.id === action.payload
        )

        if (bookmarkIndex === -1) {
          state.bookmarks.push(objectToUpdate)
        } else {
          state.bookmarks.splice(bookmarkIndex, 1)
        }
      })
  }
})

// <<<<<<< HEAD
export const { handleSearchQuery, handleActiveTab, handleToken, handleAllProjects, handleEditProject, handleEditedScopeForPIED, handleLoggedInUserId, handleLinkageModal, handleonEditScope, handleProjectCustomisationData, handlePrintReport, handleProjectId, handleUpdate, handleProjectScopeDetails, handleScopeId, handleCostumiseStages, handleCostumisePathway, handleCostumiseReceptor, handleCostumiseSource, handleEventId, handleCustomisationData, handleCertaintyDataRedux, handleCheckSavedData, handleclosepopover } = layoutSlice.actions
export const { handleCustomisationClose, handlePrintData, handleRelevantStratagyData, handleProjectUserDetails, handleCostumisePathwayModal, handleDeleteManagementStrategy, handleEditManagementStrategy, handleManagementStrategy, handleCostumiseReceptorModal, handleCostumiseSourceModal, handleCostumiseStagesModal, handleReportType } = layoutSlice.actions
export const { handleAddActions, handleCostumisePathwayPrefix } = layoutSlice.actions
export default layoutSlice.reducer
