
import axios from "axios"
import Flatpickr from 'react-flatpickr'
import { selectThemeColors } from '@utils'
import moment from "moment"
import Avatar from '@components/avatar'
import { Calendar, Check, CheckSquare } from "react-feather"
import { ModalHeader, ModalBody, ModalFooter, Form, Button, Modal, Input, Row, Col, Spinner, InputGroup, InputGroupText } from 'reactstrap'
import toast from 'react-hot-toast'
import React, { useState } from 'react'
import { handleCostumiseStagesModal } from '../../redux/navbar'
import { useDispatch, useSelector } from 'react-redux'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import Select from 'react-select'
import { handleProjectActiondeleteConfirmRefreshPage, handlerefresh } from "../../redux/managementStrategy"

//Tosty Message component
const Tosty = (msg, type) => {
    toast(
        <Row className='demo-vertical-spacing'>
            <div className='d-flex'><div className='me-1'>{type === 'Error' ? <Avatar size='sm' color='danger' icon={<Check size={12} />} /> : <Avatar size='sm' color='success' icon={<Check size={12} />} />}</div>
                <div className='d-flex flex-column'><h6>{msg}</h6></div>
            </div>
        </Row>)
}

const AddActions = ({ For }) => {
    const [spinButton, setSpinButton] = useState(false)
    const [actionData, setActionData] = useState({
        subject: "",
        description: ""
    })
    const [actionSubject, setActionSubject] = useState('')
    const [actionDiscription, setDiscription] = useState('')
    const [error, setError] = useState("1")
    const databaseUserId = localStorage.getItem('loggedInUserId')
    const [startDateValidation, setStartDateValidation] = useState("1")
    const [endDateValidation, setEndDateValidation] = useState("1")
    const [picker, setPicker] = useState([])
    const [endDatepicker, setEndDatePicker] = useState(startDateValidation === "date" ? new Date() : [])
    const [selectedFullName, SetSelectedFullName] = useState('')
    const dispatch = useDispatch()
    const RefreshPage = useSelector(state => state.managementStrategy.refreshpage)
    const [addScoping, setaddScoping] = useState(false)
    // const [errorValidation, setErrorValidation] = useState({
    //     subject: "1",
    //     description: "1",
    //     startDate: "1",
    //     endDate: "1",
    //     fullName: "1"
    // })
    const [subjectValidation, setSubjectValidation] = useState('1')
    const [descriptionValidation, setDescriptionValidation] = useState("1")
    const [fullNameValidation, setFullName] = useState("1")
    const projectIddata = useSelector(state => state.navbar.projectId)
    const userListFromPermission = useSelector(state => state.managementStrategy.projectActionUserStatus)
    const scopes = useSelector(state => state.navbar.eventId)
    // const onHandleChange = (e) => {
    //     setDescriptionValidation("1")
    //     setErrorValidation({
    //         subject: actionData.subject.trim().length !== 0 ? "1" : "",
    //         description: actionData.description.trim().length !== 0 ? "1" : ""
    //     })
    //     setActionData({ ...actionData, [e.target.name]: e.target.value.trim() })
    //     setError(1)
    // }
    // const popoverRef = useRef(null)
    // cancel Handler to empty state and ErrorState
    const cancelHandler = () => {
        setSpinButton(false)
        setActionData({ subject: "", description: "" })
        SetSelectedFullName("")
        setActionSubject('')
        setDiscription('')
        setPicker([])
        setEndDatePicker([])
        setSubjectValidation("1")
        setDescriptionValidation("1")
        setStartDateValidation("1")
        setEndDateValidation("1")
        setFullName("1")
        setaddScoping(!addScoping)
    }

    // useEffect(() => {
    //     const handleOutsideClick = (event) => {
    //       //if (popoverRef.current && !popoverRef?.current?.contains(event.target)) {
    //      alert(event.target)
    //       //}
    //     }
    
    //     window.addEventListener('click', handleOutsideClick)
    
    //     return () => {
    //       window.removeEventListener('click', handleOutsideClick)
    //     }
    //   }, [])

    // add new Action
    const AddNewActions = () => {
        setSpinButton(true)
        const postDataFrommadal = { projectId: projectIddata, subject: actionSubject, description: actionDiscription, userId: databaseUserId, startDate: picker, endDate: endDatepicker, fullName: selectedFullName, scopes: For === 'PIED' ? scopes : [] }
        if (actionSubject?.length !== 0 && actionDiscription?.length !== 0 && databaseUserId?.length !== 0 && picker.length !== 0 && endDatepicker.length !== 0 && selectedFullName.length !== 0) {
            axios.post(`${process.env.REACT_APP_BASENAME}/saveprojectaction`, postDataFrommadal).then((res) => {
                setSpinButton(false)
                dispatch(handlerefresh())
                dispatch(handleProjectActiondeleteConfirmRefreshPage(res.data.message))
                if (res.data.message) {
                    Tosty(res.data.message, "Data")
                    setaddScoping(!addScoping)
                }
                cancelHandler()
            })
        } else {
            setSubjectValidation(postDataFrommadal.subject?.length !== 0 ? "1" : "")
            setDescriptionValidation(postDataFrommadal.description?.length !== 0 ? "1" : "")
            setStartDateValidation(postDataFrommadal.startDate.length !== 0 ? "1" : "")
            setEndDateValidation(postDataFrommadal.endDate.length !== 0 ? "1" : "")
            setFullName(selectedFullName.length !== 0 ? "1" : "")
            setError(selectedFullName.length !== 0 ? "1" : "")
            setSpinButton(false)
        }
        dispatch(handleCostumiseStagesModal("projectScope"))
    }

    const [listOfUsers] = useState([])
    listOfUsers.length = 0
    const isArray = (a) => {
        return (!!a) && (a.constructor === Array)
    }
    if (userListFromPermission?.length !== 0 && isArray(userListFromPermission)) {
        userListFromPermission?.map(ele => {
            const check = listOfUsers.indexOf({ value: ele.fullName, label: ele.fullName })
            if (check === -1) {
                if (ele.status === "Active") {
                    listOfUsers.push({ value: ele.fullName, label: ele.fullName })
                }
            }
        })
    }

    const handleChangeUser = (data) =>  {
        if (data !== null) {
            SetSelectedFullName(data.value)
            setFullName("1")
            setError({ fullName: data.value ? "" : "1" })
        }
    }

    return (
        <>
            <Button color="primary" className="float-end" style={{ marginRight: '15px' }} onClick={() => setaddScoping(!addScoping)} > Add Action</Button>
            {/* {(For === 'PIED') ? (<div onClick={() => setaddScoping(!addScoping)} className="float-end cursor-pointer"><CheckSquare size={20} />&nbsp;Add Action</div>) : <Button color="primary" className="float-end" style={{ marginRight: '15px' }} onClick={() => setaddScoping(!addScoping)} > Add Action</Button>} */}
            <div >
                <Modal  backdrop='static' isOpen={addScoping} toggle={cancelHandler} className='modal-dialog-centered modal-lg'>
                    <ModalHeader toggle={cancelHandler}></ModalHeader><h4 className="text-center mb-2 mt-1">Add New Action</h4>
                    <ModalBody><Form onSubmit={(e) => e.preventDefault()}>
                        <Row>
                            <Col >
                                <Input name='subject' onChange={(e) => {
                                    setActionSubject(e.target.value.trim())
                                    setSubjectValidation('1')
                                }} placeholder='Subject' />
                                {
                                    subjectValidation.length === 0 ? <span style={{ color: "red" }}>Enter  Subject</span> : " "}

                                <Input className=" mt-1" style={{ width: "100%" }} type='textarea' onChange={(e) => {
                                    setDiscription(e.target.value.trim())
                                    setDescriptionValidation('1')
                                }} name='description' placeholder='Description' />
                                {descriptionValidation.length === 0 ? <span style={{ color: "red" }}>Enter Description</span> : " "}
                                <Row className=" mt-1">
                                    <Col md='4' xl='4' className=" mt-1">
                                        {/* <span > Start Date : </span> */}
                                            <Flatpickr className='form-control bg-white' placeholder='Start Date' options={{
                                                dateFormat: 'd-m-Y'
                                            }}
                                                onChange={date => {
                                                    setStartDateValidation("1")
                                                    if (picker) {
                                                        setPicker(date)
                                                    }
                                                }}
                                            />
                                            {startDateValidation.length === 0 ? <span style={{ color: "red" }}>Select Start Date</span> : " "}
                                    </Col>

                                    <Col md='4' xl='4' className=" mt-1">

                                        <Flatpickr id='date-picker' placeholder='End Date' className='form-control bg-white' options={{
                                            dateFormat: 'd-m-Y',
                                            minDate: `${(moment(new Date(picker)).format('DD-MM-YYYY'))}`
                                            //defaultDate: { picker }
                                        }}
                                            onChange={date => {
                                                if (startDateValidation.length !== 0) {
                                                    setEndDateValidation("date")
                                                    setEndDatePicker(date)
                                                }
                                            }} />

                                        {/* <Calendar size={15} style={{ position: "relative", top: '-33px', left: '5px' }} /> */}
                                        {endDateValidation.length === 0 ? <span style={{ color: "red" }}>Select End Date</span> : " "}
                                    </Col>
                                    <Col md='4' xl='4' className=" mt-1">
                                        {/* <span > User List : </span> */}
                                        <Select onChange={handleChangeUser} theme={selectThemeColors} placeholder='User List' className='react-select' classNamePrefix='select' maxMenuHeight={200} name='fullNameValidation' options={listOfUsers} isClearable />
                                        {fullNameValidation.length === 0 ? <span style={{ color: "red" }}>Select Users</span> : " "}
                                    </Col></Row>
                                {error === 0 ? <span style={{ color: "red" }}>Enter Users</span> : " "}
                            </Col>
                        </Row>
                    </Form>
                    </ModalBody>
                    <ModalFooter className="modal-footer justify-content-center">
                        {spinButton === "false11" && actionData.subject?.length !== 0 && actionData.description.length !== 0 && databaseUserId?.length !== 0 && picker !== [] && endDatepicker !== [] && selectedFullName !== undefined ? <Button color='primary' type='submit' ><Spinner size='sm' className='ms-75' /> </Button> : ""}
                        <Button type='submit' color='primary' onClick={AddNewActions}>{spinButton === true ? <Spinner size='sm' /> : 'SAVE'} </Button>
                        <Button onClick={cancelHandler}>CANCEL </Button>
                    </ModalFooter>
                </Modal>
            </div>
        </>
    )
}

export default AddActions