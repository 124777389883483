
import { PlusCircle, Mail, Home, Clipboard, User } from "react-feather"
import CreateProject from "../../views/CreateProject/CreateProject"
// import CreateProject from "../../views/pages/dashboard/CreateProject/CreateProject"
// import ProjectNavbar from "../../views/pages/dashboard/ProjectNavbarComponent/ProjectNavbar"

export default [
  // {
  //   // id: "createProject",
  //   //  title: "CREATE PROJECT",
  //    icon: <CreateProject/>
  //   // navLink: "/create-project"
  // },
  // {
  //   id: "adminrouter",
  //   title: "ADMIN CONSOLE",
  //   icon: <User/>,
  //   navLink: "/admin-console"
  // },
  {
    id: "createProject",
    title: "CREATE PROJECT",
    icon: <PlusCircle/>,
    navLink: "/create-project"
  },
  {
    id: "home",
    title: "Home",
    icon: < Home  />,
    navLink: "/home"
  }
 
  // {
  //   // id: "secondPage",
  //   // title: "Project 1 ",
  //   icon: <ProjectNavbar />,
  //   navLink: "/project-details"
  // }
]

