import axios from 'axios'
import React, { useEffect, useState, useRef } from 'react'
import { Book, Check, Eye, File } from 'react-feather'
import toast from 'react-hot-toast'
import Avatar from '@components/avatar'
import { useSelector } from 'react-redux'
import { ModalHeader, ModalBody, ModalFooter, Form, Button, Modal, Row, Col, Input, Spinner, Label, UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap'
import LinkStrategyModal from '../ProjectScope/LinkStrategyModal'
import OutcomeModal from './OutcomeModal'
import EditManagementStrategy from '../managementStrategy/EditManagementStrategy'
import AddNewScopingModal from '../ProjectScope/AddNewScopingModal'
import useClickOutside from '../ClickOutsideHook/Outsideclick'

const likelhood = ['Almost certain', 'Likely', 'Possible', 'Unlikely', 'Rare']
const signifcance = ['Very High', 'Major', 'Moderate', 'Minor', 'Insignificant']

const ImpactActions = ({ setPopoverOpen, refresh, setRefresh }) => {
    const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
    const selectedEvent = useSelector(state => state.navbar.customization_data)
    const [isLoading, setIsLoading] = useState(false)
    const [assesementModal, setAssesementModal] = useState(false)
    const [stakeHolderModal, setStakeHolderModal] = useState(false)
    const [LikelihoodErr, setLikelihoodErr] = useState("1")
    const [significanceErr, setsignificanceErr] = useState("1")
    const [Impact_Significance_Err, setImpact_Significance_Err] = useState("1")
    const [rawriskData, setRawRiskdata] = useState({
        Likelihood: '',
        Significance: '',
        Impact_Significance: ''
    })
    const [LikelihoodResiErr, setLikelihoodResiErr] = useState("1")
    const [consequenceErr, setconsequenceErr] = useState("1")
    const [Impact_Significance_Resi_Err, setImpact_Significance_Resi_Err] = useState("1")
    const [residualRiskData, setResidulalData] = useState({
        Likelihood: '',
        Consequence: '',
        Impact_Significance: ''
    })
    const [stakeholderData, setStakeHolderData] = useState('')
    const [stakehodlererr, setStakeHolderErr] = useState('')
    const ref = useRef(null)
    useClickOutside(ref, () => setPopoverOpen(true))

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASENAME}/getrawriskassesement/${selectedEvent._id}`)
            .then((res) => {
                if (res.data.data) {
                    setRawRiskdata({
                        Likelihood: res.data.data.likelihood,
                        Significance: res.data.data.significance,
                        Impact_Significance: res.data.impact_significance
                    })
                }
            })

        axios.get(`${process.env.REACT_APP_BASENAME}/getresidualriskdata/${selectedEvent._id}`)
            .then((res) => {
                if (res.data.data) {
                    setResidulalData({
                        Likelihood: res.data.data.likelihood,
                        Consequence: res.data.data.consequence,
                        Impact_Significance: res.data.impact_significance
                    })
                }
            })

        // axios.get(`${process.env.REACT_APP_BASENAME}/getresidualriskdata/${selectedEvent._id}`)
        // .then((res) => {
        //   if (res.data.data) {
        //     setResidulalData({
        //         Likelihood:res.data.data.likelihood,
        //         Consequence:res.data.data.consequence,
        //         Impact_Significance:res.data.impact_significance
        //     })
        //   }
        // })

        axios.get(`${process.env.REACT_APP_BASENAME}/getstakeholderdata/${selectedEvent._id}`)
            .then((res) => {
                if (res.data.data) {
                    setStakeHolderData(res.data.data.stakeholder_engagement)
                }
            })
    }, [])

    const cancelHandler = () => {
        setRawRiskdata({
            Likelihood: '',
            Significance: '',
            Impact_Significance: ''
        })
        setResidulalData({
            Likelihood: '',
            Consequence: '',
            Impact_Significance: ''
        })
        setAssesementModal(false)
        setsignificanceErr("1")
        setLikelihoodErr("1")
        setImpact_Significance_Err("1")
        setLikelihoodResiErr("1")
        setconsequenceErr("1")
        setImpact_Significance_Resi_Err("1")
        setStakeHolderData('')
        setStakeHolderModal(false)
        setPopoverOpen(false)
    }
    //for raw risk data

    const saveImpactAssesementData = () => {
        if (rawriskData.Likelihood.trim().length !== 0 && rawriskData.Significance.trim().length !== 0 && rawriskData.Impact_Significance.length !== 0 && residualRiskData.Likelihood.trim().length !== 0
            && residualRiskData.Consequence.trim().length !== 0 && residualRiskData.Impact_Significance.length !== 0) {
            setIsLoading(true)
            rawriskData.impact_event_id = selectedEvent._id
            axios.post(`${process.env.REACT_APP_BASENAME}/saverawriskassesement`, rawriskData)
            residualRiskData.impact_event_id = selectedEvent._id
            axios.post(`${process.env.REACT_APP_BASENAME}/saveresidualriskassesement`, residualRiskData).then((res) => {
                if (res.data.message) {
                    toast(
                        <Row className='demo-vertical-spacing'>
                            <div className='d-flex'><div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /> </div>
                                <div className='d-flex flex-column'><h6>{res.data.message}</h6></div>
                            </div>
                        </Row>
                    )
                    setIsLoading(false)
                    setRefresh(!refresh)
                    cancelHandler()
                }
            })
        } else {
            setLikelihoodErr(rawriskData.Likelihood.length !== 0 ? "1" : "")
            setsignificanceErr(rawriskData.Significance.length !== 0 ? "1" : "")
            setImpact_Significance_Err(rawriskData.Impact_Significance.length !== 0 ? "1" : "")
            setIsLoading(false)
        }
    }

    // const saveRawRiskData = () => {
    //     if (rawriskData.Likelihood.trim().length !== 0 && rawriskData.Significance.trim().length !== 0 && rawriskData.Impact_Significance.length !== 0) {
    //         setIsLoading(true)
    //         rawriskData.impact_event_id = selectedEvent._id
    //         axios.post(`${process.env.REACT_APP_BASENAME}/saverawriskassesement`, rawriskData).then((res) => {
    //             if (res.data.message) {
    //                 toast(
    //                     <Row className='demo-vertical-spacing'>
    //                         <div className='d-flex'><div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /> </div>
    //                             <div className='d-flex flex-column'><h6>{res.data.message}</h6></div>
    //                         </div>
    //                     </Row>
    //                 )
    //                 setIsLoading(false)
    //                 cancelHandler()
    //             }
    //         })
    //     } else {
    //         setLikelihoodErr(rawriskData.Likelihood.length !== 0 ? "1" : "")
    //         setsignificanceErr(rawriskData.Significance.length !== 0 ? "1" : "")
    //         setImpact_Significance_Err(rawriskData.Impact_Significance.length !== 0 ? "1" : "")
    //         setIsLoading(false)
    //         setLikelihoodResiErr(residualRiskData.Likelihood.length !== 0 ? "1" : "")
    //         setconsequenceErr(residualRiskData.Consequence.length !== 0 ? "1" : "")
    //         setImpact_Significance_Resi_Err(residualRiskData.Impact_Significance.length !== 0 ? "1" : "")
    //     }
    // }

    const onHandleChangeRawRisk = (e) => {
        setRawRiskdata({ ...rawriskData, [e.target.name]: e.target.value.trim() })
    }

    useEffect(() => {
        if (rawriskData.Likelihood && rawriskData.Significance) {
            const for_low_likelihood = ((rawriskData.Likelihood) === "Almost certain" || (rawriskData.Likelihood) === "Likely" || (rawriskData.Likelihood) === "Possible" || (rawriskData.Likelihood) === "Unlikely" || (rawriskData.Likelihood) === "Rare")
            const for_low_significance = ((rawriskData.Significance) === 'Insignificant')
            const for_moderate_likelihood = ((rawriskData.Likelihood) === "Almost certain" || (rawriskData.Likelihood) === "Likely" || (rawriskData.Likelihood) === "Possible")
            const for_moderate_significance = ((rawriskData.Significance) === 'Minor')
            const for_low_likelihood_signi = ((rawriskData.Likelihood) === "Unlikely" || (rawriskData.Likelihood) === "Rare")
            const for_high_likelihood_moderate = ((rawriskData.Likelihood) === "Almost certain" || (rawriskData.Likelihood) === "Likely")
            const for_high_moderate_likelihood = ((rawriskData.Significance) === 'Moderate')
            const for_med_likelihood_moderate = ((rawriskData.Likelihood) === "Possible" || (rawriskData.Likelihood) === "Unlikely" || (rawriskData.Likelihood) === "Rare")
            const for_ext_moderate_likelihood = ((rawriskData.Significance) === 'Major')
            const for_high_likelihood_major = ((rawriskData.Likelihood) === "Possible" || (rawriskData.Likelihood) === "Unlikely")
            const for_very_high_moderate_likelihood = ((rawriskData.Significance) === 'Very High')
            const for_med_likelihood_major = ((rawriskData.Likelihood) === "Rare")
            console.log(for_low_significance)
            if (for_low_likelihood && for_low_significance) {
                setRawRiskdata({ ...rawriskData, Impact_Significance: "Low" })
            } else if (for_moderate_likelihood && for_moderate_significance) {
                setRawRiskdata({ ...rawriskData, Impact_Significance: "Medium" })
            } else if (for_low_likelihood_signi && for_moderate_significance) {
                setRawRiskdata({ ...rawriskData, Impact_Significance: "Low" })
            } else if (for_high_likelihood_moderate && for_high_moderate_likelihood) {
                setResidulalData({ ...residualRiskData, Impact_Significance: "High" })
            } else if (for_med_likelihood_moderate && for_high_moderate_likelihood) {
                setRawRiskdata({ ...rawriskData, Impact_Significance: "Medium" })
            } else if (for_high_likelihood_moderate && for_ext_moderate_likelihood) {
                setRawRiskdata({ ...rawriskData, Impact_Significance: "Extreme" })
            } else if (for_high_likelihood_major && for_ext_moderate_likelihood) {
                setRawRiskdata({ ...rawriskData, Impact_Significance: "High" })
            } else if (for_high_likelihood_moderate && for_very_high_moderate_likelihood) {
                setRawRiskdata({ ...rawriskData, Impact_Significance: "Extreme" })
            } else if (for_med_likelihood_major && for_ext_moderate_likelihood) {
                setRawRiskdata({ ...rawriskData, Impact_Significance: "Medium" })
            } else if (for_high_likelihood_major && for_very_high_moderate_likelihood) {
                setRawRiskdata({ ...rawriskData, Impact_Significance: "High" })
            } else if (for_very_high_moderate_likelihood && for_med_likelihood_major) {
                setRawRiskdata({ ...rawriskData, Impact_Significance: "Medium" })
            } else {
                setRawRiskdata({ ...rawriskData, Impact_Significance: "" })
            }

            //  const likelihoodArrForLow = ["Almost certain", "Likely", "Possible"]
            //  const signiArrForLow = ['Insignificant']
            //  const likelihoodArrForMed = ["Almost certain", "Likely", "Possible"]
            //  const signiArrForMed = ["Minor"]

            //  if (signiArrForLow.find((signi) => signi === rawriskData.Significance) && likelihoodArrForLow.find((like) => like === rawriskData.Likelihood)) {
            //     setRawRiskdata({ ...rawriskData, Impact_Significance:"Low"})
            //  } else if (signiArrForMed.find((signi) => signi === rawriskData.Significance && likelihoodArrForMed.find((like) => like === rawriskData.Likelihood))) {
            //     setRawRiskdata({ ...rawriskData, Impact_Significance:"Moderate"})
            //  } else {
            //     setRawRiskdata({ ...rawriskData, Impact_Significance:""})
            //  }
        }

        if (residualRiskData.Likelihood && residualRiskData.Consequence) {
            const for_low_likelihood = ((residualRiskData.Likelihood) === "Almost certain" || (residualRiskData.Likelihood) === "Likely" || (residualRiskData.Likelihood) === "Possible" || (residualRiskData.Likelihood) === "Unlikely" || (residualRiskData.Likelihood) === "Rare")
            const for_low_significance = ((residualRiskData.Consequence) === 'Insignificant')
            const for_moderate_likelihood = (residualRiskData.Likelihood === "Almost certain" || (residualRiskData.Likelihood) === "Likely" || (residualRiskData.Likelihood) === "Possible")
            const for_moderate_significance = ((residualRiskData.Consequence) === 'Minor')
            const for_low_likelihood_signi = ((residualRiskData.Likelihood) === "Unlikely" || (residualRiskData.Likelihood) === "Rare")
            const for_high_likelihood_moderate = ((residualRiskData.Likelihood) === "Almost certain" || (residualRiskData.Likelihood) === "Likely")
            const for_high_moderate_likelihood = ((residualRiskData.Consequence) === 'Moderate')
            const for_med_likelihood_moderate = ((residualRiskData.Likelihood) === "Possible" || (residualRiskData.Likelihood) === "Unlikely" || (residualRiskData.Likelihood) === "Rare")
            const for_ext_moderate_likelihood = ((residualRiskData.Consequence) === 'Major')
            const for_high_likelihood_major = ((residualRiskData.Likelihood) === "Possible" || (residualRiskData.Likelihood) === "Unlikely")
            const for_very_high_moderate_likelihood = ((residualRiskData.Consequence) === 'Very High')
            const for_med_likelihood_major = ((residualRiskData.Likelihood) === "Rare")
            console.log(for_low_significance)
            if (for_low_likelihood && for_low_significance) {
                setResidulalData({ ...residualRiskData, Impact_Significance: "Low" })
            } else if (for_moderate_likelihood && for_moderate_significance) {
                setResidulalData({ ...residualRiskData, Impact_Significance: "Medium" })
            } else if (for_low_likelihood_signi && for_moderate_significance) {
                setResidulalData({ ...residualRiskData, Impact_Significance: "Low" })
            } else if (for_high_likelihood_moderate && for_high_moderate_likelihood) {
                setResidulalData({ ...residualRiskData, Impact_Significance: "High" })
            } else if (for_med_likelihood_moderate && for_high_moderate_likelihood) {
                setResidulalData({ ...residualRiskData, Impact_Significance: "Medium" })
            } else if (for_high_likelihood_moderate && for_ext_moderate_likelihood) {
                setResidulalData({ ...residualRiskData, Impact_Significance: "Extreme" })
            } else if (for_high_likelihood_major && for_ext_moderate_likelihood) {
                setResidulalData({ ...residualRiskData, Impact_Significance: "High" })
            } else if (for_high_likelihood_moderate && for_very_high_moderate_likelihood) {
                setResidulalData({ ...residualRiskData, Impact_Significance: "Extreme" })
            } else if (for_med_likelihood_major && for_ext_moderate_likelihood) {
                setResidulalData({ ...residualRiskData, Impact_Significance: "Medium" })
            } else if (for_high_likelihood_major && for_very_high_moderate_likelihood) {
                setResidulalData({ ...residualRiskData, Impact_Significance: "High" })
            } else if (for_very_high_moderate_likelihood && for_med_likelihood_major) {
                setResidulalData({ ...residualRiskData, Impact_Significance: "Medium" })
            } else {
                setResidulalData({ ...residualRiskData, Impact_Significance: "" })
            }

            //  const likelihoodArrForLow = ["Almost certain", "Likely", "Possible"]
            //  const signiArrForLow = ['Insignificant']
            //  const likelihoodArrForMed = ["Almost certain", "Likely", "Possible"]
            //  const signiArrForMed = ["Minor"]

            //  if (signiArrForLow.find((signi) => signi === rawriskData.Significance) && likelihoodArrForLow.find((like) => like === rawriskData.Likelihood)) {
            //
            //  } else if (signiArrForMed.find((signi) => signi === rawriskData.Significance && likelihoodArrForMed.find((like) => like === rawriskData.Likelihood))) {
            //     setRawRiskdata({ ...rawriskData, Impact_Significance:"Moderate"})
            //  } else {
            //     setRawRiskdata({ ...rawriskData, Impact_Significance:""})
            //  }
        }
    }, [rawriskData.Likelihood, rawriskData.Significance, residualRiskData.Likelihood, residualRiskData.Consequence])

   
    // for reidual risk data
    const onHandleChangeResidual = (e) => {
        setResidulalData({ ...residualRiskData, [e.target.name]: e.target.value.trim() })
    }

    // const saveResidualData = () => {

    //     if (residualRiskData.Likelihood.trim().length !== 0 && residualRiskData.Consequence.trim().length !== 0 && residualRiskData.Impact_Significance.length !== 0) {
    //         setIsLoading(true)
    //         residualRiskData.impact_event_id = selectedEvent._id
    //         axios.post(`${process.env.REACT_APP_BASENAME}/saveresidualriskassesement`, residualRiskData).then((res) => {
    //             if (res.data.message) {
    //                 toast(
    //                     <Row className='demo-vertical-spacing'>
    //                         <div className='d-flex'><div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /> </div>
    //                             <div className='d-flex flex-column'><h6>{res.data.message}</h6></div>
    //                         </div>
    //                     </Row>
    //                 )
    //                 setIsLoading(false)
    //                 cancelHandler()
    //             }
    //         })
    //     } else {
    //         setLikelihoodResiErr(residualRiskData.Likelihood.length !== 0 ? "1" : "")
    //         setconsequenceErr(residualRiskData.Consequence.length !== 0 ? "1" : "")
    //         setImpact_Significance_Resi_Err(residualRiskData.Impact_Significance.length !== 0 ? "1" : "")
    //         setIsLoading(false)
    //     }
    // }

    // stakeholder data
    const onChangeStakeholder = (e) => {
        setStakeHolderData(e.target.value)
    }

    const saveStakeHolderData = () => {
        if (stakeholderData.length > 0) {
            setIsLoading(true)
            axios.post(`${process.env.REACT_APP_BASENAME}/savestakeholderdata`, { impact_event_id: selectedEvent._id, stakeholder_engagement: stakeholderData }).then((res) => {
                if (res.data.message) {
                    toast(
                        <Row className='demo-vertical-spacing'>
                            <div className='d-flex'><div className='me-1'><Avatar size='sm' color='success' icon={<Check size={12} />} /> </div>
                                <div className='d-flex flex-column'><h6>{res.data.message}</h6></div>
                            </div>
                        </Row>
                    )
                    setRefresh(!refresh)
                    setIsLoading(false)
                    cancelHandler()
                }
            })
        } else {
            setStakeHolderErr("Please enter value")
        }
    }

    return (
        <>
            <> <div className=" d-flex flex-column">
                {/* <button className="btn btn-outline-secondary" onClick={() => setAssesementModal(true)} > Raw risk assesement </button>  */}
            </div> </>
            {(LoginUserRole === 'Editor') ? <>
            <div className=" d-flex flex-column">
                    <div id='popLeft' style={{ width: '195px', paddingLeft: '5px', paddingRight: '5px', marginBottom: '0px' }} className='d-flex hovereffect cursor-pointer align-items-center justify-content-between' onClick={() => setAssesementModal(true)}>
                        <p> <Book size={20}/></p>
                        <p style={{ fontSize: '15px' }} >Impact Significance <br/> Assessment</p>
                    </div>
                    <OutcomeModal setPopoverOpen={setPopoverOpen} />
                    <div onClick={() => setStakeHolderModal(true)} style={{ width: '242px', paddingLeft: '5px', paddingRight: '5px', marginBottom: '0px' }} className='d-flex hovereffect cursor-pointer align-items-center justify-content-between'>
                        <p> <File size={20}/> </p>
                        <p style={{ fontSize: '15px' }}>Stakeholder Engagement</p>
                    </div>     
                </div>
                {/* <div className='d-flex flex-column'>
                    <span id='popLeft' className='w-100 cursor-pointer' onClick={() => setAssesementModal(true)} > <Book size={20}/> </span>
                    <OutcomeModal setPopoverOpen={setPopoverOpen} />
                    <span onClick={() => setStakeHolderModal(true)} className='w-100 mt-1 cursor-pointer'><File size={20}/></span>
                </div> */}
                <UncontrolledPopover placement='left' target='popLeft'>
                    <PopoverBody>
                        {/* <button className="btn btn-outline-secondary p-0.5 w-100" onClick={() => setAssesementModal(true)} > Raw risk assesement </button>
                        <LinkStrategyModal />
                        <button className="btn btn-outline-secondary p-0.5 w-100" onClick={() => setresidualRiskModal(true)} >Residual risk assesement</button> */}
                    </PopoverBody>
                </UncontrolledPopover></> : <AddNewScopingModal setPopoverOpen={setPopoverOpen} For={'Viewer'} />}

            {/* Impact Assesement Modal */}

            <Modal backdrop='static' isOpen={assesementModal} toggle={cancelHandler} className='modal-dialog-centered modal-lg '>
               
                <ModalHeader toggle={cancelHandler}></ModalHeader>
                <h4 className="text-center mb-1 mt-1">Impact Significance Assessment</h4>
                <h5 style={{ marginLeft: '18px', marginBottom: '0px' }}>Raw risk assesement</h5>
                <ModalBody>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Row>
                            <Col>
                                <div >
                                    <Label> Likelihood </Label>
                                    <select className="form-select" name="Likelihood" onChange={onHandleChangeRawRisk} disabled={(LoginUserRole === 'Viewer')} aria-label="Floating label select example">
                                        {rawriskData?.Likelihood ? '' : <option selected > Select level sensitivity </option>}
                                        {
                                            likelhood.map((rawrisk_item) => {
                                                return <option selected={rawriskData?.Likelihood === rawrisk_item} value={rawrisk_item} > {rawrisk_item} </option>
                                            })
                                        }
                                    </select>
                                    {LikelihoodErr.length === 0 ? <span style={{ color: "red" }}>Select Type</span> : " "}
                                </div>
                                <div className='mt-1' >
                                    <Label>Significance </Label>
                                    <select className="form-select" name="Significance" onChange={onHandleChangeRawRisk} disabled={(LoginUserRole === 'Viewer')} aria-label="Floating label select example">
                                        {rawriskData?.Significance ? '' : <option selected > Select level sensitivity </option>}
                                        {
                                            signifcance.map((significance_item) => {
                                                return <option selected={rawriskData?.Significance === significance_item} value={significance_item} > {significance_item} </option>
                                            })
                                        }

                                    </select>
                                    {significanceErr.length === 0 ? <span style={{ color: "red" }}>Select Type</span> : " "}
                                </div>
                                <div className='mt-1 d-flex flex-column'>
                                    <Label>Impact rating </Label>
                                    <span className={`${(
                                        () => {
                                            switch (rawriskData.Impact_Significance) {
                                                case 'Low':
                                                    return 'bg-success'
                                                    break
                                                case 'High':
                                                    return 'bg-warning'
                                                    break
                                                case 'Extreme':
                                                    return 'bg-danger'
                                            }
                                        }
                                    )()} w-25`} style={{ backgroundColor: `${rawriskData.Impact_Significance === "Medium" ? 'Yellow' : ''}`, color:'black', padding:'5px' }} >{rawriskData.Impact_Significance}</span>
                                    {/* <select className={`form-select ${(
                                    () => {
                                     switch (rawriskData.Impact_Significance) {
                                        case 'Low':
                                        return 'text-success'
                                        break
                                        case 'High':
                                        return 'text-warning'
                                        break
                                        case 'Extreme':
                                        return 'text-danger'
                                     }
                                    }
                                )()}`} style={{color:`${rawriskData.Impact_Significance === "Medium" ? 'Yellow' : ''}`}} name="Impact_Significance" onChange={onHandleChangeRawRisk} disabled={(LoginUserRole === 'Viewer')} aria-label="Floating label select example">
                                    <option selected value={rawriskData.Impact_Significance}><span>{rawriskData.Impact_Significance}</span></option>
                                </select> */}
                                    {Impact_Significance_Err.length === 0 ? <span style={{ color: "red" }}>Select Type</span> : " "}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    <hr />
                    <h5 className='mt-2'>Select existing management Strategy</h5>
                    <LinkStrategyModal />
                    <hr/>
                    <h5 className='mt-2'>Residual risk assesement</h5>
                    <Form onSubmit={(e) => e.preventDefault()}>
                        <Row>
                            <Col>
                                <div >
                                    <Label> Likelihood </Label>
                                    <select className="form-select" name="Likelihood" onChange={onHandleChangeResidual} disabled={(LoginUserRole === 'Viewer')} aria-label="Floating label select example">
                                        {residualRiskData?.Likelihood ? '' : <option selected > Select likelihood </option>}
                                        {
                                            likelhood.map((residualrisk_item) => {
                                                return <option selected={residualRiskData?.Likelihood === residualrisk_item} value={residualrisk_item} > {residualrisk_item} </option>
                                            })
                                        }
                                    </select>
                                    {LikelihoodResiErr.length === 0 ? <span style={{ color: "red" }}>Select Type</span> : " "}
                                </div>
                                <div className='mt-1' >
                                    <Label> Significance </Label>
                                    <select className="form-select" name="Consequence" onChange={onHandleChangeResidual} disabled={(LoginUserRole === 'Viewer')} aria-label="Floating label select example">
                                        {residualRiskData?.Consequence ? '' : <option selected > Select significance </option>}
                                        {
                                            signifcance.map((residualrisk_item) => {
                                                return <option selected={residualRiskData?.Consequence === residualrisk_item} value={residualrisk_item} > {residualrisk_item} </option>
                                            })
                                        }
                                    </select>
                                    {consequenceErr.length === 0 ? <span style={{ color: "red" }}>Select Type</span> : " "}
                                </div>
                                <div className='mt-1 d-flex flex-column'>
                                    <Label>Impact rating </Label>
                                    <span className={`${(
                                        () => {
                                            switch (residualRiskData.Impact_Significance) {
                                                case 'Low':
                                                    return 'bg-success'
                                                    break
                                                case 'High':
                                                    return 'bg-warning'
                                                    break
                                                case 'Extreme':
                                                    return 'bg-danger'
                                            }
                                        }
                                    )()} w-25`} style={{ backgroundColor: `${residualRiskData.Impact_Significance === "Medium" ? 'Yellow' : ''}`, color:'black', padding:'5px' }} >{residualRiskData.Impact_Significance}</span>
                                    {/* <select className={`form-select ${(
                                    () => {
                                     switch (rawriskData.Impact_Significance) {
                                        case 'Low':
                                        return 'text-success'
                                        break
                                        case 'High':
                                        return 'text-warning'
                                        break
                                        case 'Extreme':
                                        return 'text-danger'
                                     }
                                    }
                                )()}`} style={{color:`${rawriskData.Impact_Significance === "Medium" ? 'Yellow' : ''}`}} name="Impact_Significance" onChange={onHandleChangeRawRisk} disabled={(LoginUserRole === 'Viewer')} aria-label="Floating label select example">
                                    <option selected value={rawriskData.Impact_Significance}><span>{rawriskData.Impact_Significance}</span></option>
                                </select> */}
                                    {Impact_Significance_Err.length === 0 ? <span style={{ color: "red" }}>Select Type</span> : " "}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                    <hr />
                </ModalBody>
                <ModalFooter className="modal-footer justify-content-center">
                    {(LoginUserRole === "Editor") ? (<><Button color="primary" onClick={saveImpactAssesementData}>
                        {isLoading === true ? <Spinner size='sm' /> : 'SAVE'}
                    </Button>
                        <Button onClick={cancelHandler}>CANCEL</Button></>) : ''}
                </ModalFooter>
            </Modal>

            {/* residual modal */}


            {/* Stakeholder engagement */}
            <Modal backdrop='static' isOpen={stakeHolderModal} toggle={cancelHandler} className='modal-dialog-centered'>
                <div></div>
                <ModalHeader toggle={cancelHandler}></ModalHeader>
                <h4 className="text-center mb-2 mt-1">Stakeholder Engagement</h4>
                <ModalBody>
                    <div className='mt-1' >
                        <Label>Stakeholder engagement (stakeholder views of the source, pathway or receptor and acceptability of impact level) </Label>
                        <Input value={stakeholderData} className=" mt-2" disabled={(LoginUserRole === 'Viewer')} style={{ width: "100%" }} type='textarea' onChange={onChangeStakeholder} name='measure_outcome' placeholder='Enter value' />
                        {stakehodlererr ? <span style={{ color: "red" }}>Please Enter value</span> : " "}
                    </div>
                </ModalBody>
                <ModalFooter className="modal-footer justify-content-center">
                    {(LoginUserRole === "Editor") ? (<><Button color="primary" onClick={saveStakeHolderData}>
                        {isLoading === true ? <Spinner size='sm' /> : 'SAVE'}
                    </Button>
                        <Button onClick={cancelHandler}>CANCEL</Button></>) : ''}
                </ModalFooter>
            </Modal>
        </>
    )
}

export default ImpactActions

//residualRiskModal 

{ /* <Modal backdrop='static' isOpen={residualRiskModal} toggle={cancelHandler} className='modal-dialog-centered modal-lg '>
<ModalHeader toggle={cancelHandler}></ModalHeader>
<h4 className="text-center mb-2 mt-1">Residual risk assesement</h4>
<ModalBody>
    <Form onSubmit={(e) => e.preventDefault()}>
        <Row>
            <Col>
                <div >
                    <Label> Likelihood </Label>
                    <select className="form-select" name="Likelihood" onChange={onHandleChangeResidual} disabled={(LoginUserRole === 'Viewer')} aria-label="Floating label select example">
                        {residualRiskData?.Likelihood ? '' : <option selected > Select likelihood </option>}
                        {
                            likelhood.map((residualrisk_item) => {
                                return <option selected={residualRiskData?.Likelihood === residualrisk_item} value={residualrisk_item} > {residualrisk_item} </option>
                            })
                        }
                    </select>
                    {LikelihoodResiErr.length === 0 ? <span style={{ color: "red" }}>Select Type</span> : " "}
                </div>
                <div className='mt-1' >
                    <Label> Significance </Label>
                    <select className="form-select" name="Consequence" onChange={onHandleChangeResidual} disabled={(LoginUserRole === 'Viewer')} aria-label="Floating label select example">
                        {residualRiskData?.Consequence ? '' : <option selected > Select significance </option>}
                        {
                            signifcance.map((residualrisk_item) => {
                                return <option selected={residualRiskData?.Consequence === residualrisk_item} value={residualrisk_item} > {residualrisk_item} </option>
                            })
                        }
                    </select>
                    {consequenceErr.length === 0 ? <span style={{ color: "red" }}>Select Type</span> : " "}
                </div>
                <div className='mt-1 d-flex flex-column'>
                    <Label>Impact rating </Label>
                    <span className={`${(
                        () => {
                            switch (residualRiskData.Impact_Significance) {
                                case 'Low':
                                    return 'text-success'
                                    break
                                case 'High':
                                    return 'text-warning'
                                    break
                                case 'Extreme':
                                    return 'text-danger'
                            }
                        }
                    )()}`} style={{ color: `${residualRiskData.Impact_Significance === "Medium" ? 'Yellow' : ''}` }} >{residualRiskData.Impact_Significance}</span>
                    {/* <select className={`form-select ${(
                    () => {
                     switch (rawriskData.Impact_Significance) {
                        case 'Low':
                        return 'text-success'
                        break
                        case 'High':
                        return 'text-warning'
                        break
                        case 'Extreme':
                        return 'text-danger'
                     }
                    }
                )()}`} style={{color:`${rawriskData.Impact_Significance === "Medium" ? 'Yellow' : ''}`}} name="Impact_Significance" onChange={onHandleChangeRawRisk} disabled={(LoginUserRole === 'Viewer')} aria-label="Floating label select example">
                    <option selected value={rawriskData.Impact_Significance}><span>{rawriskData.Impact_Significance}</span></option>
                </select> */ }
//                     {Impact_Significance_Err.length === 0 ? <span style={{ color: "red" }}>Select Type</span> : " "}
//                 </div>
//             </Col>
//         </Row>
//     </Form>
// </ModalBody>
// <ModalFooter className="modal-footer justify-content-center">
//     {(LoginUserRole === "Editor") ? (<><Button color="primary" onClick={saveResidualData}>
//         {isLoading === true ? <Spinner size='sm' /> : 'SAVE'}
//     </Button>
//         <Button onClick={cancelHandler}>CANCEL</Button></>) : ''}
// </ModalFooter>
// </Modal> */}