import Chart from 'react-apexcharts'
import { Legend, YAxis } from 'recharts'

const StagesBar = ({ Count, value }) => {
    const columnColors = {
        Used: '#79C585',
        Unused: '#1B1464',
        bg: '#dedbfc',
        Unconfirm: 'lightgrey'
    }

    const splitter = (str, l, max_lines = 0) => {
        const strs = []

        while (str?.length > l) {
            let pos = str?.substring(0, l).lastIndexOf(' ')
            pos = pos <= 0 ? l : pos
            strs?.push(str?.substring(0, pos))
            let i = str?.indexOf(' ', pos) + 1

            if (i < pos || i > pos + l) {
                i = pos
            }
            str = str?.substring(i)

            if (strs.length === 1) {
                break
            }
        }

        strs.push(str)

        if (max_lines > 0) {
            //return strs.splice(0, max_lines)
        }

        return strs
    }

    // ** Chart Options
    const options = {
        chart: {
            height: 400,
            type: 'bar',
            stacked: true,
            parentHeightOffset: 0,
            toolbar: {
                show: false
            }
        },
        plotOptions: {
            bar: {
                barHeight: '15%',
                columnWidth: '10%',
                colors: {
                    backgroundBarColors: [columnColors.bg],
                    backgroundBarRadius: 10
                },
                horizontal: true
            }
        },
        dataLabels: {
            enabled: false
        },
        colors: [columnColors.Used, columnColors.Unused, columnColors.Unconfirm],
        xaxis: {
            // categories: value.map((a) => {
            //     return `${a.slice(0, 3)} ${a.slice(3, 30)}`
            // })
            categories: value.map((a) => splitter(a, 20, 2))
        },
        yaxis: {
            // categories: value,
            type: 'number',
            min:0,
            labels:{
                maxWidth:100
                // formatter: (val) => {
                //     if (typeof val === 'object') {
                //         return val.splice(0, 2)
                //     }

                //     console.log(typeof val, val)

                //     return val
                // }
            }
        }
    }

    const series = [
        {
            data: Count?.Yes,
            name: 'Yes'
        },
        {
            data: Count?.No,
            name: 'No'
        },
        {
            data: Count?.Unconfirm,
            name: 'Unconfirmed'
        }
    ]

    console.log('series', series)

    let height

    if (series[0]?.data?.length >= 5 && series[0]?.data?.length <= 10) {
        height = 500
    } else if (series[0]?.data?.length > 10 && series[0]?.data?.length <= 15) {
       height = 700
    } else if (series[0]?.data?.length > 15 && series[0]?.data?.length <= 20) {
        height = 900
     } else {
        height = 260
    }

    console.log(height)

    return (
        <>
            <Chart options={options} series={series} height={height} type='bar' />
        </>
    )
}

export default StagesBar

