import { saveAs } from 'file-saver'
import { Document, Page, pdf } from '@react-pdf/renderer'

const MyDoc = (
    <Document>
      <Page>
        My document data
      </Page>
    </Document>
  )

const generatePdfDocument = async () => {
        const blob = await pdf(MyDoc).toBlob()
        saveAs(blob, 'bk.pdf')
}

export default generatePdfDocument
