import { Col, Form, Row, Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from 'reactstrap'
import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { Check } from 'react-feather'
import Avatar from '@components/avatar'
import DropdownStage from '../custumise/DropdownStage'
import DropdownPathways from '../custumise/DropdownPathways'
import DropdownSource from '../custumise/DropdownSource'
import DropdownReceptors from '../custumise/DropdownReceptors'
import { handleActiveTab, handleCheckSavedData, handleCustomisationClose, handleProjectCustomisationData, handleProjectId } from '../../redux/navbar'
import { useNavigate, useParams } from 'react-router-dom'
import { useState } from 'react'
import ProjectDetails from '../ProjectDetails/ProjectDetails'

const CustomizeProject = () => {
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const parms = useParams()
    const checksaveddata = useSelector(state => state.navbar.checksaveddata)
    const [confirmationModal, setConfirmationModal] = useState(false)
    const data = useSelector((data) => data)
    const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
    const projectIddata = parms.projectId
    const databaseUserId = localStorage.getItem('loggedInUserId')
    const receptor = data.navbar.costumise.receptor
    const stage = data.navbar.costumise.stages
    const source = data.navbar.costumise.source
    const pathway = data.navbar.costumise.pathway
    const dispatch = useDispatch()

    //Tosty Message component
    const Tosty = (msg, type) => {
        toast(
            <Row className='demo-vertical-spacing'>
                <div className='d-flex'><div className='me-1'>{type === 'Error' ? <Avatar size='sm' color='danger' icon={<Check size={12} />} /> : <Avatar size='sm' color='success' icon={<Check size={12} />} />}</div>
                    <div className='d-flex flex-column'><h6>{msg}</h6></div>
                </div>
            </Row>)
    }

    const CostumiseHandler = () => {
        setIsLoading(true)
        dispatch(handleProjectId(projectIddata))
        if (receptor.length !== 0 && stage.length !== 0 && pathway.length !== 0 && source.length !== 0) {
            const costumiseData = { pathways: pathway, receptors: receptor, stages: stage, sources: source, projectId: projectIddata, userId: databaseUserId }
            axios.post(`${process.env.REACT_APP_BASENAME}/savecustomization`, costumiseData).then((res) => {
                dispatch(handleProjectCustomisationData({}))
                setIsLoading(false)
                if (res.data.data.message) {
                    dispatch(handleProjectId(projectIddata))
                    Tosty("Project customisation Saved", 'Data')
                    dispatch(handleCustomisationClose('true'))
                }
            })
        } else {
            Tosty("Please select all fields", 'Error')
        }
    }

    return (
        <>
            <ProjectDetails />
            <Row>
                <Col className="card " >
                    <h3 className='mt-2 mb-2 offset-0'>Create your project and identify key elements relevant to your project including stages, sources, pathways and receptors. Every project will be different, and this is a fit-for purpose analysis.</h3>
                    <Form >
                        <DropdownStage />
                        <DropdownSource />
                        <DropdownPathways />
                        <DropdownReceptors />
                        <Row className="mt-4 mb-4">
                            {(LoginUserRole === 'Editor') ? (<>
                                <Col className='offset-0 mt-4'>  <Button color="primary" onClick={CostumiseHandler}> {isLoading === true ? <Spinner size='sm' /> : 'SAVE CHANGES'} </Button>
                                    <Button className=" ms-1 " color="secondary" onClick={() => dispatch(handleCheckSavedData(true))} > CANCEL</Button>
                                </Col></>) : ''}
                        </Row>
                    </Form>
                </Col>
            </Row>
            {/* Are you sure you want to Leave without saving changes */}
            <div className='demo-inline-spacing'>
                <div className='vertically-centered-modal'>
                    <Modal isOpen={checksaveddata} toggle={() => dispatch(handleCheckSavedData(true))} className='modal-dialog-centered'>
                        <ModalHeader toggle={() => setConfirmationModal(!confirmationModal)}>Confirmation </ModalHeader>
                        <ModalBody> Are you sure you want to Leave without saving changes ? </ModalBody>
                        <ModalFooter>
                            <Button color="primary" type='submit' onClick={() => {
                                navigate(`/impact-event/${projectIddata}`)
                                dispatch(handleCheckSavedData(false))
                                dispatch(handleActiveTab('1'))
                            }}>{isLoading === true ? <Spinner size='sm' /> : 'YES'} </Button>
                            <Button className="offset-2" color="secondary" onClick={() => dispatch(handleCheckSavedData(false))}> NO</Button>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>
        </>
    )
}
export default CustomizeProject