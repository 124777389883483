import { Fragment, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Button, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Input, Spinner } from "reactstrap"
import axios from 'axios'
import { handleUpdate } from "../../redux/navbar"
import toast from 'react-hot-toast'
import { Check, Edit2 } from 'react-feather'
// ** Custom Components
import Avatar from '@components/avatar'
const AddNewUser = ({ For, EditProjectUser }) => {
    const [addNewUserModal, setAddNewUserModal] = useState(false)
    const ProjectId = useSelector(state => state.navbar.projectId)
    const [isLoading, setIsLoading] = useState(false)
    const [errorFullName, setErrorFullName] = useState()
    const [errorEmail, setErrorEmail] = useState()
    const [errorRole, setErrorFullRole] = useState()
    const dispatch = useDispatch()
    const loggedInUserId = useSelector(state => state.navbar.loggedInUserId)
    const [userFullName, setuserFullName] = useState(EditProjectUser?.fullName)
    const [userEmail, setUserEmail] = useState(EditProjectUser?.email)
    const [userRole, setUserRole] = useState(EditProjectUser?.role)

    // on Handle Change of Add and update User
    const onHandleChange = (e) => {
        setUserData({ ...userData, [e.target.name]: e.target.value })
    }

    // Tosty Coponent 
    const Tosty = (msg, type) => {
        toast(
            <Row className='demo-vertical-spacing'>
                <div className='d-flex'><div className='me-1'>{type === 'Error' ? <Avatar size='sm' color='danger' icon={<Check size={12} />} /> : <Avatar size='sm' color='success' icon={<Check size={12} />} />}</div>
                    <div className='d-flex flex-column'><h6>{msg}</h6></div>
                </div>
            </Row>)
    }

    //cancel Handler for state Empty 
    const cancelHandler = () => {
        setErrorFullName(1)
        setErrorEmail(1)
        setErrorFullRole(1)
        setIsLoading(false)
        setAddNewUserModal(!addNewUserModal)
    }

    // on submit of Add user 
    const onHandleSubmit = () => {
        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        setIsLoading(true)
        if (userFullName === undefined || re.test(userEmail) === false || userRole === undefined) {
            setErrorFullName(userFullName?.length === 0 || userFullName === undefined ? '0' : '1')
            setErrorEmail(re.test(userEmail) === false ? '0' : '1')
            setErrorFullRole(userRole?.length === 0 || userRole === undefined ? '0' : '1')
            setIsLoading(false)
        } else {
            axios.post(`${process.env.REACT_APP_BASENAME}/createprojectuser`, {
                userId: '0',
                projectId: ProjectId,
                fullName: userFullName,
                email: userEmail,
                role: userRole,
                status: "Pending",
                invitedBy: loggedInUserId
            })
                .then((res) => {
                    setIsLoading(false)
                    dispatch(handleUpdate(res.data.data))
                    if (res.data.data) {
                        Tosty(res.data.data.message, 'Data')
                        setAddNewUserModal(!addNewUserModal)
                    } else {
                        Tosty(res.data.errorMessage, 'Error')
                    }
                    cancelHandler()
                })
        }
    }

    // on Update of Update Existing User 
    const onHandleEdit = () => {
        setIsLoading(true)
        if (userFullName.trim().length === 0) {
            setErrorFullName(userFullName?.trim().length === 0 ? '0' : '1')
            setIsLoading(false)
        } else {
            axios.post(`${process.env.REACT_APP_BASENAME}/updateprojectuser`, {
                projectUserId: EditProjectUser._id,
                fullName: userFullName,
                role: userRole
            })
                .then((res) => {
                    setIsLoading(false)
                    if (res.data.data.message) {
                        Tosty(res.data.data.message, 'Data')
                    }
                    dispatch(handleUpdate(res.data))
                    cancelHandler()
                    setAddNewUserModal(!addNewUserModal)
                })
        }
    }
    return (
        <>
            {/* Add New User Button */}
            {/* <Fragment>
                <Row><Col > */}
                    {(For === 'Edit') ? <Edit2 color='#6e6b7b' size={17} className='cursor-pointer' onClick={() => setAddNewUserModal(!addNewUserModal)} /> : <Button color="primary" className="float-end" style={{marginRight:'15px'}} onClick={() => setAddNewUserModal(!addNewUserModal)}  > ADD USER</Button>}
                {/* </Col></Row>
            </Fragment> */}

            {/* Add User Modal */}
            <Modal backdrop='static' isOpen={addNewUserModal} toggle={cancelHandler} className='modal-dialog-centered'>
                <ModalHeader toggle={cancelHandler}></ModalHeader>
                <ModalBody>
                    <Row>{(For === 'Create') ? <h4 className="text-center mb-3">Add New User</h4> : <h4 className="text-center mb-3">Edit Existing User</h4>} </Row>
                    <Col md='11' xl='11' className='mb-2'>
                         {EditProjectUser?.fullName ? <label style={{marginBottom:'5px'}} >FullName</label> : '' }
                        <Input type='text' name='fullName' defaultValue={EditProjectUser?.fullName} onChange={(e) => {
                            setErrorFullName(1)
                            setuserFullName(e.target.value.trim())
                        }} placeholder='Full Name' />
                        {errorFullName === '0' ? <span style={{ color: "red" }}>Enter Full Name</span> : ''}
                    </Col>
                    <Col md='11' xl='11' className='mb-2'>
                    {EditProjectUser?.email ? <label style={{marginBottom:'5px'}} >Email</label> : '' }
                        {(For === 'Create') ? <Input type='email' name='email' onChange={(e) => {
                            setErrorEmail(1)
                            setUserEmail(e.target.value.trim())
                        }} placeholder='Email' pattern="/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/" title="Must contain at least one  number and one uppercase and lowercase letter, and at least 6 or more characters" /> : <Input type='email' name='email' value={EditProjectUser?.email} disabled onChange={onHandleChange} placeholder='Email' />}
                        {errorEmail === '0' ? <span style={{ color: "red" }}>Enter Valid Email-Id</span> : ''}
                    </Col>
                    <Col md='6' xl='6' className='mb-2'>
                    {EditProjectUser?.role ? <label style={{marginBottom:"5px"}} >User Type</label> : '' }
                        <select className="form-select" onChange={(e) => {
                            setErrorFullRole(1)
                            setUserRole(e.target.value)
                        }} name='role' >
                            {(For === 'Create') ? <option selected value="">Select User Type</option> : null}
                            <option selected={(EditProjectUser?.role === 'Viewer')} value="Viewer">Viewer</option>
                            <option selected={(EditProjectUser?.role === 'Editor')} value="Editor">Editor</option>
                        </select>
                        {errorRole === '0' ? <span style={{ color: "red" }}>Select User Type</span> : ''}
                    </Col>
                </ModalBody>
                <ModalFooter className=" modal-footer justify-content-center " >
                    {(For === 'Create') ? <Button color="primary" type='submit' onClick={onHandleSubmit} >{isLoading === true ? <Spinner size='sm' /> : 'SUBMIT'}</Button> : <Button color="primary" type='submit' onClick={onHandleEdit} >
                    {isLoading === true ? <Spinner size='sm' /> : 'EDIT'} </Button>}
                    <Button color="secondary" className="offset-1" onClick={cancelHandler} >CANCEL</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default AddNewUser