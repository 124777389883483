import Select from 'react-select'
import { selectThemeColors } from '@utils'
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux/es/exports'
import { Button, Row } from 'reactstrap'
import { XCircle } from 'react-feather'
import { handlePotentialImpactFromPotential, handlePotentialImpactFromPotentialStageid } from '../../redux/managementStrategy'

const PotentialImpactEvent = (props) => {
  console.log('props', props)
  const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
  const dispatch = useDispatch()
  const scopeIdfromScoping = useSelector(state => {
    if (state.managementStrategy?.ProjectScopeIdsfromScopingToPotential.length === 0) {
       return []
    } else {
      return state.managementStrategy?.ProjectScopeIdsfromScopingToPotential
    }
  })
 
  const projectPotentialImpactfromUpadateStrtegypage = useSelector(state => state.managementStrategy.projectPotentialImpactfromUpadateStrtegypage)
  console.log(projectPotentialImpactfromUpadateStrtegypage, props.scopeid.scopes, "projectPotentialImpactfromUpadateStrtegypage")
  const [scopeIds] = useState([])
  scopeIds.length = 0
  const getScopeidfromScoping = scopeIdfromScoping?.map(ele => scopeIds.push({ value: ele.scopeId, label: ele.scopeId })
  )
 
  console.log('scopeIdfromScoping', getScopeidfromScoping.toString())
  const projectId = useSelector(state => state.navbar.projectId)
  const [selected, setSelected] = useState(props.scopeid.scopes ? props.scopeid.scopes : [])
  const [selectedValueMap, setSelectedValueMap] = useState([])
  console.log(selectedValueMap, "selectedValueMap")

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_BASENAME}/dataProjectScopeIdsByProjectId`, { projectID: projectId })
      .then((res) => {
        console.log('All Project SCope Ids', res.data)
        res.data?.map(ele => {
          const compareStageData = scopeIds.map(ele => ele.value)
          const check = compareStageData.indexOf(ele.scopeId)
          scopeIds.length = 0
          if (check === -1) {
            scopeIds.push({ value: ele.scopeId, label: ele.scopeId })
          }
          setSelectedValueMap([ele.scopeId])
        }
        )
      })
  }, [projectId])

  const handleScopeIdAdd = (val) => {

    if (val !== null) {

      const check = selected.indexOf(val.label)
      if (check === -1) {

        const selectedIndex = selected.indexOf(val.label)
        let newSelected = []

        if (selectedIndex === -1) {
          newSelected = newSelected.concat(selected, val.label)
        } else if (selectedIndex === 0) {
          newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
          newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
          newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
        }
        setSelected(newSelected)
        if (props.For === 'Strategy') {
          dispatch(handlePotentialImpactFromPotential(newSelected))
          dispatch(handlePotentialImpactFromPotentialStageid(props))
        } else {
          dispatch(handlePotentialImpactFromPotential(newSelected))
        }
      }
    }
  }

  console.log('selected', selected)

  const handleScopeIdDeletion = (id) => {
    const selectedIndex = selected.indexOf(id)
    let newSelected = []
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1))
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1))
    }
    setSelected(newSelected)
    dispatch(handlePotentialImpactFromPotential(newSelected))
    dispatch(handlePotentialImpactFromPotentialStageid(props))
  }

  useEffect(() => {
    axios.post(`${process.env.REACT_APP_BASENAME}/ProjectScopesByProjectId`, { projectID: projectId })
      .then((res) => {
        res.data.allProject?.map(ele => setSelected(ele.scopeId))
      })
    axios.get(`${process.env.REACT_APP_BASENAME}/getProjectPotentialImpactandScopeId/${props.scopeid.id}`)
      .then((res) => {
        const projectdatavalue = res.data.Project?.map(ele => ele.scopeID)
        setSelected(projectdatavalue)
      })
  }, [])


  return (<div>
    <h6 className='offset-0 ms-0 mt-2'>Potential Impact Events</h6>
    <Row >
      <div className='col-md-4  '>
        {(LoginUserRole === 'Viewer') ? " " : <Select
          disabled={(LoginUserRole === 'Viewer')}
          onChange={handleScopeIdAdd}
          theme={selectThemeColors}
          className='react-select'
          classNamePrefix='select'
          name='clear'
          options={scopeIds}
          placeholder="Potential Impact Events"
          isClearable
        />
        }
      </div>
      <div className=' col-md-8 ' >
        {selected?.map(ele => <Button.Ripple className='btn btn-outline-primary round waves-effect ms-2  ' size='sm' color='secondary' outline>
          <span className='round height-60-per'>{ele}</span>
          <XCircle size={15} className='align-middle me-25' onClick={() => handleScopeIdDeletion(ele)} />
        </Button.Ripple> 
        )}
      </div></Row>
  </div>
  )
}
export default PotentialImpactEvent
