import ViewManagementStrategy from "../managementStrategy/ViewManagementStrategy"
import ProjectDetails from "../ProjectDetails/ProjectDetails"
const ManagementStrategy = () => {
return (
    <div>   
            <ProjectDetails/>
            <ViewManagementStrategy/>
    </div>
)
}
export default ManagementStrategy