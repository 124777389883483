// You can customize the template with the help of this file

import Login from '../views/pages/authentication/Login'

// //Template config options
// import { useNavigate } from 'react-router-dom'
// const navigate = useNavigate()
// const change = () => {
//   navigate("/login")
// }
const themeConfig = {

  app: {
    // appName: 'Digital Impact Assessment',
    appLogoImage: require('@src/assets/images/logo/logo.png').default
    // appName:navigate("/login")   
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      isHidden: false,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'sticky', // static , sticky , floating, hidden
      backgroundColor: 'none' // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static' // static, sticky, hidden
    },
    customizer: false,
    scrollTop: true, // Enable scroll to top button
    toastPosition: 'top-right' // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
  }
}

export default themeConfig
