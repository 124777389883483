import { Fragment, useEffect, useState } from "react"
import { Col, Row, Card, Label, Input, ModalBody, ModalFooter, Button, Modal, ModalHeader, Spinner } from "reactstrap"
import  axios from 'axios'
import { useDispatch, useSelector } from "react-redux"
import { Airplay, ChevronDown, Check, Trash, Edit2, Eye } from 'react-feather'
import DataTable from 'react-data-table-component'
import ReactPaginate from 'react-paginate'
// import { handleProjectActionUserStatus } from "../../redux/managementStrategy"
import { useParams } from "react-router-dom"
import moment from "moment"
import jwt_decode from "jwt-decode"
import { UserDetails } from "../../redux/authentication"

const AdminConsole = () => {
  const dispatch = useDispatch()
  const [isLoading, setIsLoading] = useState(false)
  const parms = useParams()
  const updateOne = useSelector(state => state.navbar.onUpdate)
  const LoginUserRole = useSelector(state => state.navbar.projectUserDetails)
  console.log(LoginUserRole)
 const activeTabFromRedux = useSelector((state) => state.navbar.activeTab)
  const projectID = parms.projectId
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [searchValue] = useState('')
  const accessToken = localStorage.getItem('accessToken')
  const searchUserId = jwt_decode(accessToken)
  const [data, setData] = useState([])
  const [totalRecord, setTotalRecords] = useState()
  //for pagination
  const [getData, setGetData] = useState({
    page: currentPage,
    perPage: rowsPerPage
  })
  
  console.log(isLoading)
  //get userlist by project Id with Pagination
  useEffect(() => { 
      setIsLoading(true)
      axios.post(`${process.env.REACT_APP_BASENAME}/adminconsole`, getData)
        .then((res) => {
          //console.log('Res', res.data.data)
          setData(res.data.userData)
          setTotalRecords(res.data.totalRecords)
          setIsLoading(false)
        })
        dispatch(UserDetails(searchUserId.userId))
  }, [updateOne, getData,  activeTabFromRedux === '6'])


  //Checkbox code
  // const onHandleSelect = (id) => {
  //   const selectedIndex = deleteId.indexOf(id)
  //   let newSelected = []
  //   if (selectedIndex === -1) {
  //     newSelected = newSelected.concat(deleteId, id)
  //   } else if (selectedIndex === 0) {
  //     newSelected = newSelected.concat(deleteId.slice(1))
  //   } else if (selectedIndex === deleteId.length - 1) {
  //     newSelected = newSelected.concat(deleteId.slice(0, -1))
  //   } else if (selectedIndex > 0) {
  //     newSelected = newSelected.concat(deleteId.slice(0, selectedIndex), deleteId.slice(selectedIndex + 1))
  //   }
  //   setDeleteId(newSelected)
  //  // setSelecedSingle(newSelected)
  // }

  // for de-select the previos item after complition of task
  // const isSelected = (id) => deleteId.indexOf(id) !== -1

  // server side columns
  const serverSideColumns = [
    {
      sortable: true,
      name: 'First Name',
      wrap: true,
      minWidth: '12%',
      selector: row => row?.firstName
    },
    {
      sortable: true,
      name: 'Last Name',
      wrap: true,
      minWidth: '12%',
      selector: row => row?.lastName
    },
    {
      sortable: true,
      name: 'Email',
      minWidth: '20%',
      wrap: true,
      selector: row => row?.email
    },
    {
      sortable: true,
      name: 'Date of account setup',
      minWidth: '18%',
      selector: row => (moment(new Date(row.date_of_account_setup)).format('ll')) 
    },
    {
      sortable: true,
      name: 'Last interaction time',
      minWidth: '18%',
      selector: row => (moment(new Date(row.last_interaction_time)).format('lll'))
      // selector: row => {
      //   return (
      //     <>
      //       <span className={row.status === 'Active' ? 'badge bg-light-success rounded-pill' : (row.status === 'Pending') ? 'badge bg-light-danger rounded-pill' : 'badge bg-light-primary rounded-pill'}>{row.status}</span>
      //     </>
      //   )
      // }
    },
    {
      sortable: true,
      name: 'Number of projects',
      minWidth: '15%',
      selector: row => row.project_count
    }
  ]
  //handle Pagination
  const handlePagination = page => {
    setGetData({
      page: page.selected + 1,
      perPage: rowsPerPage
    })
    setCurrentPage(page.selected + 1)
  }
  
  // handle per page logic
  const handlePerPage = e => {
    setGetData({
      projectId: projectID,
      page: currentPage,
      perPage: parseInt(e.target.value)
    })
    setRowsPerPage(parseInt(e.target.value))
  }
  const CustomPagination = () => {
    const count = Math.ceil(totalRecord / rowsPerPage)

    //Pagination Component
    return (
     <div className="d-flex align-items-center justify-content-between">
       <div style={{marginLeft:'10px'}} className='d-flex align-items-center'><Label for='sort-select'> Select No. of Records </Label>
                          <Input className='dataTable-select'style={{width:'75px', height:'33px', margin:'5px'}} type='select' id='sort-select' value={rowsPerPage} onChange={e => handlePerPage(e)}>
                            <option value={20}>20</option>
                            <option value={50}>50</option>
                            <option value={100}>100</option>
                            <option value={200}>200</option>
                            <option value={400}>400</option>
                            {/* <option value={50}>50</option>
                            <option value={75}>75</option>
                            <option value={100}>100</option> */}
                          </Input>
                        </div>
         <div>
         <ReactPaginate previousLabel={''} nextLabel={''} breakLabel='...' pageCount={Math.ceil(count) || 1} marginPagesDisplayed={2} pageRangeDisplayed={2} activeClassName='active' forcePage={currentPage !== 0 ? currentPage - 1 : 0} onPageChange={page => handlePagination(page)} pageClassName='page-item' breakClassName='page-item' nextLinkClassName='page-link' pageLinkClassName='page-link' breakLinkClassName='page-link' previousLinkClassName='page-link' nextClassName='page-item next-item' previousClassName='page-item prev-item' containerClassName={'pagination react-paginate separated-pagination pagination-sm pe-1 mt-2'} />
         </div>
      
     </div>
      // <ReactPaginate previousLabel={''} nextLabel={''} breakLabel='...' pageCount={Math.ceil(count) || 1} marginPagesDisplayed={2} pageRangeDisplayed={2} activeClassName='active' forcePage={currentPage !== 0 ? currentPage - 1 : 0} onPageChange={page => handlePagination(page)} pageClassName='page-item' breakClassName='page-item' nextLinkClassName='page-link' pageLinkClassName='page-link' breakLinkClassName='page-link' previousLinkClassName='page-link' nextClassName='page-item next-item' previousClassName='page-item prev-item' containerClassName={'pagination react-paginate separated-pagination pagination-sm justify-content-end pe-1 mt-0'}
      // />
    )
  }

  // ** Table data to render
  const dataToRender = () => {
    const filters = {
      q: searchValue
    }
    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })
    if (data?.length > 0) {
      return data
    } else if (data?.length === 0 && isFiltered) {
      return []
    } else {
      return data?.slice(0, rowsPerPage)
    }
  }
 
  return (
    <>
        <>
          <Fragment>
            <Card><Row className='mt-1'><Col sm='10 '><h4 className="ms-1 float-start">All Users</h4></Col></Row>
              <hr />
              <Row>
                {(data?.length === 0) ? (<h6 className=' text-center'> Users Will Appear Here</h6>) : (
                  <>
                    <Row><Col sm='12'>
                        <DataTable noHeader pagination paginationServer className='react-dataTable table-bordered' columns={serverSideColumns} sortIcon={<ChevronDown size={15} />} paginationComponent={CustomPagination} data={dataToRender()} />
                       
                      </Col>
                      
                    </Row>
                    <Row className='mx-0 mt-0'>
                      <Col sm='3 '>
                        </Col>
                    </Row></>
                )}
              </Row>
            </Card>
          </Fragment>
        </>
    </>
  )
}

export default AdminConsole